import { FC, useEffect, useState } from 'react'
import { Modal, Button, Divider, Form, InputNumber, Switch } from 'antd'
import { notify } from 'contexts/Notification'
import _ from 'lodash'
import { IAutoPromotion } from 'types/IAutoPromotion'
import AutoPromotionAPI from 'api/AutoPromotionAPI'
import dayjs from 'dayjs'
import { TIME_ZONE } from 'const'

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    item?: IAutoPromotion
    refetch: () => void
}

const SetAutoTimeModal: FC<Props> = ({ visible, toggleModal, item, refetch }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const onFinish = async (values: any) => {
        if (!item) return
        console.log('Success:', values)
        const estimate_create_time = form.getFieldValue('estimate_create_time')
        setLoading(true)
        AutoPromotionAPI.editAutoPromotion(item?._id, {
            ...values,
            estimate_create_time
        })
            .then((res) => {
                notify('success', 'Cập nhật Promotion thành công')
                onClose()
                refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (visible && item) {
            const estimate_create_time = getEstimateTime(item.create_before_hours || 1)
            form.setFieldsValue({
                create_before_hours: item.create_before_hours || 1,
                is_active: item.is_active,
                estimate_create_time
            })
        }
    }, [visible, item])
    const onClose = () => {
        toggleModal(false)
        form.resetFields()
    }
    const onValuesChange = (changedValues: any, allValues: any) => {
        if (!item) return
        if (!changedValues.create_before_hours) return
        const estimate_create_time = getEstimateTime(changedValues.create_before_hours)
        form.setFieldsValue({ estimate_create_time })
    }
    const getEstimateTime = (create_before_hours: number) => {
        if (!item) return
        const timeBegin = dayjs.unix(item.begin_time)
        const newTime = dayjs()
            .set('hour', timeBegin.get('hour'))
            .set('minutes', timeBegin.get('minutes'))
            .set('seconds', timeBegin.get('seconds'))
            .subtract(create_before_hours, 'hour')
        if (newTime.isBefore(dayjs())) {
            return newTime.add(1, 'day').unix()
        }
        return newTime.unix()
    }

    const getFlashSaleTime = (_estimate_create_time: number) => {
        if (!item) return
        if (!_estimate_create_time) return
        // Cập nhật begin_time với ngày hiện tại nhưng giữ nguyên giờ, phút, giây
        const timeBegin = dayjs.unix(item?.begin_time)
        const timeEnd = dayjs.unix(item?.end_time)
        const estimate_create_time = dayjs.unix(_estimate_create_time)
        const updatedBeginTime = estimate_create_time
            .clone()
            .set('hour', timeBegin.hour())
            .set('minute', timeBegin.minute())
            .set('second', timeBegin.second())
        // Cập nhật end_time với ngày hiện tại nhưng giữ nguyên giờ, phút, giây
        const updatedEndTime = estimate_create_time
            .clone()
            .set('hour', timeEnd.hour())
            .set('minute', timeEnd.minute())
            .set('second', timeEnd.second())
        if (updatedBeginTime.isBefore(estimate_create_time)) {
            updatedBeginTime.add(1, 'day')
            updatedEndTime.add(1, 'day')
        }
        // Nếu end_time nhỏ hơn begin_time (qua đêm), phải điều chỉnh end_time cho đúng
        if (updatedEndTime.isBefore(updatedBeginTime)) {
            // Nếu end_time là trước begin_time, nghĩa là end_time thuộc ngày hôm sau
            updatedEndTime.add(1, 'day')
        }
        return {
            estimate_create_time: estimate_create_time.unix(),
            begin_time: updatedBeginTime.unix(),
            end_time: updatedEndTime.unix()
        }
    }
    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title={`Cấu hình thời gian tạo tự động: ${item?.title}`}
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Form name='SetAutoTimeModal' layout='vertical' onFinish={onFinish} autoComplete='off' form={form} onValuesChange={onValuesChange}>
                <Form.Item
                    label={<b>Thời gian tạo trước dữ liệu</b>}
                    name='create_before_hours'
                    rules={[{ required: true, message: 'Vui lòng nhập Thời gian tạo trước dữ liệu' }]}
                >
                    <InputNumber placeholder='Nhập Thời gian tạo trước dữ liệu' size='large' style={{ width: '100%' }} min={1} max={20} />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.estimate_create_time !== curValues.estimate_create_time}>
                    {(form) => {
                        return (
                            <>
                                <p className='mb-4'>
                                    <b>Thời gian tạo dự kiến: </b>
                                    <span style={{ color: 'red' }}>
                                        {form.getFieldValue('estimate_create_time') &&
                                            dayjs.unix(form.getFieldValue('estimate_create_time')).tz(TIME_ZONE).format('DD/MM/YYYY HH:mm')}{' '}
                                        ({TIME_ZONE})
                                    </span>
                                </p>
                                <p className='mb-4'>
                                    <b>Thời gian Flash Sale: </b>
                                    <span style={{ color: 'green' }}>
                                        {form.getFieldValue('estimate_create_time') &&
                                            getFlashSaleTime(form.getFieldValue('estimate_create_time')) &&
                                            dayjs
                                                .unix(Number(getFlashSaleTime(form.getFieldValue('estimate_create_time'))?.begin_time))
                                                .tz(TIME_ZONE)
                                                .format('DD/MM/YYYY HH:mm')}
                                        {' - '}
                                        {form.getFieldValue('estimate_create_time') &&
                                            getFlashSaleTime(form.getFieldValue('estimate_create_time')) &&
                                            dayjs
                                                .unix(Number(getFlashSaleTime(form.getFieldValue('estimate_create_time'))?.end_time))
                                                .tz(TIME_ZONE)
                                                .format('DD/MM/YYYY HH:mm')}
                                        {'  '}({TIME_ZONE})
                                    </span>
                                </p>
                            </>
                        )
                    }}
                </Form.Item>

                <Form.Item label={<b>Kích hoạt chạy tự động</b>} name='is_active' valuePropName='checked'>
                    <Switch />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading} size='large'>
                        Cập nhật
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default SetAutoTimeModal
