import { FC, useState } from 'react'
import { Modal, Button, Row, Divider, Select, Tag } from 'antd'
import { notify } from 'contexts/Notification'
import _ from 'lodash'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import PromotionAPI from 'api/PromotionAPI'
const { Option } = Select

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
}

const SyncPromotionModal: FC<Props> = ({ visible, toggleModal }) => {
    const [loading, setLoading] = useState(false)
    const [selectedShop, setSelectedShop] = useState<string>()
    const shopsQueryResponse = useAllTikTokShopQuery()

    const onChangeShop = (value: string) => {
        setSelectedShop(value)
    }
    const onClose = () => {
        toggleModal(false)
        setSelectedShop(undefined)
    }

    const handleSync = async () => {
        try {
            setLoading(true)
            await PromotionAPI.syncPromotionsFromTikTokAccount({
                shop_id: selectedShop as string
            })
            notify('success', 'Đồng bộ dữ liệu đã được đưa vào hàng chờ')
            onClose()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }
    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Đồng bộ sản phẩm' footer={null} width={720} centered>
            <Divider />
            <Row gutter={[24, 24]} justify={'center'} className='m-2'>
                <p>
                    Đồng bộ toàn bộ Promotions từ cửa hàng TikTok của bạn lên hệ thống. Việc này sẽ diễn ra mất thời gian một vài phút và không thể
                    hoàn tác.
                </p>
            </Row>
            <Row justify={'center'} className='mt-4'>
                <Select
                    allowClear
                    showSearch
                    optionFilterProp='textSearch'
                    style={{ width: '100%' }}
                    placeholder='Chọn cửa hàng để đồng bộ'
                    onChange={onChangeShop}
                    size='large'
                    value={selectedShop}
                >
                    {renderListShops()}
                </Select>
            </Row>
            <Row gutter={[24, 24]} justify={'center'} className='mt-4'>
                <Button size='large' onClick={handleSync} loading={loading}>
                    Đồng bộ ngay
                </Button>
            </Row>
        </Modal>
    )
}

export default SyncPromotionModal
