import React from 'react'
import { Divider, Modal } from 'antd'
import { ModalProps } from '../../../../hooks/useModal'

function AutoIdeaUpdateDataResult({
    onClose,
    visible,
    data
}: ModalProps & {
    data: { keyword: string; message: string; source: string }[]
}) {
    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Update Data Result' footer={null} width={720} centered>
            <Divider />
            {data?.map((d, idx) => (
                <div key={idx} className='d-flex flex-column'>
                    <p className='text-danger font-weight-bold'>[{d.source}] "{d.keyword}":</p>
                    <p className='text-danger ml-2'> {d.message}</p>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </div>
            ))}
            {data?.length === 0 && <p className='text-success font-weight-bold'>Perfectly. That doesnt have any errors</p>}
        </Modal>
    )
}

export default AutoIdeaUpdateDataResult
