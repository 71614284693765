import {useQuery} from "@tanstack/react-query";
import PackageAPI from "../../../api/PackageAPI";

export const PACKAGES_QUERY_KEY = 'PACKAGES_QUERY_KEY'

export const usePackageQuery = () => useQuery({
    queryKey: [PACKAGES_QUERY_KEY],
    queryFn: PackageAPI.getPackage,
    staleTime: Infinity
})
