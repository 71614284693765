import { Card, Tabs } from 'antd'
import { useState, FC } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import ListPromotion from './ListPromotion'
import AutoPromotion from './AutoPromotion'
import CreatePromotion from './CreatePromotion'
import PromotionProvider from './PromotionProvider'
const TITLE = 'Quản lý Promotions'

const PromotionManagement: FC = () => {
    const [selectedTab, setSelectedTab] = useState(1)
    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
        console.log('key', key)
    }
    const LIST_TABS = [
        {
            id: 1,
            label: 'Tạo Promotions',
            children: <CreatePromotion tab={selectedTab} />
        },
        {
            id: 2,
            label: 'Danh sách Promotions',
            children: <ListPromotion tab={selectedTab} />
        },
        {
            id: 3,
            label: 'Cấu hình đã lưu',
            children: <AutoPromotion tab={selectedTab} />
        }
    ]
    return (
        <Card title='Quản lý Promotions'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <PromotionProvider>
                <Tabs
                    activeKey={selectedTab.toString()}
                    defaultActiveKey='1'
                    type='card'
                    items={LIST_TABS.map((tab, i) => {
                        return {
                            key: tab.id.toString(),
                            label: tab.label,
                            children: tab.children
                        }
                    })}
                    onChange={onChangeTab}
                />
            </PromotionProvider>
        </Card>
    )
}

export default PromotionManagement
