import { FC } from 'react'
type Props = {
    total: number
    text: string
}

const HeaderTotalTable: FC<Props> = ({ total, text = 'Tổng số items' }) => {
    return (
        <span style={{ fontSize: '16px' }}>
            {text} <strong style={{ color: '#f5222d' }}>{total}</strong>
        </span>
    )
}

export default HeaderTotalTable
