import React from 'react'
import { IAutoIdeaProduct } from '../../../../types/IAutoIdea'
import CellProductName from '../../../../core/Table/CellProductName'
import { CRAWL_SOURCE } from './AutoIdeaProductImageColumn'

function AutoIdeaProductColumn(productId: string, record: IAutoIdeaProduct) {
    return (
        <div>
            <CellProductName
                product_id={productId}
                product_name={record.title}
                skus={[]}
                tiktok_shop={record.shop}
                removed={record.source === CRAWL_SOURCE.SHOP_PLUS.source && !record.product_status}
            />
        </div>
    )
}

export default AutoIdeaProductColumn
