import ApiSender from './ApiSender'

export default class AuthAPI {
    public static login(payload: object) {
        const route = `/core/user/login`
        return ApiSender.post(route, payload)
    }

    public static verifyAccessToken() {
        const route = `/core/user/tokens`
        return ApiSender.get(route)
    }

    public static loginGoogleOauth(payload: { id_token: string }) {
        const route = `/core/user/google-auth`
        return ApiSender.post(route, payload)
    }

    public static register(payload: { email: string; password: string; first_name: string; last_name: string }) {
        const route = `/core/user/register`
        return ApiSender.post(route, payload)
    }

    public static getAccountInfo() {
        const route = `/core/user/me`
        return ApiSender.get(route)
    }

    public static updateAccountInfo(payload: { first_name: string; last_name: string; sku_prefix: string, kiki_api_key: string, resource_importer: object }) {
        const route = `/core/user/update`
        return ApiSender.put(route, payload)
    }

    public static changePassword(payload: { old_pwd: string; new_pwd: string }) {
        const route = `/core/user/change-password`
        return ApiSender.put(route, payload)
    }
}
