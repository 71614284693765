import { IConfigProduct } from 'types/IConfigProduct';
import ApiSender from './ApiSender'

export default class ConfigProductAPI {
    public static getConfigProducts(params: { page_size: number; page_number: number }) {
        const route = `/core/user/config-products`
        return ApiSender.get(route, params)
    }

    public static getAllConfigProducts(): Promise<IConfigProduct[]> {
        const route = `/core/user/all-config-products`
        return ApiSender.get(route)
    }

    public static createConfigProduct(payload: {
        categories: string[]
        product_properties: any[]
        weight: string
        unit: number
        height: number
        width: number
        length: number
        quantity: number
        brand_id: string
    }) {
        const route = `/core/user/config-products`
        return ApiSender.post(route, payload)
    }

    public static editConfigProduct(
        _id: string,
        payload: {
            product_properties: any[]
            weight: string
            unit: number
            height: number
            width: number
            length: number
            quantity: number
            brand_id: string
        }
    ) {
        const route = `/core/user/config-products/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removeConfigProduct(_id: string) {
        const route = `/core/user/config-products/${_id}`
        return ApiSender.delete(route)
    }

    public static importConfigProduct(payload: {configs: any[]}) {
        const route = `/core/user/config-products/import`
        return ApiSender.post(route, payload)
    }
}
