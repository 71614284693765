import React from 'react'

/*
 * @deprecated
 * This interface will be remove in the next version. Please dont use anymore.
 * Use interface in ModalProvider instead
 */
export interface ModalProps {
    visible: boolean
    onClose: () => void
}

/*
 * @deprecated
 * This hook will be remove in the next version. Please dont use anymore.
 * Use useModal in ModalProvider instead
 */
export function useModal() {
    const [visible, setVisible] = React.useState(false)

    const toggleModal = () => setVisible(!visible)

    return [visible, toggleModal] as const
}
