import { useQuery } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'

export const USER_FULL_FILL_SERVICE_QUERY_KEY = 'USER_FULL_FILL_SERVICE_QUERY_KEY'

export const useUserFulfillServicesQuery = (params?: Record<string, any>) => {
    return useQuery({
        queryKey: [USER_FULL_FILL_SERVICE_QUERY_KEY, params],
        queryFn: () => OrderAPI.getUserFulfillServices(params)
    })
}
