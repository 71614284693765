import {IAutoIdeaProduct} from "../../../../types/IAutoIdea";
import {IListing} from "../../../../types/IListing";
import {notify} from "../../../../contexts/Notification";
import {CRAWL_SOURCE} from "../Table/AutoIdeaProductImageColumn";
import UploadAPI from "../../../../api/UploadAPI";
import ListingAPI from "../../../../api/ListingAPI";
import {sleep} from "../../../../utils/time";
import { useState} from "react";

const addImageExtension = (fileName: string) => {
    const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp']
    const lowerCaseFileName = fileName.toLowerCase()
    const hasValidExtension = validExtensions.some((ext) => lowerCaseFileName.endsWith(ext))
    if (!hasValidExtension) {
        return `${fileName}.jpg`
    }
    return fileName
}

const getImageName = (image: string) => {
    const imagePart = image.split('/')
    const imageName = imagePart[imagePart.length - 1].replace('1000:1000', '')
    return addImageExtension(imageName)
}

export const useAutoIdeaListingHandler = (record?: IAutoIdeaProduct) => {
    const [currentTitle, setCurrentTitle] = useState(record?.title || '')

    const onCreateListingSuccess = async (listings: IListing[], { onSuccess }: { onSuccess?: (listing: IListing) => void }) => {
        if (!record) return;
        if (!listings || !Array.isArray(listings) || listings.length === 0) {
            notify('error', 'No listing found')
            return
        }
        if (!record.image) {
            notify('error', 'No product image found')
            return
        }

        try {
            const url = CRAWL_SOURCE[record.source as keyof typeof CRAWL_SOURCE].imagePrefix + record.image
            const response = await fetch(url)
            const blob = await response.blob()
            const imageName = getImageName(record.image)
            const resImage = await UploadAPI.uploadImageFromNetwork(blob, imageName)
            const diff = {
                product_name: currentTitle,
                main_image: resImage,
                keyword: record.keyword
            }

            const listing = listings[0]
            await ListingAPI.editListing(listing._id, diff)
            await sleep(4000)
            notify('success', 'Sử dụng Idea thành công')
            await ListingAPI.draftIdeaProduct({
                product_id: record.product_id,
                shop: record.shop._id,
                listingId: listing._id
            })
            onSuccess?.(listing);
        } catch (err) {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    }

    return {
        currentTitle,
        setCurrentTitle,
        onCreateListingSuccess
    }
}
