import React from 'react';
import {IOrder} from "../../../types/IOrder";
import {Tag} from "antd";

function OrderSellerColumn(order: IOrder) {
    return (
        <div>
            <p>{order.shop?.name}</p>
            <p>{order.shop?.assignee?.full_name}</p>
            <Tag color='purple'>{order.shop?.profile_name}</Tag>

        </div>
    );
}

export default OrderSellerColumn;
