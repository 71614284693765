import React from 'react'
import '../styles/redesign.scss'

function RedesignBadge({ text = 'Redesign' }) {
    return (
        <div className='neon-frame glow'>
            <div className='' style={{ background: 'white' }}>
                <p className='neon-text'>
                    {text.split('').map((c, idx) => (
                        <span key={idx + 1} style={{ '--i': idx + 1, marginRight: c === ' ' ? 6 : 0, fontSize: 12, fontWeight: "bold" }}>
                            {c}
                        </span>
                    ))}
                </p>
            </div>
        </div>
    )
}

export default RedesignBadge
