import React, {FC, useEffect, useState} from 'react'
import { Modal, Button, Divider, Form, Input, InputNumber, Select, Row, Col, Tag } from 'antd'
import { notify } from 'contexts/Notification'
import _ from 'lodash'
import { useForm } from 'antd/es/form/Form'
import { IProduct } from 'types/IProduct'
import ProductAPI from 'api/ProductAPI'
import ListingAPI from 'api/ListingAPI'
import {IListing} from "../../../../types/IListing";
import {IAutoIdeaProduct} from "../../../../types/IAutoIdea";
import {useAutoIdeaListingHandler} from "../../AutoIdea/hooks/useAutoIdeaListingHandler";
import AutoIdeaCreateListingHead from "../../AutoIdea/components/AutoIdeaCreateListingHead";
import AutoIdeaTitleGenerator from "../../AutoIdea/components/AutoIdeaTitleGenerator";
import { useModal } from 'contexts/Modal'

const { Option } = Select

type Props = {
    refetch: () => void
    shop_id: string;
    onSuccess?: (listings: IListing) => void;
    successMessage?: string;
    disabledSKUInput?: boolean;
    ideaRecord?: IAutoIdeaProduct;
}

const CreateListingModal: FC<Props> = ({ refetch, shop_id, onSuccess, successMessage = 'Tạo Listing thành công!', disabledSKUInput = false, ideaRecord }) => {
    const [loading, setLoading] = useState(false)
    const [form] = useForm()
    const [products, setProducts] = useState<IProduct[]>([])
    const [defaultSku, setDefaultSku] = useState('')
    const [newSku, setNewSku] = useState('')
    const [selectedProduct, setSelectedProduct] = useState<any>(null)
    const { closeModal } = useModal();
    const { onCreateListingSuccess, setCurrentTitle } = useAutoIdeaListingHandler(ideaRecord)

    const onClose = () => {
        closeModal()
        form.resetFields()
    }

    useEffect(() => {
        setLoading(true)
        ListingAPI.getSKU()
            .then((res) => {
                form.setFieldsValue({ sku: res })
                setDefaultSku(res)
                setNewSku(res)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }, [shop_id])

    useEffect(() => {
        if (shop_id) {
            ProductAPI.getProductsTemplate(shop_id).then((res) => {
                setProducts(res)
            })
        }
    }, [shop_id])

    const onCreateIdea = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                ...values
            }
            if (values.sku !== defaultSku) {
                payload.sku = values.sku + '_001'
            }
            const response = await ListingAPI.createListing(values)
            notify('success', successMessage)
            form.resetFields()
            onClose()
            refetch()
            await onCreateListingSuccess(response.data, { onSuccess })
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const renderListProducts = () => {
        return _.map(products, (product: any) => {
            return (
                <Option value={product._id} key={product._id}>
                    {product.template_name || product.product_name}
                </Option>
            )
        })
    }

    const renderSubNiches = (sub_niches: any) => {
        return sub_niches.map((niche: any, idx: number) => {
            return (
                <span key={niche._id}>
                    {niche.name}
                    {idx !== sub_niches.length - 1 && ';'}
                </span>
            )
        })
    }
    const onChangeForm = (changedValues: any) => {
        if (changedValues.sku) {
            setNewSku(changedValues.sku)
        }
        if (changedValues.product_id) {
            const product = products.find((product) => product._id === changedValues.product_id)
            setSelectedProduct(product)
        }
    }
    return (
        <Modal maskClosable={false} closable open onCancel={onClose} title='Tạo Listing' footer={null} width={720} centered>
            <Divider />
            {ideaRecord && (
                <>
                    <AutoIdeaCreateListingHead {...ideaRecord} />
                    <AutoIdeaTitleGenerator title={ideaRecord.title} getCurrentTitle={setCurrentTitle} />
                </>
            )}
            <Form
                name='CreateListing'
                layout={'vertical'}
                form={form}
                onFinish={onCreateIdea}
                style={{ maxWidth: 600 }}
                initialValues={{
                    number_sku: 1,
                    quantity: 2
                }}
                onValuesChange={onChangeForm}
            >
                <Form.Item label={<b>Số SKU cần tạo</b>} name='number_sku' rules={[{ required: true, message: 'Vui lòng nhập Số SKU cần tạo' }]}>
                    <InputNumber placeholder='Nhập Số SKU cần tạo' min={1} style={{ width: '100%' }} disabled={disabledSKUInput} />
                </Form.Item>
                <Form.Item label={<b>Chọn sản phẩm Template</b>} name='product_id' rules={[{ required: true, message: 'Vui Chọn sản phẩm Template!' }]}>
                    <Select allowClear style={{ width: '100%' }} placeholder='Chọn sản phẩm Template' size='large'>
                        {renderListProducts()}
                    </Select>
                </Form.Item>
                {selectedProduct && selectedProduct.tiktok_shop?.main_niche && (
                    <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-1'>
                        <b style={{ color: '#000' }}>Niche: </b> <Tag color='#08979c'>{selectedProduct.tiktok_shop?.main_niche?.name}</Tag>
                    </p>
                )}
                {selectedProduct && selectedProduct.tiktok_shop?.sub_niches && selectedProduct.tiktok_shop?.sub_niches.length > 0 && (
                    <p style={{ color: 'rgba(0,0,0,.55)' }} className='mb-3'>
                        <b style={{ color: '#000' }}>Sub-Niche: </b> <Tag color='#08979c'>{renderSubNiches(selectedProduct.tiktok_shop?.sub_niches)}</Tag>
                    </p>
                )}
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <Form.Item label={<b>Mã SKU</b>} name='sku' rules={[{ required: true, message: 'Vui lòng nhập SKU!' }]}>
                            <Input placeholder='Nhập mã SKU' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<b>Quantity</b>} name='quantity' rules={[{ required: true, message: 'Vui lòng nhập Số lượng!' }]}>
                            <InputNumber placeholder='Nhập Số lượng' min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                {newSku !== defaultSku && (
                    <p className='mb-3' style={{ color: 'red' }}>
                        <i>
                            Lưu ý: Bạn đang thay đổi mã SKU mặc định. Hệ thống sẽ tự động thêm <b>_001</b> vào mã SKU mới : <b>{newSku}_001</b>
                        </i>
                    </p>
                )}
                <Form.Item>
                    <Button size='large' htmlType='submit' type='primary' loading={loading}>
                        Tạo Listing
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateListingModal
