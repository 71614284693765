export const HOUR_TO_MS = 3600 * 1000

export const MINUTE_TO_MS = 60 * 1000

export const DAY_TO_MS = 24 * 3600 * 1000

export const DAYS_OF_WEEK_ENUM = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6
}

export enum EnumDaysOfWeek {
    SUNDAY = 0,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY
}

export const FULL_DATE_FORMAT = 'HH:mm DD-MM-YYYY'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const locateMoment = {
    relativeTime: {
        future: 'trong %s',
        past: '%s trước',
        s: '1 giây',
        ss: '%s giây',
        m: 'một phút',
        mm: '%d phút',
        h: '1 giờ',
        hh: '%d giờ',
        d: '1 ngày',
        dd: '%d ngày',
        M: 'tháng',
        MM: '%d tháng',
        y: 'năm',
        yy: '%d năm'
    }
}

export const TIME_ZONE = 'America/Los_Angeles'