import { EnumProductStatus } from 'types/IProduct'

export const TAG_COLOR = {
    PROFILE_NAME: '#1677ff',
    SHOP_NAME: '#d46b08',
    ASSIGNEE: '#d4b106',
    MAIN_NICHE: '#08979c',
    SUB_NICHE: '#08979c'
}

export const getProductStatusColor = (status: EnumProductStatus) => {
    switch (status) {
        case EnumProductStatus.DRAFT:
            return '#52c41a'
        case EnumProductStatus.DRAFT:
            return '#f5222d'
        case EnumProductStatus.DRAFT:
            return '#faad14'
        case EnumProductStatus.DRAFT:
            return '#1890ff'
        default:
            return ''
    }
}
