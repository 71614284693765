import React, {useEffect, useRef} from 'react'
import TextArea from 'antd/es/input/TextArea'
import { Button, ConfigProvider } from 'antd'
import { AntDesignOutlined } from '@ant-design/icons'
import ListingAPI from '../../../../api/ListingAPI'
import { notify } from '../../../../contexts/Notification'
import { useMutation } from '@tanstack/react-query'

function parseJsonFromChatGPT(inputString: string) {
    const cleanedString = inputString
        .replace(/```json/g, '')
        .replace(/```/g, '')
        .trim()

    try {
        return JSON.parse(cleanedString)
    } catch (error) {
        console.error('Parse JSON error:', error)
        return null
    }
}

function AutoIdeaTitleGenerator({ title, getCurrentTitle }: { title: string; getCurrentTitle: (title: string) => void }) {
    const [currentTitle, setCurrentTitle] = React.useState<string>(title)
    const inputRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => clearTimeout(inputRef.current), [])

    const { mutateAsync, isLoading } = useMutation({
        mutationFn: ListingAPI.generateAutoIdeaTitle,
        onSuccess: (newTitle) => {
            console.log('newTitle', newTitle)
            const { title } = parseJsonFromChatGPT(newTitle) || {}
            if (title) {
                onCurrentTitleChange(title)
            } else {
                notify('error', 'Cannot generate title, please try again')
            }
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })

    const generateTitle = async () => {
        await mutateAsync(title)
    }

    const onCurrentTitleChange = (text: string) => {
        setCurrentTitle(text)
        if (inputRef.current) {
            clearTimeout(inputRef.current)
        }

        inputRef.current = setTimeout(() => {
            getCurrentTitle(text)
        }, 500)
    }

    return (
        <div className='mb-3'>
            {/*<p className='font-weight-bold mb-2'>Original Title</p>*/}
            {/*<p className='mb-2'>{title}</p>*/}
            <p className='font-weight-bold mb-2'>New Title</p>
            <TextArea value={currentTitle} onChange={e => onCurrentTitleChange(e.target.value)} rows={3} />
            <div className='d-flex justify-content-between align-items-center mt-3'>
                <p className={'font-italic font-weight-bold ' + (currentTitle === title ? 'text-danger' : 'text-success')}>{currentTitle === title ? 'Both titles are the same, please change' : 'New title is different from the original'}</p>
                <ConfigProvider
                    theme={{
                        components: {
                            Button: {
                                colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                primaryShadow: 'none',
                                defaultBg: 'linear-gradient(90deg, #aea4e3, #d3ffe8)',
                                defaultShadow: 'none',
                                defaultColor: '#fff'
                            }
                        }
                    }}
                >
                    <Button type='primary' icon={<AntDesignOutlined />} onClick={generateTitle} loading={isLoading}>
                        Generate with AI
                    </Button>
                </ConfigProvider>
            </div>
        </div>
    )
}

export default AutoIdeaTitleGenerator
