import { useAuth } from 'contexts/Authenticate'
import _ from 'lodash'
import { ReactNode } from 'react'
import { UserPermission } from 'types/IPermission'

const ButtonProtected = ({ children, permission }: { children: ReactNode; permission: UserPermission }) => {
    const { user } = useAuth()
    const checkPermission = () => {
        if (user?.isOwner) return true;
        return _.isEmpty(user)
            ? false
            : Object.keys(permission).every((k: any) => {
                  const require = permission[k]
                  const ofAdmin = user?.permissions?.find((x: any) => x.code_permission === k)
                  return require.every((r) => _.includes(ofAdmin?.actions, r))
              })
    }
    return checkPermission() && children
}

export default ButtonProtected
