import ApiSender from './ApiSender'

export default class UploadAPI {

    public static uploadImage(file: any) {
        const route = `/core/user/upload`;
        const formData = new FormData()
        formData.append('file', file)
        return ApiSender.post(route, formData)
    }

    public static uploadImageFromNetwork(file: Blob, fileName: string) {
        const route = `/core/user/upload`;
        const formData = new FormData()
        formData.append('file', file, fileName)
        return ApiSender.post(route, formData)
    }
}
