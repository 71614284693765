import { EnumListingStatus, IListing, IListingQueryParams } from 'types/IListing'
import ApiSender from './ApiSender'
import { IAutoIdeaProduct, IAutoIdeaQueryParams } from '../types/IAutoIdea'
import { IListApiResponse } from '../types/IApiResponse'
import { ITikTokShop } from '../types/ITikTokShop'

export default class ListingAPI {
    public static getListings(params: IListingQueryParams): Promise<IListApiResponse<IListing>> {
        let route = `/core/user/listings`
        if (params.status && Array.isArray(params.status)) {
            const URLString = params.status.map((value, index) => `status[${index}]=${value}`).join('&')
            route += `?${URLString}`
        }
        return ApiSender.get(route, params)
    }

    public static createListing(payload: { number_sku: number; product_id: string; sku: string }) {
        const route = `/core/user/listings`
        return ApiSender.post(route, payload)
    }

    public static removeListing(_id: string) {
        const route = `/core/user/listings/${_id}`
        return ApiSender.delete(route)
    }

    public static publishListing(_id: string) {
        const route = `/core/user/listings/${_id}/publish`
        return ApiSender.post(route)
    }

    public static editListing(_id: string, payload: { product_name?: string; main_image?: any; description?: string; product_properties?: any[] }) {
        const route = `/core/user/listings/${_id}`
        return ApiSender.put(route, payload)
    }

    public static getListingsProcess(params: {
        page_size: number
        page_number: number
        shop_id?: string
        search?: string
        status?: EnumListingStatus
    }) {
        const route = `/core/user/listings/wait-process`
        return ApiSender.get(route, params)
    }

    public static publishMulti(payload: { listing_ids: string[] }) {
        const route = `/core/user/listings/publish-multi`
        return ApiSender.post(route, payload)
    }

    public static getSKU() {
        const route = `/core/user/listings/sku`
        return ApiSender.get(route)
    }

    public static removeMultiListing(payload: { listing_ids: string[] }) {
        const route = `/core/user/listings/remove-multi`
        return ApiSender.post(route, payload)
    }

    public static cancelListing(_id: string) {
        const route = `/core/user/listings/${_id}/cancel`
        return ApiSender.post(route)
    }

    public static cloneListing(_id: string) {
        const route = `/core/user/listings/${_id}/clone`
        return ApiSender.post(route)
    }

    /*
     * Define pi for listing auto idea
     * */

    public static getListingsAutoIdea(params: IAutoIdeaQueryParams): Promise<IListApiResponse<IAutoIdeaProduct>> {
        return ApiSender.get('/core/user/listings/auto-idea', params)
    }

    public static createAutoIdeaProduct(
        payload: Omit<IAutoIdeaProduct, 'shop' | 'listing' | '_id' | 'updatedAt' | 'createdAt'> & { shop: string; listing?: string }
    ) {
        return ApiSender.post(`/core/user/listings/auto-idea`, payload)
    }

    public static deleteAutoIdeaProduct(autoIdeaId: string) {
        return ApiSender.delete(`/core/user/listings/auto-idea/${autoIdeaId}`)
    }

    public static restoreAutoIdeaProduct(autoIdeaId: string) {
        return ApiSender.post(`/core/user/listings/auto-idea/${autoIdeaId}/restore`)
    }

    public static draftIdeaProduct(payload: { product_id: string; shop: string; listingId: string }) {
        return ApiSender.put(`/core/user/listings/auto-idea/drafted`, payload)
    }

    public static unDraftIdeaProduct(payload: { product_id: string; shop: string }) {
        return ApiSender.put(`/core/user/listings/auto-idea/un-drafted`, payload)
    }

    public static generateAutoIdeaTitle(title: string): Promise<string> {
        return ApiSender.post(`/core/user/listings/auto-idea/generate-title`, { title })
    }

    public static updateAutoIdeaApiProps(payload: { cUrlParsed: Record<string, any>; service: string }) {
        return ApiSender.put(`/core/user/listings/auto-idea/update-api-props`, payload)
    }

    public static getAutoIdeaCrawlServices() {
        return ApiSender.get(`/core/user/listings/auto-idea/crawl-services`)
    }

    public static getAutoIdeaKeywords(shop?: string): Promise<string[]> {
        return ApiSender.get(`/core/user/listings/auto-idea/keywords`, { shop })
    }

    public static crawlNewData(payload: { shop: ITikTokShop; keywords: string[] }) {
        return ApiSender.post(`/core/user/listings/auto-idea/crawl-new-data`, payload, { timeout: 0 })
    }

    public static searchProducts(payload: { keyword?: string; page?: number }) {
        return ApiSender.get(`/core/user/listings/auto-idea/search-products`, {
            page: payload.page,
            keyword: payload.keyword ? encodeURIComponent(payload.keyword) : undefined
        })
    }
}
