import { Col, Form, InputNumber, Row } from 'antd'
import _ from 'lodash'
import { usePromotionContext } from 'pages/PromotionManagement/PromotionProvider'
import { useEffect } from 'react'
import { ISelectProductPromotion } from 'types/IPromotion'

function DealPriceColumn({ product }: { product: ISelectProductPromotion }) {
    const [form] = Form.useForm()
    const { selectedProducts, saveDataSource } = usePromotionContext()
    const handleSave = async (key: keyof ISelectProductPromotion) => {
        try {
            const values = await form.validateFields() // Kiểm tra giá trị hợp lệ
            // Cập nhật dữ liệu vào bảng
            const _idx = selectedProducts.findIndex((item) => item.product_id === product.product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx] as ISelectProductPromotion;
            (record as any)[key] = values[`${product.product_id}_${key}`]
            saveDataSource(record) // Cập nhật
        } catch (error) {
            console.log('Error during save:', error)
        }
    }
    useEffect(() => {
        if (product) {
            if (_.has(product, 'deal_price_percent')) {
                form.setFieldsValue({ [`${product.product_id}_deal_price_percent`]: product.deal_price_percent })
                let price: any = product.sale_price_ranges[0].price_range.split('-')[0].trim().replace('$', '')
                price = Number(price)
                form.setFieldsValue({
                    [`${product.product_id}_activity_price_amount`]: _.round(price * (1 - Number(product.deal_price_percent) / 100), 2)
                })
                handleSave('activity_price_amount')
            }
        }
    }, [product?.deal_price_percent])

    const onValuesChange = (values: any) => {
        if (_.has(values, `${product.product_id}_deal_price_percent`)) {
            let price: any = product.sale_price_ranges[0].price_range.split('-')[0].trim().replace('$', '')
            price = Number(price)
            form.setFieldsValue({
                [`${product.product_id}_activity_price_amount`]: _.round(price * (1 - values[`${product.product_id}_deal_price_percent`] / 100), 2)
            })
        } else if (_.has(values, `${product.product_id}_activity_price_amount`)) {
            let price: any = product.sale_price_ranges[0].price_range.split('-')[0].trim().replace('$', '')
            price = Number(price)
            form.setFieldsValue({
                [`${product.product_id}_deal_price_percent`]: 100 - _.toInteger((values[`${product.product_id}_activity_price_amount`] / price) * 100)
            })
        }
    }
    return (
        <>
            <Form layout='horizontal' form={form} onValuesChange={onValuesChange}>
                <Row gutter={[10, 10]}>
                    <Col span={10}>
                        <Form.Item
                            name={`${product.product_id}_activity_price_amount`}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Required.` }]}
                        >
                            <InputNumber
                                addonBefore='$'
                                style={{ width: '100%' }}
                                onBlur={() => handleSave('activity_price_amount')}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <span> or </span>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name={`${product.product_id}_deal_price_percent`}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Required.` }]}
                        >
                            <InputNumber
                                addonAfter='%off'
                                style={{ width: '100%' }}
                                onBlur={() => handleSave('deal_price_percent')}
                                size='large'
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default DealPriceColumn
