import React from 'react';
import {IOrder} from "../../../types/IOrder";
import {Table} from "antd";
import {ORDER_ITEM_COLUMNS} from "./_orderColumnItemDefined";

function OrderRowExpandable(order: IOrder) {
    const columns = ORDER_ITEM_COLUMNS(order)
    return (
        <div className='d-flex flex-column'>
            <Table
                bordered
                dataSource={order.items ?? []}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={false}
            />
        </div>
    );
}

export default OrderRowExpandable;
