import React from 'react';

export function formatTime(milliseconds: number) {
    const date = new Date(milliseconds);

    // Format by DD/MM/YYYY pattern
    const formatted = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;

    // Calculate time ago
    const now = Date.now();
    const diffInMilliseconds = now - milliseconds;
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    let timeAgo = '';

    if (diffInMinutes < 60) {
        if (diffInMinutes < 1) {
            timeAgo = `Just now`;
        } else {
            timeAgo = `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        }
    } else if (diffInHours < 24) {
        timeAgo = `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    } else {
        timeAgo = `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
    }

    return {
        formatted,
        timeAgo
    };
}

function AutoIdeaPublishAtColumn(ms: number) {
    const { formatted, timeAgo } = formatTime(ms)

    if (ms === -1) return <>-</>

    return (
        <div>
            <p>{formatted}</p>
            <p>{timeAgo}</p>
        </div>
    )
}

export default AutoIdeaPublishAtColumn;
