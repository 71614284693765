import { useQuery } from '@tanstack/react-query'
import ListingAPI from 'api/ListingAPI'
import { IListingQueryParams } from 'types/IListing'

export const LISTING_KEY = 'listing_list'

export const useListingQuery = (queryParams: IListingQueryParams) => {
    return useQuery({
        queryKey: [LISTING_KEY, queryParams],
        queryFn: () => ListingAPI.getListings(queryParams)
    })
}