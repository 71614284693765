import { useEffect, useState, FC } from 'react'
import { Select, Button, Form, Input, Modal, Divider } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import NicheAPI from 'api/NicheAPI'

const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item?: any
}

const CreateNicheModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [nichesParent, setNichesParent] = useState<any[]>([])
    useEffect(() => {
        setLoading(true)
        NicheAPI.getParentNiches()
            .then((res) => {
                setNichesParent(res)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
        if (item) {
            form.setFieldsValue({
                name: item.name,
                parent_id: item?.parent?._id || ''
            })
        }
    }, [visible])
    const onClose = () => {
        toggleModal(false)
        form.resetFields()
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            setLoading(true)
            if (!item) {
                await NicheAPI.createNiche(values)
                notify('success', 'Tạo Niche thành công!')
            } else {
                await NicheAPI.editNiche(item._id, values)
                notify('success', 'Lưu Niche thành công!')
            }
            onClose()
            refetch()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const renderNichesParent = () => {
        return _.map(nichesParent, (item: any) => {
            return (
                <Option value={item._id} key={item._id}>
                    {item.name}
                </Option>
            )
        })
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title={item ? 'Chỉnh sửa Niche' : 'Tạo Niche'}
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Form name='CreateNiche' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form}>
                <Form.Item label={<b>Niche Name</b>} name='name' rules={[{ required: true, message: 'Vui lòng nhập Niche Name!' }]}>
                    <Input placeholder='Nhập Niche Name' />
                </Form.Item>
                <Form.Item label={<b>Parent Niche</b>} name='parent_id'>
                    <Select allowClear style={{ width: '100%' }} placeholder='Chọn Parent Niche' size='large'>
                        {renderNichesParent()}
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        {item ? 'Lưu Niche' : 'Tạo Niche'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateNicheModal
