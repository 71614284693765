import { Table, Row, Space, Button, Form, Popconfirm } from 'antd'
import { useEffect, useState, FC, useCallback } from 'react'
import { EditOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import CreateNicheModal from './modals/CreateNicheModal'
import NicheAPI from 'api/NicheAPI'

const ListNiche = () => {
    const [niches, setNiches] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)
    const [total, setTotal] = useState(0)
    const [form] = Form.useForm()
    const [visibleModal, setVisibleModal] = useState(false)
    const [editedItem, setEditedItem] = useState<any>(null)
    const getNiches = (query: { page_size: number; page_number: number }) => {
        setLoading(true)
        NicheAPI.getNiches(query)
            .then((res) => {
                if (res.pagination && res.pagination.total >= 0) {
                    setTotal(res.pagination.total)
                }
                setNiches(res.data)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        refetchData()
    }, [])

    const refetchData = () => {
        getNiches({
            page_number: pageNumber,
            page_size: pageSize
        })
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
            getNiches({
                page_number: pageNumber,
                page_size: _pageSize
            })
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
            getNiches({
                page_number: _pageNumber,
                page_size: pageSize
            })
        }
    }

    const toggleAddModal = useCallback(
        (value: boolean) => {
            setVisibleModal(value)
            if (!value) {
                setEditedItem(null)
            }
        },
        [visibleModal, editedItem]
    )

    const edit = (record: any) => {
        setEditedItem(record)
        toggleAddModal(true)
    }
    const onRemove = (_id: string) => {
        setLoading(true)
        NicheAPI.removeNiche(_id)
            .then(() => {
                notify('success', 'Xóa Niche thành công!')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    const columns: any = [
        {
            title: 'Tên Niche',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Parent Niche',
            dataIndex: 'parent',
            key: 'parent',
            render: (text: any, record: any) => record?.parent?.name || '-'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size='large'>
                        <EditOutlined
                            style={{ color: blue.primary, fontSize: '20px' }}
                            type='button'
                            onClick={() => edit(record)}
                            title='Edit Niche'
                        />
                        <Popconfirm
                            title='Bạn chắc chắn xóa Niche này khỏi tài khoản?'
                            description='Xóa Niche sẽ bao gồm xóa Niche được gắn vào Shop?'
                            onConfirm={() => onRemove(record._id)}
                        >
                            <DeleteOutlined style={{ color: red.primary, fontSize: '22px' }} type='button' title='Remove shop' />
                        </Popconfirm>
                    </Space>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]
    return (
        <>
            <Row justify='end' className='mb-3' gutter={[20, 20]}>
                <Space className='mr-2' size='large'>
                    <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />}>
                        Refresh
                    </Button>
                    <Button type='primary' onClick={() => toggleAddModal(true)}>
                        Thêm Niche
                    </Button>
                </Space>
            </Row>
            <Form form={form} component={false}>
                <Table
                    bordered
                    dataSource={niches}
                    columns={columns}
                    loading={loading}
                    pagination={{
                        defaultCurrent: pageNumber,
                        current: pageNumber,
                        pageSize,
                        total,
                        onChange: handleChangePagination,
                        position: ['topLeft', 'bottomRight'],
                        showTotal: (total) => (
                            <span>
                                Tổng số Niches: <strong>{total}</strong>
                            </span>
                        )
                    }}
                    rowKey={(record) => record?._id}
                    scroll={{
                        x: 500
                    }}
                />
            </Form>
            <CreateNicheModal visible={visibleModal} toggleModal={toggleAddModal} refetch={refetchData} item={editedItem} />
        </>
    )
}

export default ListNiche
