import { useEffect, useState, FC } from 'react'
import { Button, Form, Input, DatePicker, Row, Col, Select, Tag } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { IPayloadCreatePromotion } from 'types/IPromotion'
import PromotionAPI from 'api/PromotionAPI'
import dayjs from 'dayjs'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import SelectProducts from './modals/SelectProducts'
import momentTz from 'moment-timezone'
import { TIME_ZONE } from 'const'
import ProductPromotionTable from './Table/ProductPromotionTable'
import { usePromotionContext } from './PromotionProvider'
import SelectListShop from 'core/SelectListShop'
const { Option } = Select

const CreatePromotion = ({ tab }: { tab: number }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const shopsQueryResponse = useAllTikTokShopQuery()
    const [visibleSelectProducts, setVisibleSelectProducts] = useState(false)
    const { selectedProducts, setSelectedProducts, productLevel, setProductLevel } = usePromotionContext()

    useEffect(() => {
        if (tab === 1) {
            onReset()
        }
    }, [tab])

    const onReset = () => {
        form.resetFields()
        setSelectedProducts([])
    }
    const onFinish = async (values: IPayloadCreatePromotion) => {
        console.log('Success:', values)
        console.log('selectedProducts:', selectedProducts)
        const payload = {
            ...values,
            begin_time: dayjs(values.begin_time).unix(),
            end_time: dayjs(values.end_time).unix(),
            products: selectedProducts
        }
        setLoading(true)
        PromotionAPI.createPromotion(payload)
            .then((res) => {
                notify('success', 'Tạo Promotion thành công')
                onReset()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const toggleSelectProducts = (val: boolean) => {
        setVisibleSelectProducts(val)
    }

    const cbChooseProducts = (products: any[]) => {
        const newProducts = selectedProducts
        products.forEach((product) => {
            const index = newProducts.findIndex((item) => item.product_id === product.product_id)
            if (index === -1) {
                const skus = product.skus.map((sku: any) => {
                    return {
                        ...sku,
                        quantity_limit: -1,
                        quantity_per_user: -1,
                        is_on_sale: true
                    }
                })
                product.skus = skus
                newProducts.push(product)
            }
        })
        setSelectedProducts(newProducts)
        toggleSelectProducts(false)
    }
    const onValuesChange = (changedValues: any, allValues: any) => {
        console.log('changedValues:', changedValues)
        if (changedValues.product_level) {
            setProductLevel(changedValues.product_level)
        }
    }
    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }
    return (
        <>
            <Form
                name='PromotionModal'
                layout='vertical'
                onFinish={onFinish}
                autoComplete='off'
                form={form}
                initialValues={{
                    activity_type: 'FLASHSALE',
                    product_level: 'PRODUCT'
                }}
                onValuesChange={onValuesChange}
            >
                <Form.Item label={<b>Shop</b>} name='shop_id' rules={[{ required: true, message: 'Vui lòng chọn cửa hàng!' }]}>
                    <Select allowClear showSearch optionFilterProp='textSearch' style={{ width: '100%' }} placeholder='Chọn cửa hàng' size='large'>
                        {renderListShops()}
                    </Select>
                </Form.Item>
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <Form.Item label={<b>Promotion name</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên Promotion!' }]}>
                            <Input placeholder='Nhập Tên Promotion' size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<b>Loại Promotion</b>}
                            name='activity_type'
                            rules={[{ required: true, message: 'Vui lòng chọn loại Promotion!' }]}
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp='textSearch'
                                style={{ width: '100%' }}
                                placeholder='Chọn loại promotion'
                                size='large'
                            >
                                <Option value='FLASHSALE'>FLASH SALE</Option>
                                <Option value='FIXED_PRICE'>FIXED_PRICE</Option>
                                <Option value='DIRECT_DISCOUNT'>DIRECT_DISCOUNT</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian bắt đầu</b>}
                            name='begin_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian bắt đầu' }]}
                            extra={`Timezone: ${TIME_ZONE}`}
                        >
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                size='large'
                                disabledDate={(current) => current && current < dayjs()}
                                format={(date) => momentTz(date.valueOf()).tz(TIME_ZONE).format('YYYY-MM-DD HH:mm')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian kết thúc</b>}
                            name='end_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian kết thúc' }]}
                            extra={`Timezone: ${TIME_ZONE}`}
                        >
                            <DatePicker
                                showTime={{ format: 'HH:mm' }}
                                size='large'
                                disabledDate={(current) => current && current < dayjs()}
                                format={(date) => momentTz(date.valueOf()).tz(TIME_ZONE).format('YYYY-MM-DD HH:mm')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={<b>Products</b>} name='product_level' rules={[{ required: true, message: 'Vui lòng chọn loại Products!' }]}>
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp='textSearch'
                                style={{ width: '100%' }}
                                placeholder='Chọn loại Products'
                                size='large'
                            >
                                <Option value='PRODUCT'>PRODUCT-LEVEL</Option>
                                <Option value='VARIATION'>VARIATION-LEVEL</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item shouldUpdate={(prevValues: any, curValues: any) => prevValues?.shop_id !== curValues?.shop_id}>
                    {() =>
                        form.getFieldValue('shop_id') && (
                            <Button size='large' onClick={() => toggleSelectProducts(true)}>
                                Chọn sản phẩm
                            </Button>
                        )
                    }
                </Form.Item>
                <ProductPromotionTable />
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading} size='large'>
                        Tạo Promotion
                    </Button>
                </Form.Item>
            </Form>
            <SelectProducts
                visible={visibleSelectProducts}
                toggleModal={toggleSelectProducts}
                cbChoose={cbChooseProducts}
                shop_id={form.getFieldValue('shop_id')}
                selectedProducts={selectedProducts}
            />
        </>
    )
}

export default CreatePromotion
