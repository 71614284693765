import React, { useState } from 'react'
import { useModal } from '../../../contexts/Modal'
import { Button, Col, Modal, Row, Upload } from 'antd'
import { IOrder, OrderItem } from '../../../types/IOrder'
import './artwork.style.scss'
import type { UploadFile, UploadProps } from 'antd'
import OrderAPI from "../../../api/OrderAPI";

function ArtworkUploadModal({ product, order }: { product: OrderItem; order: IOrder }) {
    const { closeModal } = useModal()
    const [frontImage, setFrontImage] = useState<{ file: UploadFile | null; preview: string | null }>({
        file: null,
        preview: null
    })
    const [backImage, setBackImage] = useState<{ file: UploadFile | null; preview: string | null }>({
        file: null,
        preview: null
    })
    const [leftHoodImage, setLeftHoodImage] = useState<{ file: UploadFile | null; preview: string | null }>({
        file: null,
        preview: null
    })
    const [rightHoodImage, setRightHoodImage] = useState<{ file: UploadFile | null; preview: string | null }>({
        file: null,
        preview: null
    })

    const handleChange = (setCallBack: (payload: { file: UploadFile | null; preview: string | null }) => void): UploadProps['onChange'] => (info) => {
        console.log('info', info)
        setCallBack({
            file: info.fileList[0],
            preview: URL.createObjectURL(info.file as unknown as File)
        })
    }

    const uploadImages = async () => {
        console.log(frontImage.file)
        if (frontImage.file && frontImage.file.originFileObj) {
            const formData = new FormData()
            formData.append('file', frontImage.file.originFileObj)
            formData.append('seller_sku', product.seller_sku)
            await OrderAPI.uploadOrderItemArtwork(formData)
        }
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open
            onCancel={() => closeModal()}
            title={'Upload product artwork'}
            footer={null}
            width={800}
            className='artwork-config-modal'
        >
            <Row gutter={24}>
                <Col span={6}>
                    <p>Front image</p>
                    <Upload
                        name='front_image'
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        accept='image/*'
                        onChange={handleChange(setFrontImage)}
                        beforeUpload={() => false}
                    >
                        {frontImage.preview ? (
                            <img src={frontImage.preview} alt='avatar' style={{ width: '80%' }} />
                        ) : (
                            <button style={{ border: 0, background: 'none' }} type='button'>
                                {/*{loading ? <LoadingOutlined/> : <PlusOutlined/>}*/}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                        )}
                    </Upload>
                </Col>
                <Col span={6}>
                    <p>Back image</p>
                    <Upload
                        name='back_image'
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        accept='image/*'
                        onChange={handleChange(setBackImage)}
                        beforeUpload={() => false}
                    >
                        {backImage.preview ? (
                            <img src={backImage.preview} alt='avatar' style={{ width: '80%' }} />
                        ) : (
                            <button style={{ border: 0, background: 'none' }} type='button'>
                                {/*{loading ? <LoadingOutlined/> : <PlusOutlined/>}*/}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                        )}
                    </Upload>
                </Col>
                <Col span={6}>
                    <p>Left hood image</p>
                    <Upload
                        name='left_hood_image'
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        accept='image/*'
                        onChange={handleChange(setLeftHoodImage)}
                        beforeUpload={() => false}
                    >
                        {leftHoodImage.preview ? (
                            <img src={leftHoodImage.preview} alt='avatar' style={{ width: '80%' }} />
                        ) : (
                            <button style={{ border: 0, background: 'none' }} type='button'>
                                {/*{loading ? <LoadingOutlined/> : <PlusOutlined/>}*/}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                        )}
                    </Upload>
                </Col>
                <Col span={6}>
                    <p>Right hood image</p>
                    <Upload
                        name='right_hood_image'
                        listType='picture-card'
                        className='avatar-uploader'
                        showUploadList={false}
                        accept='image/*'
                        onChange={handleChange(setRightHoodImage)}
                        beforeUpload={() => false}
                    >
                        {rightHoodImage.preview ? (
                            <img src={rightHoodImage.preview} alt='avatar' style={{ width: '80%' }} />
                        ) : (
                            <button style={{ border: 0, background: 'none' }} type='button'>
                                {/*{loading ? <LoadingOutlined/> : <PlusOutlined/>}*/}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </button>
                        )}
                    </Upload>
                </Col>
            </Row>
            <Button type='primary' className='mt-4 w-100' onClick={uploadImages}>
                Xác nhận
            </Button>
        </Modal>
    )
}

export default ArtworkUploadModal
