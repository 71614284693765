import React from 'react'
import UserOrder from './ui/Order'
import {Card, Flex} from 'antd'
import { Helmet } from 'react-helmet'
import PackageBlock from './ui/Packages'
import WalletBlock from "./ui/Wallet";

function PaymentCenter() {
    return (
        <Card>
            <Helmet>
                <title>Payment Center</title>
            </Helmet>
            <Flex gap={24}>
                <PackageBlock />
                <WalletBlock />
            </Flex>
            <UserOrder />
        </Card>
    )
}

export default PaymentCenter
