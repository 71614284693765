import { useQuery } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'

export const LIST_FULL_FILL_SERVICE_QUERY_KEY = 'LIST_FULL_FILL_SERVICE_QUERY_KEY'

export const useFulfillServiceQuery = () => {
    return useQuery({
        queryKey: [LIST_FULL_FILL_SERVICE_QUERY_KEY],
        queryFn: OrderAPI.getFulfillService
    })
}
