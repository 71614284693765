import { Card, Tabs } from 'antd'
import { useState, FC } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import DescriptionTemplate from './DescriptionTemplate'
import ConfigProduct from './ConfigProduct'
import PriceTemplate from './PriceTemplate'
const TITLE = 'Cài đặt Template'

const TemplateSetting: FC = () => {
    const [selectedTab, setSelectedTab] = useState(1)
    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
        console.log('key', key)
    }
    const LIST_TABS = [
        {
            id: 1,
            label: 'Cấu hình Product Attributes',
            children: <ConfigProduct tab={selectedTab} />
        },
        {
            id: 2,
            label: 'Cấu hình Color,Size,Price',
            children: <PriceTemplate tab={selectedTab} />
        },
        {
            id: 3,
            label: 'Cấu hình Description',
            children: <DescriptionTemplate tab={selectedTab} />
        }
    ]
    return (
        <Card title='Cài đặt Template'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Tabs
                activeKey={selectedTab.toString()}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: tab.children
                    }
                })}
                onChange={onChangeTab}
            />
        </Card>
    )
}

export default TemplateSetting
