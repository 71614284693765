import { FC, useEffect, useState } from 'react'
import { Modal, Button, Divider, Form, Input, InputNumber, Select, Row, Col, Tag, Space } from 'antd'
import { notify } from 'contexts/Notification'
import _ from 'lodash'
import { useForm } from 'antd/es/form/Form'
import ListingAPI from 'api/ListingAPI'
import { IListing } from '../../../../types/IListing'

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item: IListing | any
}

const EditPriceModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = useForm()
    const [hasColor, setHasColor] = useState(false)

    const onClose = () => {
        toggleModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (item && item.skus && item.sale_properties) {
            const checkColor = item.sale_properties.find((property: any) => property.id === '100000')
            if (checkColor) {
                setHasColor(true)
            }
            let newSkus = item.skus
            newSkus = newSkus.map((sku: any) => {
                if (checkColor) {
                    const tmp = {
                        id: sku.id,
                        color: sku.properties[0].value_name,
                        size: sku.properties[1].value_name,
                        price: Number(sku.base_price.sale_price),
                        seller_sku: sku.seller_sku
                    }
                    return tmp
                } else {
                    const tmp = {
                        id: sku.id,
                        size: sku.properties[0].value_name,
                        price: Number(sku.base_price.sale_price),
                        seller_sku: sku.seller_sku
                    }
                    return tmp
                }
            })
            form.setFieldsValue({ new_skus: newSkus })
        }
    }, [item])

    const onUpdatePriceListing = async (values: any) => {
        try {
            setLoading(true)
            const payload = {
                ...values
            }
            console.log(payload)
            const response = await ListingAPI.editListing(item._id, values)
            notify('success', 'Cập nhật thành công')
            onClose()
            refetch()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Edit price listing' footer={null} width={720} centered>
            <Divider />
            <Form name='EditPriceListing' layout={'vertical'} form={form} onFinish={onUpdatePriceListing} style={{ maxWidth: 720 }}>
                <Form.List name='new_skus'>
                    {(fields) => (
                        <div>
                            {fields.map(({ key, name, ...restField }, idx) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                                    {hasColor && (
                                        <Form.Item {...restField} name={[name, 'color']}>
                                            <Input placeholder='Color' readOnly />
                                        </Form.Item>
                                    )}
                                    <Form.Item {...restField} name={[name, 'size']} rules={[{ required: true, message: 'Missing Size Name' }]}>
                                        <Input placeholder='Size' readOnly />
                                    </Form.Item>
                                    <Form.Item {...restField} name={[name, 'seller_sku']}>
                                        <Input placeholder='Seller SKU' />
                                    </Form.Item>
                                    <Form.Item {...restField} name={[name, 'price']} rules={[{ required: true, message: 'Missing Price' }]}>
                                        <InputNumber placeholder='Price' min={0} />
                                    </Form.Item>
                                </Space>
                            ))}
                        </div>
                    )}
                </Form.List>
                <Form.Item className='d-flex justify-content-center'>
                    <Button size='large' htmlType='submit' type='primary' loading={loading}>
                        Lưu thay đổi
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditPriceModal
