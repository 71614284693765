import { Col, Form, Select, Row } from 'antd/lib'
import useFormInstance from 'antd/lib/form/hooks/useFormInstance'
import { PRODUCT_COMPLAIN_DEFAULT, PRODUCT_COMPLIANCE, PRODUCT_PROPERTIES } from 'const'
import { useEffect, useState } from 'react'
import { IProductProperties, IValue } from 'types/IConfigProduct'
const { Option } = Select

const ProductProperties = ({
    category,
    initialData,
    cbChangeValues
}: {
    category: string[]
    initialData?: IProductProperties[]
    cbChangeValues: (val: IProductProperties[]) => void
}) => {
    const [productProperties, setProductProperties] = useState<IProductProperties[]>([])
    const [productComplain, setProductComplain] = useState<any[]>([])
    const [form] = Form.useForm()
    const watchForm = Form.useWatch([], form)
    useEffect(() => {
        console.log('ProductProperties category', category, typeof category, initialData)
        if (
            category &&
            (['824328', '839944', '601226', '1165712'].join('').includes(category.join('')) ||
                ['824328', '839944', '601226', '1165840'].join('').includes(category.join('')) ||
                ['824328', '839944', '601226', '1165968'].join('').includes(category.join('')))
        ) {
            setProductProperties(PRODUCT_PROPERTIES.MEN_SLEEVELESS_TSHIRT)
            if (!initialData || initialData.length === 0) {
                setProductComplain(PRODUCT_COMPLAIN_DEFAULT.MEN_SLEEVELESS_TSHIRT)
                cbChangeValues(PRODUCT_COMPLAIN_DEFAULT.MEN_SLEEVELESS_TSHIRT)
            }
        } else if (
            category &&
            (['824328', '839944', '601213', '1167504'].join('').includes(category.join('')) ||
                ['824328', '839944', '601213', '1167376'].join('').includes(category.join('')))
        ) {
            setProductProperties(PRODUCT_PROPERTIES.MEN_HOODIES_SWEATER)
            if (!initialData || initialData.length === 0) {
                setProductComplain(PRODUCT_COMPLAIN_DEFAULT.MEN_HOODIES_SWEATER)
                cbChangeValues(PRODUCT_COMPLAIN_DEFAULT.MEN_HOODIES_SWEATER)
            }
        } else if (category && ['601152', '842248', '601302'].join('').includes(category.join(''))) {
            setProductProperties(PRODUCT_PROPERTIES.WOMEN_TOP_TSHIRT)
            if (!initialData || initialData.length === 0) {
                setProductComplain(PRODUCT_COMPLAIN_DEFAULT.MEN_SLEEVELESS_TSHIRT)
                cbChangeValues(PRODUCT_COMPLAIN_DEFAULT.MEN_SLEEVELESS_TSHIRT)
            }
        } else if (
            category &&
            (['601152', '842248', '601295', '901776'].join('').includes(category.join('')) ||
                ['601152', '842248', '601295', '901648'].join('').includes(category.join('')))
        ) {
            setProductProperties(PRODUCT_PROPERTIES.WOMEN_TOPS_HOODIES)
            if (!initialData || initialData.length === 0) {
                setProductComplain(PRODUCT_COMPLAIN_DEFAULT.MEN_SLEEVELESS_TSHIRT)
                cbChangeValues(PRODUCT_COMPLAIN_DEFAULT.MEN_SLEEVELESS_TSHIRT)
            }
        } else {
            setProductProperties([])
            setProductComplain([])
            cbChangeValues([])
        }
        if (initialData && initialData.length > 0) {
            const tmp: any = {}
            initialData.forEach((item) => {
                if (item.values.length > 1) {
                    tmp[item.id] = item.values.map((val) => val.id)
                } else if (item.values[0]) {
                    tmp[item.id] = item.values[0].id
                }
            })
            form.setFieldsValue(tmp)
        } else {
            form.resetFields()
        }
    }, [category, initialData])

    useEffect(() => {
        console.log('watchForm', watchForm)
    }, [watchForm])
    const onChange = (changedValues: any) => {
        let product_properties: IProductProperties[] = [...productComplain]
        console.log('product_properties', product_properties)
        Object.keys(form.getFieldsValue()).forEach((key) => {
            const productProper = productProperties.find((item) => item.id === key)
            const tmp = {
                id: key,
                name: productProper?.name as string,
                values: [] as IValue[]
            }
            if (Array.isArray(form.getFieldsValue()[key])) {
                form.getFieldsValue()[key].forEach((val: string) => {
                    let productVal = productProper?.values.find((item) => item.id === val)
                    if (productVal) {
                        tmp.values.push({
                            id: val,
                            name: productVal?.name as string,
                            is_custom: false
                        })
                    }
                })
            } else {
                let productVal = productProper?.values.find((item) => item.id === form.getFieldsValue()[key])
                if (productVal) {
                    tmp.values.push({
                        id: form.getFieldsValue()[key],
                        name: productVal?.name as string,
                        is_custom: false
                    })
                }
            }
            product_properties.push(tmp)
        })
        cbChangeValues(product_properties)
    }
    const renderSelect = () => {
        if (productProperties.length === 0)
            return (
                <p style={{ color: 'red', fontSize: '18px', marginLeft: '13px', marginBottom: '10px' }}>
                    Vui lòng liên hệ Support để được bổ sung danh mục sản phẩm này!
                </p>
            )
        return productProperties.map((item) => {
            return (
                <Col span={6} className='ml-2 mr-2' key={item.id}>
                    <Form.Item name={item.id} label={item.name}>
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder={'Chọn thuộc tính ' + item.name}
                            optionFilterProp='textSearch'
                            size='large'
                            mode={item.is_multi_choice ? 'multiple' : undefined}
                        >
                            {item.values.map((val) => (
                                <Option key={val.id} value={val.id} textSearch={val.name}>
                                    {val.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            )
        })
    }

    return (
        <Form form={form} name={'ProductProperties'} onValuesChange={onChange} layout='vertical'>
            <Row>{renderSelect()}</Row>
        </Form>
    )
}

export default ProductProperties
