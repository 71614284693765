import { Button, Card, Form, Input } from 'antd'
import { useEffect, useState, FC } from 'react'

import _ from 'lodash'

import { notify } from 'contexts/Notification'
import AuthAPI from 'api/AuthAPI'
import { useForm } from 'antd/es/form/Form'
import { Helmet } from 'react-helmet'
import { IUser } from 'types/IUser'
import { Select } from 'antd/lib'
import StaffAPI from 'api/StaffAPI'
import { useAuth } from 'contexts/Authenticate'
const TITLE = 'Cài đặt tài khoản'
const { Option } = Select
const SettingAccount: FC<any> = ({ tab }: { tab: number }) => {
    const [infoAccount, setInfoAccount] = useState<IUser[]>([])
    const [partnerStaffs, setPartnerStaffs] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [firstLoading, setFirstLoading] = useState(false)
    const [form] = useForm()
    const { user } = useAuth()
    const getInfoAccount = () => {
        setFirstLoading(true)
        AuthAPI.getAccountInfo()
            .then((res) => {
                setInfoAccount(res)
                form.setFieldsValue({
                    email: res.email,
                    first_name: res.first_name,
                    last_name: res.last_name,
                    sku_prefix: res.sku_prefix,
                    kiki_api_key: res.kiki_api_key,
                    // resource_importer_id: res?.resource_importer?.id
                })
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setFirstLoading(false))
    }

    useEffect(() => {
        if (user.isOwner) {
            setFirstLoading(true)
            StaffAPI.getPartnerStaffs()
                .then((res) => {
                    setPartnerStaffs(res)
                    getInfoAccount()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setFirstLoading(false))
        } else {
            getInfoAccount()
        }
    }, [])

    const onUpdateInfoAccount = async (values: any) => {
        try {
            setLoading(true)
            const staff = partnerStaffs.find((item) => item.id === values.resource_importer_id)
            const diff: any = {
                first_name: values.first_name,
                last_name: values.last_name,
                sku_prefix: values.sku_prefix
            }
            if (user.isOwner) {
                diff['kiki_api_key'] = values.kiki_api_key
                diff['resource_importer'] = staff
            }
            await AuthAPI.updateAccountInfo(diff)
            notify('success', 'Cập nhật thông tin tài khoản thành công')
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    const renderPartnerStaff = () => {
        return _.map(partnerStaffs, (item: any) => {
            return (
                <Option value={item.id} key={item.id}>
                    {item.id} - {item.name}
                </Option>
            )
        })
    }
    return (
        <Card title='Cài đặt thông tin tài khoản' loading={firstLoading}>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form layout={'vertical'} form={form} initialValues={{ layout: 'vertical' }} onFinish={onUpdateInfoAccount} style={{ maxWidth: 600 }}>
                <Form.Item label={<b>Email</b>} name='email'>
                    <Input placeholder='Your Email' disabled={true} />
                </Form.Item>
                <Form.Item label={<b>First Name</b>} name='first_name' rules={[{ required: true, message: 'Nhập Tên của bạn!' }]}>
                    <Input placeholder='Enter First Name' />
                </Form.Item>
                <Form.Item label={<b>Last Name</b>} name='last_name'>
                    <Input placeholder='Enter Last Name' />
                </Form.Item>
                <Form.Item label={<b>SKU Prefix</b>} name='sku_prefix' rules={[{ required: true, message: 'Nhập SKU!' }]}>
                    <Input placeholder='Nhập SKU Prefix' />
                </Form.Item>
                {user.isOwner && (
                    <>
                        <Form.Item label={<b>KikiLogin API Key</b>} name='kiki_api_key' rules={[{ required: true, message: 'Nhập KIKI API KEY!' }]}>
                            <Input placeholder='Nhập API Key KikiLogin' />
                        </Form.Item>
                        <Form.Item
                            label={<b>KBT Resource Importer</b>}
                            name='resource_importer_id'
                            rules={[{ required: true, message: 'Chọn KBT Resource Importer' }]}
                        >
                            <Select allowClear style={{ width: '100%' }} placeholder='Chọn KBT Resource Importer' size='large'>
                                {renderPartnerStaff()}
                            </Select>
                        </Form.Item>
                    </>
                )}

                <Form.Item>
                    <Button size='large' htmlType='submit' type='primary' loading={loading}>
                        Cập nhật thông tin
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default SettingAccount
