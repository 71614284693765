import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { SHOP_PLUS_SEARCH_QUERY_KEY } from './index'
import { ShopPlusProduct } from '../../../../types/IAutoIdea'

const analyticFromQueriesData = (queriesData: any[]) => {
    const products: ShopPlusProduct[] = []
    queriesData.forEach((queryData) => {
        if (queryData[1]?.data) {
            products.push(...queryData[1]?.data)
        }
    })

    const removedProducts = products.filter((product) => !product.product_status)

    return {
        products,
        removedProducts,
        totalProducts: products.length,
        totalRemovedProducts: removedProducts.length
    }
}

function ShoplusRemovedRating({ keyword }: { keyword: string }) {
    const queryClient = useQueryClient()

    const queriesData = queryClient.getQueriesData([SHOP_PLUS_SEARCH_QUERY_KEY, { keyword }])

    const { totalProducts, totalRemovedProducts } = analyticFromQueriesData(queriesData)

    if (!totalProducts) return null

    return (
        <div className='mt-3 text-danger w-full' style={{ fontSize: 18 }}>
            Removal rate: <strong>{((totalRemovedProducts / totalProducts) * 100).toFixed(2)}%</strong> ({totalRemovedProducts}/{totalProducts})
        </div>
    )
}

export default ShoplusRemovedRating
