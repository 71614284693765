import { Button, Col, Divider, Form, Image, InputNumber, Row, Select, Space, Table, Typography } from 'antd'
import { usePromotionContext } from '../PromotionProvider'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import CellProductProductStatus from 'core/Table/CellProductStatus'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import DealPriceColumn from './Columns/DealPriceColumn'
import { ISelectProductPromotion } from 'types/IPromotion'
import QuantityLimitColumn from './Columns/QuantityLimitColumn'
import QuantityPerUserColumn from './Columns/QuantityPerUserColumn'
import ExpandSKUTable from './ExpandSKUTable'

const ProductPromotionTable = () => {
    const { selectedProducts, saveDataSource, setSelectedProducts, productLevel } = usePromotionContext()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [itemsQuantityLimit, setItemsQuantityLimit] = useState([{ label: 'No limit', value: -1 }])
    const [itemsQuantityPerUser, setItemsQuantityPerUser] = useState([{ label: 'No limit', value: -1 }])
    const [quantityLimit, setQuantityLimit] = useState(0)
    const [quantityPerUser, setQuantityPerUser] = useState(0)
    const [expandedKeys, setExpandedKey] = useState<string[]>([])
    const [form] = Form.useForm()

    useEffect(() => {
        if (selectedProducts.length > 0) {
            setExpandedKey(selectedProducts.map((d) => d._id) ?? [])
        }

    }, [selectedProducts])
    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys)
    }
    const applyPurchaseLimit = () => {
        if (quantityLimit) {
            const limit = _.toInteger(quantityLimit)
            const newItems = [...itemsQuantityLimit]
            if (newItems.length === 1) {
                newItems.push({ label: `${limit}`, value: limit })
                setItemsQuantityLimit(newItems)
            } else {
                newItems[1] = { label: `${limit}`, value: limit }
                setItemsQuantityLimit(newItems)
            }
            form.setFieldsValue({ quantity_limit: limit })
        }
    }
    const applyCustomPurchaseLimit = () => {
        if (quantityPerUser) {
            const limit = _.toInteger(quantityPerUser)
            const newItems = [...itemsQuantityPerUser]
            if (newItems.length === 1) {
                newItems.push({ label: `${limit}`, value: limit })
                setItemsQuantityPerUser(newItems)
            } else {
                newItems[1] = { label: `${limit}`, value: limit }
                setItemsQuantityPerUser(newItems)
            }
            form.setFieldsValue({ quantity_per_user: limit })
        }
    }
    const onBatchUpdate = () => {
        const diff: any = {}
        diff['quantity_limit'] = form.getFieldValue('quantity_limit')
        diff['quantity_per_user'] = form.getFieldValue('quantity_per_user')
        if (form.getFieldValue('deal_price_percent') > 0) {
            diff['deal_price_percent'] = form.getFieldValue('deal_price_percent')
        }
        selectedRowKeys.forEach((_id) => {
            const index = selectedProducts.findIndex((item) => item._id === _id)
            if (index > -1) {
                if (productLevel === 'PRODUCT') {
                    selectedProducts[index] = {
                        ...selectedProducts[index],
                        ...diff
                    } as any
                } else {
                    const skus = selectedProducts[index].skus
                    const newSkus = skus.map((sku) => {
                        return {
                            ...sku,
                            ...diff
                        }
                    })
                    selectedProducts[index].skus = newSkus
                }
            }
        })
        setSelectedProducts([...selectedProducts])
    }
    const onBatchDelete = () => {
        const newSelectedProducts = selectedProducts.filter((product) => !selectedRowKeys.includes(product._id))
        setSelectedProducts([...newSelectedProducts])
    }
    const onDeleteProduct = (product_id: string) => {
        const newSelectedProducts = selectedProducts.filter((product) => product.product_id !== product_id)
        setSelectedProducts([...newSelectedProducts])
    }
    const onReset = () => {
        form.resetFields()
        setSelectedProducts([])
        setSelectedRowKeys([])
        setItemsQuantityLimit([{ label: 'No limit', value: -1 }])
        setItemsQuantityPerUser([{ label: 'No limit', value: -1 }])
        setQuantityLimit(0)
        setQuantityPerUser(0)
    }
    const columns: ColumnsType<ISelectProductPromotion> = [
        {
            title: 'Thông tin sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record) => {
                return (
                    <Space>
                        <Link to={record.images[0] && _.isArray(record.images[0]?.url_list) && record.images[0]?.url_list[0]} target='_blank'>
                            <Image src={record.images && record.images[0] && record.images[0]?.url_list[0]} width={80} preview={false} />
                        </Link>
                        <CellProductName
                            product_id={record.product_id}
                            product_name={text}
                            skus={record.skus}
                            tiktok_shop={_.pick(record.tiktok_shop, ['name'])}
                        />
                    </Space>
                )
            },
            width: 200
        },
        {
            title: 'Giá bán',
            dataIndex: 'sale_price_ranges',
            key: 'sale_price_ranges',
            render: (text: [], record: any, index: number) => {
                return text.map((sku: any, index: number) => {
                    return <p key={sku.index}>{sku.price_range}</p>
                })
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Deal Price',
            dataIndex: 'activity_price_amount',
            key: 'activity_price_amount',
            width: 350,
            align: 'center',
            render: (text, record) => {
                return productLevel === 'PRODUCT' && <DealPriceColumn product={record} />
            }
        },
        {
            title: 'Total Purchase Limit',
            dataIndex: 'quantity_limit',
            key: 'quantity_limit',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return productLevel === 'PRODUCT' && <QuantityLimitColumn product={record} />
            }
        },
        {
            title: 'Customer Purchase Limit',
            dataIndex: 'quantity_per_user',
            key: 'quantity_per_user',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return <QuantityPerUserColumn product={record} />
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => {
                return <CellProductProductStatus status={text} />
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Typography.Link onClick={() => onDeleteProduct(record.product_id)}>
                        <span style={{ color: 'red', cursor: 'pointer' }}>
                            <b>Delete</b>
                        </span>
                    </Typography.Link>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    return (
        <Form form={form} component={false}>
            {selectedProducts.length > 0 && (
                <Row gutter={[20, 20]} className='mb-4'>
                    <Col span={3}>
                        <div>
                            <Form.Item name='deal_price_percent' label={<b>Deal Price</b>}>
                                <InputNumber style={{ width: '100%' }} size='large' addonAfter='%off' min={1} max={90} />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div>
                            <Form.Item name='quantity_limit' label={<b>Total Purchase Limit</b>}>
                                <Select
                                    placeholder='Total purchase limit'
                                    size='large'
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Space style={{ padding: '0 8px 4px' }}>
                                                <InputNumber value={quantityLimit} onChange={(value) => setQuantityLimit(Number(value))} min={1} />
                                                <Button type='text' onClick={applyPurchaseLimit}>
                                                    Apply
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                    options={itemsQuantityLimit}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </Col>
                    {productLevel === 'PRODUCT' && (
                        <Col span={4}>
                            <div>
                                <Form.Item name='quantity_per_user' label={<b>Custom Purchase Limit</b>}>
                                    <Select
                                        placeholder='Custom Purchase Limit'
                                        size='large'
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Space style={{ padding: '0 8px 4px' }}>
                                                    <InputNumber
                                                        value={quantityPerUser}
                                                        onChange={(value) => setQuantityPerUser(Number(value))}
                                                        min={1}
                                                    />
                                                    <Button type='text' onClick={applyCustomPurchaseLimit}>
                                                        Apply
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                        options={itemsQuantityPerUser}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    )}

                    <Col span={8}>
                        <Space size='large'>
                            <Button size='large' style={{ marginTop: '30px' }} disabled={selectedRowKeys.length === 0} onClick={onBatchUpdate}>
                                Batch update
                            </Button>
                            <Button size='large' style={{ marginTop: '30px' }} disabled={selectedRowKeys.length === 0} onClick={onBatchDelete}>
                                Batch delete
                            </Button>
                            {/* <Button type='primary' htmlType='submit' style={{ marginTop: '22px' }} loading={loading} size='large'>
                                Tạo Promotion
                            </Button> */}
                        </Space>
                    </Col>
                </Row>
            )}
            <Table
                bordered
                dataSource={selectedProducts}
                columns={columns}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
                pagination={{
                    pageSize: 100
                }}
                rowSelection={rowSelection}
                expandable={
                    productLevel === 'VARIATION'
                        ? {
                              expandedRowRender: ExpandSKUTable,
                            //   defaultExpandAllRows: true,
                            //   expandedRowKeys: expandedKeys,
                            //   onExpandedRowsChange: (keys) => setExpandedKey(keys as unknown as string[])
                          }
                        : undefined
                }
            />
        </Form>
    )
}

export default ProductPromotionTable
