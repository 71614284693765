import { Card } from 'antd'
import { FC } from 'react'
import _ from 'lodash'
import Staff from './Staff'
import { Helmet } from 'react-helmet'
const TITLE = 'Quản lý nhân viên'

const StaffManagement: FC = () => {
    return (
        <Card title={TITLE}>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Staff />
        </Card>
    )
}

export default StaffManagement
