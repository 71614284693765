import React from 'react';
import {useTableConfigs} from "../../../../hooks/useTableConfigs";
import {useUserOrderQuery} from "../../queries/user-order.query";
import {USER_ORDER_COLUMNS} from "./Table/_userOrderColumnDefined";
import {Table} from "antd";

function OrderTab() {
    const tableConfigs = useTableConfigs(useUserOrderQuery, { type: ['upgrade_package', 'downgrade_package', 'extend_package'] })

    return (
        <Table
            bordered
            dataSource={tableConfigs.queryResponse?.data?.data ?? []}
            columns={USER_ORDER_COLUMNS}
            loading={tableConfigs.queryResponse?.isFetching}
            pagination={{
                defaultCurrent: tableConfigs.pagination.pageNumber,
                current: tableConfigs.pagination.pageNumber,
                pageSize: tableConfigs.pagination.pageSize,
                total: tableConfigs.pagination.total,
                onChange: tableConfigs.pagination.handleChangePagination,
                position: ['topLeft', 'bottomRight'],
                showTotal: (total) => <span>Total <strong>{total}</strong> orders</span>
            }}
            rowKey={(record) => record?._id}
        />
    );
}

export default OrderTab;
