import { useEffect, useState, FC } from 'react'
import { Button, Form, Modal, Divider, Input, DatePicker, Row, Col, Select, Tag, Table, Typography, Image } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { IPayloadCreatePromotion, IPromotion } from 'types/IPromotion'
import PromotionAPI from 'api/PromotionAPI'
import moment from 'moment'
import dayjs from 'dayjs'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import SelectProducts from './SelectProducts'
import { IProduct } from 'types/IProduct'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import EditableCell from 'core/Table/EditableCell'
import { ColumnType } from 'antd/lib/table'
import momentTz from 'moment-timezone';
import { TIME_ZONE } from 'const'
import CellProductProductStatus from 'core/Table/CellProductStatus'
const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item: IPromotion | any
}

const PromotionModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const shopsQueryResponse = useAllTikTokShopQuery()
    const [visibleSelectProducts, setVisibleSelectProducts] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([])
    useEffect(() => {
        if (visible && item) {
            form.setFieldsValue({
                title: item.title,
                shop_id: item?.tiktok_shop?._id,
                activity_type: item.activity_type,
                product_level: item.product_level,
                begin_time: dayjs.unix(item.begin_time),
                end_time: dayjs.unix(item.end_time)
            })
        }
    }, [visible, item])

    const onClose = () => {
        form.resetFields()
        toggleModal(false)
    }
    const onFinish = async (values: IPayloadCreatePromotion) => {
        console.log('Success:', values)
        console.log('selectedProducts:', selectedProducts)
        const payload = {
            ...values,
            begin_time: dayjs(values.begin_time).unix(),
            end_time: dayjs(values.end_time).unix(),
            products: selectedProducts
        }
        if (item) {
            setLoading(true)
            PromotionAPI.editPromotion(item._id, payload)
                .then((res) => {
                    notify('success', 'Cập nhật Promotion thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
            return
        } else {
            setLoading(true)
            PromotionAPI.createPromotion(payload)
                .then((res) => {
                    notify('success', 'Tạo Promotion thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }
    const toggleSelectProducts = (val: boolean) => {
        setVisibleSelectProducts(val)
    }

    const cbChooseProducts = (products: any[]) => {
        setSelectedProducts(products)
        toggleSelectProducts(false)
    }
    const saveDataSource = (record: any) => {
        console.log('Save data', record)
        const index = selectedProducts.findIndex((item) => item.product_id === record.product_id)
        if (index > -1) {
            selectedProducts[index] = record
            setSelectedProducts([...selectedProducts])
        }
    }

    const onDeleteProduct = (product_id: string) => {
        const newSelectedProducts = selectedProducts.filter((product) => product.product_id !== product_id)
        setSelectedProducts(newSelectedProducts)
    }
    const columns: (ColumnType<IProduct> & { editable?: boolean })[] = [
        {
            title: 'Ảnh Preview',
            dataIndex: 'images',
            key: 'images',
            render: (text: any, record: any, index: number) => {
                return (
                    <Link to={text && text[0] && _.isArray(text[0]?.url_list) && text[0]?.url_list[0]} target='_blank'>
                        <Image src={text && text[0] && text[0]?.url_list[0]} width={120} preview={false} />
                    </Link>
                )
            },
            width: 150,
            align: 'center'
        },
        {
            title: 'Thông tin sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: IProduct) => {
                return <CellProductName product_id={record.product_id} product_name={text} skus={record.skus} tiktok_shop={record.tiktok_shop} />
            },
            width: 200
        },
        {
            title: 'Giá bán',
            dataIndex: 'sale_price_ranges',
            key: 'sale_price_ranges',
            render: (text: [], record: any, index: number) => {
                return text.map((sku: any, index: number) => {
                    return <p key={sku.index}>{sku.price_range}</p>
                })
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Deal Price',
            dataIndex: 'activity_price_amount',
            key: 'activity_price_amount',
            width: 300,
            align: 'center',
            editable: true
        },
        {
            title: 'Total Purchase Limit',
            dataIndex: 'quantity_limit',
            key: 'quantity_limit',
            width: 200,
            align: 'center',
            editable: true
        },
        {
            title: 'Customer Purchase Limit',
            dataIndex: 'quantity_per_user',
            key: 'quantity_per_user',
            width: 200,
            align: 'center',
            editable: true
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => {
                return <CellProductProductStatus status={text} />
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Typography.Link onClick={() => onDeleteProduct(record.product_id)}>
                        <span style={{ color: 'red', cursor: 'pointer' }}>
                            <b>Delete</b>
                        </span>
                    </Typography.Link>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record: IProduct) => ({
                record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editable: true,
                setDataSource: saveDataSource
            })
        }
    })

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title={item ? 'Chỉnh sửa Promotion' : 'Tạo Promotion'}
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Form name='PromotionModal' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form}>
                <Form.Item label={<b>Shop</b>} name='shop_id' rules={[{ required: true, message: 'Vui lòng chọn cửa hàng!' }]}>
                    <Select allowClear showSearch optionFilterProp='textSearch' style={{ width: '100%' }} placeholder='Chọn cửa hàng' size='large'>
                        {renderListShops()}
                    </Select>
                </Form.Item>
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <Form.Item label={<b>Promotion name</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên Promotion!' }]}>
                            <Input placeholder='Nhập Tên Promotion' size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={<b>Loại Promotion</b>}
                            name='activity_type'
                            rules={[{ required: true, message: 'Vui lòng chọn loại Promotion!' }]}
                        >
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp='textSearch'
                                style={{ width: '100%' }}
                                placeholder='Chọn loại promotion'
                                size='large'
                            >
                                <Option value='FLASHSALE'>FLASH SALE</Option>
                                <Option value='FIXED_PRICE'>FIXED_PRICE</Option>
                                <Option value='DIRECT_DISCOUNT'>DIRECT_DISCOUNT</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian bắt đầu</b>}
                            name='begin_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian bắt đầu' }]}
                            extra={`Timezone: ${TIME_ZONE}`}
                        >
                            <DatePicker
                                showTime
                                size='large'
                                disabledDate={(current) => current && current < dayjs()}
                                format={(date) => momentTz(date.valueOf()).tz(TIME_ZONE).format('YYYY-MM-DD HH:mm')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={<b>Thời gian kết thúc</b>}
                            name='end_time'
                            rules={[{ required: true, message: 'Vui lòng Chọn thời gian kết thúc' }]}
                            extra={`Timezone: ${TIME_ZONE}`}
                        >
                            <DatePicker
                                showTime
                                size='large'
                                disabledDate={(current) => current && current < dayjs()}
                                format={(date) => momentTz(date.valueOf()).tz(TIME_ZONE).format('YYYY-MM-DD HH:mm')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={<b>Products</b>} name='product_level' rules={[{ required: true, message: 'Vui lòng chọn loại Products!' }]}>
                            <Select
                                allowClear
                                showSearch
                                optionFilterProp='textSearch'
                                style={{ width: '100%' }}
                                placeholder='Chọn loại Products'
                                size='large'
                            >
                                <Option value='PRODUCT'>PRODUCT-LEVEL</Option>
                                <Option value='VARIATION'>VARIATION-LEVEL</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item shouldUpdate={(prevValues: any, curValues: any) => prevValues?.shop_id !== curValues?.shop_id}>
                    {() =>
                        form.getFieldValue('shop_id') && (
                            <Button size='large' onClick={() => toggleSelectProducts(true)}>
                                Chọn sản phẩm
                            </Button>
                        )
                    }
                </Form.Item>
                <Table
                    bordered
                    dataSource={selectedProducts}
                    columns={mergedColumns}
                    // pagination={{
                    //     defaultCurrent: pageNumber,
                    //     current: pageNumber,
                    //     pageSize,
                    //     total: productQueryResponse?.data?.pagination?.total,
                    //     onChange: handleChangePagination,
                    //     position: ['topLeft', 'bottomRight'],
                    //     showTotal: (total) => <HeaderTotalTable total={total} text='Tổng số Templates:' />
                    // }}
                    rowKey={(record) => record?._id}
                    scroll={{
                        x: 500
                    }}
                />
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading} size='large'>
                        {item ? 'Lưu Promotion' : 'Tạo Promotion'}
                    </Button>
                </Form.Item>
            </Form>
            {/* <SelectProducts
                visible={visibleSelectProducts}
                toggleModal={toggleSelectProducts}
                cbChoose={cbChooseProducts}
                shop_id={form.getFieldValue('shop_id')}
            /> */}
        </Modal>
    )
}

export default PromotionModal
