import ApiSender from './ApiSender'

export default class DescriptionTemplateAPI {
    public static getDescriptionTemplates(params: { page_size: number; page_number: number }) {
        const route = `/core/user/description-templates`
        return ApiSender.get(route, params)
    }

    public static getAllDescriptionTemplates() {
        const route = `/core/user/all-description-templates`
        return ApiSender.get(route)
    }

    public static createDescriptionTemplate(payload: { name: string; description: string }) {
        const route = `/core/user/description-templates`
        return ApiSender.post(route, payload)
    }

    public static editDescriptionTemplate(
        _id: string,
        payload: {
            name: string
            description: string
        }
    ) {
        const route = `/core/user/description-templates/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removeDescriptionTemplate(_id: string) {
        const route = `/core/user/description-templates/${_id}`
        return ApiSender.delete(route)
    }

    public static importDescriptionTemplate(payload: { configs: any[] }) {
        const route = `/core/user/description-templates/import`
        return ApiSender.post(route, payload)
    }
}
