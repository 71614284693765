import React from 'react'
import { Tabs } from 'antd'
import OrderTab from './OrderTab'
import DepositTab from './DepositTab'

function UserOrder() {
    return (
        <div className='mt-3'>
            <Tabs
                defaultActiveKey='Available'
                type='card'
                items={[
                    {
                        key: 'Order',
                        label: 'Đơn hàng',
                        children: <OrderTab />
                    },
                    {
                        key: 'Deposit',
                        label: 'Lịch sử nạp tiền',
                        children: <DepositTab />
                    }
                ]}
            />
        </div>
    )
}

export default UserOrder
