import { Card } from 'antd'
import { FC } from 'react'
import _ from 'lodash'
import ListNiche from './ListNiche'
import { Helmet } from 'react-helmet'
const TITLE = 'Quản lý Niche-Sub Niche'

const NicheManagement: FC = () => {
    return (
        <Card title='Quản lý Niche-Sub Niche'>
             <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <ListNiche />
        </Card>
    )
}

export default NicheManagement
