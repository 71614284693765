import { IPriceTemplate } from 'types/IPriceTemplate'
import ApiSender from './ApiSender'

export default class PriceTemplateAPI {
    public static getPriceTemplates(params: { page_size: number; page_number: number }) {
        const route = `/core/user/price-templates`
        return ApiSender.get(route, params)
    }

    public static getAllPriceTemplates() {
        const route = `/core/user/all-price-templates`
        return ApiSender.get(route)
    }

    public static createPriceTemplate(payload: Pick<IPriceTemplate, 'name' | 'colors' | 'sizes' | 'mixed_attributes'>) {
        const route = `/core/user/price-templates`
        return ApiSender.post(route, payload)
    }

    public static editPriceTemplate(_id: string, payload: Pick<IPriceTemplate, 'name' | 'colors' | 'sizes' | 'mixed_attributes'>) {
        const route = `/core/user/price-templates/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removePriceTemplate(_id: string) {
        const route = `/core/user/price-templates/${_id}`
        return ApiSender.delete(route)
    }

    public static importPriceTemplate(payload: { configs: IPriceTemplate[] }) {
        const route = `/core/user/price-templates/import`
        return ApiSender.post(route, payload)
    }
}
