import React, { useState } from 'react'
import { IAutoIdeaProduct } from '../../../../types/IAutoIdea'
import { Button, Divider, Image } from 'antd'
import { notify } from '../../../../contexts/Notification'
import ListingAPI from '../../../../api/ListingAPI'
import CreateListingModal from '../../ListingManagement/modals/CreateListingModal'
import { EnumListingStatus, IListing } from '../../../../types/IListing'
import UploadAPI from '../../../../api/UploadAPI'
import { CRAWL_SOURCE } from './AutoIdeaProductImageColumn'
import { sleep } from '../../../../utils/time'
import { useAutoIdeaContext } from '../AutoIdeaProvider'
import AutoIdeaTitleGenerator from '../components/AutoIdeaTitleGenerator'
import ShoplusQuickSearchModal from '../../../Dashboard/modals/ShoplusQuickSearchModal'
import AutoIdeaCreateListingHead from '../components/AutoIdeaCreateListingHead'
import { useAutoIdeaListingHandler } from '../hooks/useAutoIdeaListingHandler'
import { useModal } from 'contexts/Modal'

export const PRODUCT_STATUS = {
    available: true,
    removed: false
}

const addImageExtension = (fileName: string) => {
    const validExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.webp']
    const lowerCaseFileName = fileName.toLowerCase()
    const hasValidExtension = validExtensions.some((ext) => lowerCaseFileName.endsWith(ext))
    if (!hasValidExtension) {
        return `${fileName}.jpg`
    }
    return fileName
}

const getImageName = (image: string) => {
    const imagePart = image.split('/')
    const imageName = imagePart[imagePart.length - 1].replace('1000:1000', '')
    return addImageExtension(imageName)
}

function AutoIdeaActionsColumn(record: IAutoIdeaProduct) {
    const { openModal, closeModal } = useModal()
    const { currentTab, tableConfigs } = useAutoIdeaContext()

    const confirmDelete = async () => {
        try {
            await ListingAPI.deleteAutoIdeaProduct(record._id)
            notify('success', 'Delete idea successfully')
            await tableConfigs.queryResponse?.refetch()
        } catch (err) {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    }

    const restore = async () => {
        try {
            await ListingAPI.restoreAutoIdeaProduct(record._id)
            notify('success', 'Restore idea successfully')
            await tableConfigs.queryResponse?.refetch()
        } catch (err) {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    }

    const rollback = async () => {
        try {
            await ListingAPI.unDraftIdeaProduct({
                product_id: record.product_id,
                shop: record.shop._id
            })
            notify('success', 'Rollback idea successfully')
            await tableConfigs.queryResponse?.refetch()
        } catch (err) {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    }

    const openCreateListingModal = () =>
        openModal(
            <CreateListingModal
                refetch={() => {}}
                shop_id={record.shop._id}
                onSuccess={() => tableConfigs.queryResponse?.refetch()}
                successMessage='The listing is in progress, please wait...'
                disabledSKUInput
                ideaRecord={record}
            />
        )

    const openShopPlusQuickSearchModal = () => openModal(<ShoplusQuickSearchModal onClose={closeModal} search={record.title} product={record} />)

    return (
        <>
            {currentTab === 'Available' && (
                <>
                    <Button type='primary' onClick={openCreateListingModal} disabled={record.moreInfo?.product_status === PRODUCT_STATUS.removed}>
                        {record.source === CRAWL_SOURCE.SHOP_PLUS.source && !record.product_status ? 'Removed' : 'Get product'}
                    </Button>
                    <Button type='primary' danger className='mt-2' onClick={confirmDelete}>
                        Delete product
                    </Button>
                </>
            )}
            {currentTab === 'Drafted' &&
                (!record.listing?.status || typeof record.listing?.status === 'string' || record.listing?.status === EnumListingStatus.DRAFT) && (
                    <>
                        <Button type='primary' onClick={rollback}>
                            Rollback
                        </Button>
                    </>
                )}

            {record.source === CRAWL_SOURCE.TIKTOK_SHOP.source && (
                <Button type='primary' onClick={openShopPlusQuickSearchModal} className='mt-2'>
                    Check in Shoplus
                </Button>
            )}
        </>
    )
}

export default AutoIdeaActionsColumn
