import React, { useState } from 'react'
import { Button, Card, Flex, Modal, Select } from 'antd'
import { useModal } from '../../../../../contexts/Modal'
import { usePackageQuery } from '../../../queries/package.query'
import { formatCurrency, USDvsVND } from '../../../../../utils/format'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { IPackage } from '../../../../../types/IPackage'
import { useAuth } from '../../../../../contexts/Authenticate'
import PackageUpgradeModal from "./PackageUpgradeModal";

const PackageBox = (pkg: IPackage) => {
    const [duration, setDuration] = useState('1')
    const { openModal } = useModal()

    const openUpgradePackageModal = () => openModal(<PackageUpgradeModal pkg={pkg} duration={Number(duration)} />, { isSecondModal: true })

    return (
        <Card bordered>
            <h4 className='font-weight-bold'>{pkg.name}</h4>
            <p className='' style={{ height: 40 }}>
                {pkg.description}
            </p>
            <h1
                className='font-weight-bolder mt-3 text-info'
                style={{
                    fontSize: 40,
                    lineHeight: 1
                }}
            >
                $
                {pkg.price_per_shop === 999999999999
                    ? '???'
                    : formatCurrency(pkg.price_per_shop * pkg.shop_limit * Number(duration), { rate: USDvsVND })}
                <span style={{ fontSize: 18 }}></span>
            </h1>
            <div className='d-flex align-items-center mt-4 mb-4'>
                <CheckCircleTwoTone style={{ fontSize: 20 }} />
                <p className='text-lg ml-2'>{pkg.price_per_shop === 999999999999 ? 'Infinity' : pkg.shop_limit} shops</p>
            </div>
            {/*<div className='d-flex align-items-center mt-2 mb-4'>*/}
            {/*    <CheckCircleTwoTone style={{ fontSize: 20 }} />*/}
            {/*    <p className='text-lg ml-2'>Hỗ trợ đến ngày tận thế</p>*/}
            {/*</div>*/}
            <Select style={{ width: '100%' }} placeholder='Chọn thời hạn' size='middle' value={duration} onChange={setDuration}>
                <Select.Option value='1'>1 tháng</Select.Option>
                <Select.Option value='3'>3 tháng</Select.Option>
                <Select.Option value='6'>6 tháng</Select.Option>
                <Select.Option value='12'>1 năm</Select.Option>
                <Select.Option value='24'>2 năm</Select.Option>
            </Select>
            {
                pkg.slug === 'universe' ? (
                    <Button type='primary' className='w-100 mt-3'>
                        Contact customer support
                    </Button>
                ) : (
                    <Button type='primary' onClick={openUpgradePackageModal} className='w-100 mt-3'>
                        Chọn gói
                    </Button>
                )
            }

        </Card>
    )
}

function PackageModal() {
    const { closeModal } = useModal()
    const { data } = usePackageQuery()
    const { user } = useAuth()

    const packages = data?.slice(1)?.filter((pkg) => pkg.slug !== user?.package?.package_detail?.slug) ?? []

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Đổi gói cước' footer={null} width='90%'>
            <Flex className='mt-4' gap={12}>
                {packages.map((pkg) => (
                    <div key={pkg._id} style={{ width: `${100 / packages.length}%` }}>
                        <PackageBox {...pkg} />
                    </div>
                ))}
            </Flex>
        </Modal>
    )
}

export default PackageModal
