import React from 'react'
import {IOrder} from "../../../types/IOrder";
import {Tag} from "antd";

function OrderNameColumn({order}: { order: IOrder}) {
    return (
        <div>
            <p>{order.name}</p>
            <p>{order.full_address}</p>
            <p>{order.phone_number}</p>
            <p className='mb-1'>{order.buyer_message}</p>
            <Tag color='blue'>{order.payment_method_name}</Tag>
        </div>
    )
}

export default OrderNameColumn
