import React from 'react'
import { IOrder } from '../../../types/IOrder'
import {Button, Flex, Popconfirm, Tag} from 'antd'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { notify } from '../../../contexts/Notification'
import dayjs from 'dayjs'
import { formatDate } from '../../../utils/format'
import { LIST_ORDER_QUERY_KEY } from '../queries/order.query'

function OrderPushToFulFillColumn({ order }: { order: IOrder }) {
    const queryClient = useQueryClient()
    const pushToFulfillMutation = useMutation({
        mutationFn: OrderAPI.pushToFulfill,
        onSuccess: () => {
            notify('success', 'Push to fulfill successfully')
            void queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })

    const cancelFulfillMutation = useMutation({
        mutationFn: OrderAPI.cancelFulfillOrder,
        onSuccess: () => {
            notify('success', 'Cancel order successfully')
            void queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })

    const getFulfillOrderMutation = useMutation({
        mutationFn: OrderAPI.getFulfillOrderDetail,
        onSuccess: () => {
            void queryClient.refetchQueries([LIST_ORDER_QUERY_KEY])
        },
        onError: (err) => {
            notify('error', (err as Error).message)
        }
    })

    const isNotReadyToFulfill = order.items.some((item) => !item.ff_info?.service)

    return (
        <>
            {order.more_info?.ff_cancel_fulfill_at ? (
                <>
                    <Tag color='red'>Đơn bị Cancel lúc {formatDate(dayjs.unix(order.more_info.ff_cancel_fulfill_at))}</Tag>
                </>
            ) : order.more_info?.ff_last_push_to_fulfill ? (
                <>
                    <Tag color='green'>Đẩy sang fulfill lúc {formatDate(dayjs.unix(order.more_info.ff_last_push_to_fulfill))}</Tag>
                    <Flex className='mt-2' gap={12}>
                        <Button
                            type='primary'
                            onClick={() => getFulfillOrderMutation.mutateAsync(order.order_id)}
                            loading={getFulfillOrderMutation.isLoading}
                        >
                            Cập nhật trạng thái
                        </Button>
                        <Popconfirm
                            title='Xác nhận hủy'
                            description="Đơn hàng sẽ bị hủy trên hệ thống fulfill. Bạn có chắc chắn tiếp tục?"
                            onConfirm={() => cancelFulfillMutation.mutateAsync(order.order_id)}
                            okText="Yes"
                            cancelText="No"
                        >
                        <Button
                            type='primary'
                            danger
                            loading={cancelFulfillMutation.isLoading}
                        >
                            Hủy đơn hàng
                        </Button>
                        </Popconfirm>
                    </Flex>
                    {/*<Button*/}
                    {/*    disabled={isNotReadyToFulfill}*/}
                    {/*    onClick={() => pushToFulfillMutation.mutateAsync(order._id)}*/}
                    {/*    loading={pushToFulfillMutation.isLoading}*/}
                    {/*    className='mt-2'*/}
                    {/*>*/}
                    {/*    Đẩy lại Fulfill*/}
                    {/*</Button>*/}
                </>
            ) : (
                <Button
                    type='primary'
                    disabled={isNotReadyToFulfill}
                    onClick={() => pushToFulfillMutation.mutateAsync(order._id)}
                    loading={pushToFulfillMutation.isLoading}
                >
                    Đẩy sang Fulfill
                </Button>
            )}
        </>
    )
}

export default OrderPushToFulFillColumn
