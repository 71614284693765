import { Col, Form, InputNumber, Row } from 'antd'
import _ from 'lodash'
import { usePromotionContext } from 'pages/PromotionManagement/PromotionProvider'
import { useEffect } from 'react'
import { ISelectProductPromotion, ISKUItemProductPromotion } from 'types/IPromotion'

function SKUDealPriceColumn({ sku, product_id }: { sku: ISKUItemProductPromotion, product_id: string }) {
    const [form] = Form.useForm()
    const { selectedProducts, saveDataSource } = usePromotionContext()
    const handleSave = async (key: keyof ISelectProductPromotion) => {
        try {
            const values = await form.validateFields() // Kiểm tra giá trị hợp lệ
            // Cập nhật dữ liệu vào bảng
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx] as ISelectProductPromotion
            const skuIdx = record.skus.findIndex((item) => item.id === sku.id)
            if (skuIdx === -1) return
            const skuTmp = record.skus[skuIdx] as ISKUItemProductPromotion
            (skuTmp as any)[key] = values[`${sku.id}_${key}`]
            saveDataSource(record) // Cập nhật
        } catch (error) {
            console.log('Error during save:', error)
        }
    }
    useEffect(() => {
        if (sku?.deal_price_percent) {
            form.setFieldsValue({ [`${sku.id}_deal_price_percent`]: sku.deal_price_percent })
            const price = Number(sku.base_price.sale_price)
            form.setFieldsValue({
                [`${sku.id}_activity_price_amount`]: _.round(price * (1 - Number(sku.deal_price_percent) / 100), 2)
            })
            handleSave('activity_price_amount')
        }
    }, [sku?.deal_price_percent])

    const onValuesChange = (values: any) => {
        console.log('onValuesChange----:', values)
        if (_.has(values, `${sku.id}_deal_price_percent`)) {
            const price = Number(sku.base_price.sale_price)
            form.setFieldsValue({
                [`${sku.id}_activity_price_amount`]: _.round(price * (1 - values[`${sku.id}_deal_price_percent`] / 100), 2)
            })
        } else if (_.has(values, `${sku.id}_activity_price_amount`)) {
            const price = Number(sku.base_price.sale_price)
            form.setFieldsValue({
                [`${sku.id}_deal_price_percent`]: 100 - _.toInteger((values[`${sku.id}_activity_price_amount`] / price) * 100)
            })
        }
    }
    return (
        <>
            <Form layout='horizontal' form={form} onValuesChange={onValuesChange}  className='mb-3'>
                <Row gutter={[10, 10]}>
                    <Col span={10}>
                        <Form.Item name={`${sku.id}_activity_price_amount`} style={{ margin: 0 }} rules={[{ required: true, message: `Required.` }]}>
                            <InputNumber disabled={!sku.is_on_sale} addonBefore='$' style={{ width: '100%' }} onBlur={() => handleSave('activity_price_amount')} size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <span> or </span>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={`${sku.id}_deal_price_percent`} style={{ margin: 0 }} rules={[{ required: true, message: `Required.` }]}>
                            <InputNumber disabled={!sku.is_on_sale} addonAfter='%off' style={{ width: '100%' }} onBlur={() => handleSave('deal_price_percent')} size='large' />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default SKUDealPriceColumn
