import {useQuery} from "@tanstack/react-query";
import ProductTemplateTmpAPI from "api/ProductTemplateTmpAPI";
import { IProduct, IProductTemplateQueryParams } from "types/IProduct";

export const PRODUCT_TEMPLATE_QUERY_KEY = 'product_template_list'

export const useProductTemplateQuery = (queryParams: IProductTemplateQueryParams) => {
    return useQuery({
        queryKey: [PRODUCT_TEMPLATE_QUERY_KEY, queryParams],
        queryFn: () => ProductTemplateTmpAPI.getProductTemplateTmp(queryParams),
        enabled: queryParams.tab
    })
}