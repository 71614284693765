import { useQuery } from '@tanstack/react-query'
import AutoPromotionAPI from 'api/AutoPromotionAPI'
import { IAutoPromotionQueryParams } from 'types/IAutoPromotion'

export const TIKTOK_AUTO_PROMOTION_KEY = 'auto_promotion_list'

export const useAutoPromotionQuery = (queryParams: IAutoPromotionQueryParams) => {
    return useQuery({
        queryKey: [TIKTOK_AUTO_PROMOTION_KEY, queryParams],
        queryFn: () => AutoPromotionAPI.getAutoPromotions(queryParams)
    })
}