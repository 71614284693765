import { Card, Table, Row, Col, Select, Space, Button, Tag, Switch, Tabs, Form, Dropdown, message } from 'antd'
import { useEffect, useState, useCallback, FC } from 'react'
import { DownOutlined, ReloadOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import Search from 'antd/es/input/Search'
import { Helmet } from 'react-helmet'
import { useAllStaffQuery } from 'hooks/queries/staff'
import { ColumnsType } from 'antd/es/table'
import { useAllTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AutoPromotionAPI from 'api/AutoPromotionAPI'
import { useAutoPromotionQuery } from 'hooks/queries/auto-promotion'
import { IAutoPromotion } from 'types/IAutoPromotion'
import dayjs from 'dayjs'
import SetAutoTimeModal from './modals/SetAutoTimeModal'
import { TIME_ZONE } from 'const'
import CellProductName from 'core/Table/CellProductName'
const { Option } = Select
const TITLE = 'Quản lý Promotion'

const AutoPromotion = ({ tab }: { tab: number }) => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleSetTimeModal, setVisibleSetTimeModal] = useState(false)
    const [selectedPromotion, setSelectedPromotion] = useState<IAutoPromotion>()
    const [form] = Form.useForm()
    const formWatch = Form.useWatch([], form)
    const staffsQueryResponse = useAllStaffQuery()
    const shopsQueryResponse = useAllTikTokShopQuery()
    const autoPromotionQueryResponse = useAutoPromotionQuery({
        page_size: pageSize,
        page_number: pageNumber,
        search,
        ...formWatch
    })
    useEffect(() => {
        if (tab === 2) {
            form.resetFields()
            autoPromotionQueryResponse.refetch()
        }
    }, [tab])
    const refetchData = () => {
        autoPromotionQueryResponse.refetch()
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const toggleModal = useCallback(
        (value: boolean) => {
            setVisibleModal(value)
            if (!value) {
                setSelectedPromotion(undefined)
            }
        },
        [visibleModal, selectedPromotion]
    )

    const toggleSetTimeModal = useCallback(
        (value: boolean) => {
            setVisibleSetTimeModal(value)
            if (!value) {
                setSelectedPromotion(undefined)
            }
        },
        [visibleSetTimeModal, selectedPromotion]
    )

    const renderListShops = () => {
        return _.map(shopsQueryResponse?.data, (shop: any) => {
            return (
                <Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                    {shop.name}
                </Option>
            )
        })
    }
    const renderStaffs = () => {
        return _.map(staffsQueryResponse?.data, (staff) => {
            return (
                <Option value={staff._id} key={staff._id} textSearch={staff.full_name}>
                    {staff.full_name}
                </Option>
            )
        })
    }
    const onEditPromotion = (promotion: IAutoPromotion) => {
        setSelectedPromotion(promotion)
        toggleModal(true)
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }
    const onChangeValues = (values: any) => {
        setPageNumber(1)
    }

    const onRemoveAutoPromotion = (_id: string) => {
        setLoading(true)
        AutoPromotionAPI.removeAutoPromotion(_id)
            .then((res) => {
                notify('success', 'Xóa thành công')
                refetchData()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }
    const onCopy = (value: string) => {
        message.success(`Đã Copied: ${value}`)
    }

    const handleMenuClick = (e: any, record: IAutoPromotion) => {
        if (e.key === '1') {
            onEditPromotion(record)
        } else if (e.key === '2') {
            setSelectedPromotion(record)
            toggleSetTimeModal(true)
        } else if (e.key === '3') {
            onRemoveAutoPromotion(record._id)
        }
    }

    const renderMenuItem = (record: IAutoPromotion) => {
        const ListMenus = [
            {
                label: 'Chỉnh sửa',
                key: '1'
            },
            {
                label: 'Hẹn giờ tự động',
                key: '2'
            },
            {
                label: <b style={{ color: 'red' }}>Xóa</b>,
                key: '3'
            }
        ]
        return ListMenus.map((item) => _.pick(item, ['label', 'key']))
    }
    const columns: ColumnsType<IAutoPromotion> = [
        {
            title: 'Thông tin cửa hàng',
            dataIndex: 'tiktok_shop',
            key: 'tiktok_shop',
            width: 250,
            align: 'left',
            render: (tiktok_shop, record) => {
                return <CellProductName tiktok_shop={_.pick(tiktok_shop, ['name', 'profile_name', 'assignee'])} />
            }
        },
        {
            title: 'Promotion name',
            dataIndex: 'title',
            key: 'title',
            width: 300,
            render: (title, record) => {
                return (
                    <>
                        <p style={{ fontSize: '15px' }}>
                            <b>{title}</b>
                        </p>
                    </>
                )
            }
        },
        {
            title: 'Loại',
            dataIndex: 'activity_type',
            key: 'activity_type',
            width: 120,
            align: 'center',
            render: (activity_type, record) => {
                return (
                    <>
                        <p>{activity_type}</p>
                        <p>{record.product_level}</p>
                    </>
                )
            }
        },
        {
            title: 'Số lượng sản phẩm',
            dataIndex: 'products',
            key: 'products',
            width: 150,
            align: 'center',
            render: (products, record) => {
                return <span>{products?.length}</span>
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'is_active',
            key: 'is_active',
            width: 100,
            align: 'center',
            render: (text) => {
                return <Tag color={text ? '#369afe' : '#8c8c8c'}>{text ? 'ACTIVE' : 'INACTIVE'}</Tag>
            }
        },
        {
            title: (
                <span>
                    Ngày bắt đầu
                    <br />
                    (TZ: {TIME_ZONE})
                </span>
            ),
            dataIndex: 'begin_time',
            key: 'begin_time',
            render: (text) => text && dayjs.unix(text).tz(TIME_ZONE).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: (
                <span>
                    Ngày kết thúc
                    <br />
                    (TZ: {TIME_ZONE})
                </span>
            ),
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text) => text && dayjs.unix(text).tz(TIME_ZONE).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },

        {
            title: <span>Thời gian tạo dự kiến</span>,
            dataIndex: 'estimate_create_time',
            key: 'estimate_create_time',
            render: (text) => text && dayjs.unix(text).tz(TIME_ZONE).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Dropdown.Button
                        icon={<DownOutlined />}
                        menu={{
                            items: renderMenuItem(record),
                            onClick: (e) => handleMenuClick(e, record)
                        }}
                        trigger={['click']}
                    >
                        Actions
                    </Dropdown.Button>
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form form={form} onValuesChange={onChangeValues}>
                <Row justify='end' className='mb-3' gutter={[20, 20]}>
                    <Col span={16}>
                        <Row gutter={[20, 20]} className='mb-4'>
                            <Col span={14}>
                                <Search
                                    placeholder='Tìm kiếm theo: Tên, ID, Profile, Template Name'
                                    allowClear
                                    enterButton='Search'
                                    onSearch={_.debounce(onSearch, 250)}
                                    size='large'
                                />
                            </Col>
                        </Row>
                        <Row gutter={[20, 20]}>
                            <Col span={12}>
                                <Form.Item name='shop_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả cửa hàng'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả cửa hàng</Option>
                                        {renderListShops()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name='assignee_id'>
                                    <Select
                                        allowClear
                                        showSearch
                                        optionFilterProp='textSearch'
                                        style={{ width: '100%' }}
                                        placeholder='Tất cả nhân viên'
                                        size='large'
                                    >
                                        <Option value=''>Tất cả nhân viên</Option>
                                        {renderStaffs()}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space className='mr-2' size='large'>
                            <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

            <Table
                bordered
                dataSource={autoPromotionQueryResponse?.data?.data ?? []}
                columns={columns}
                loading={autoPromotionQueryResponse?.isFetching || loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total: autoPromotionQueryResponse?.data?.pagination?.total,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Tổng số promotions: <strong>{total}</strong>
                        </span>
                    )
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
            />
            <SetAutoTimeModal visible={visibleSetTimeModal} toggleModal={toggleSetTimeModal} item={selectedPromotion} refetch={refetchData} />
        </>
    )
}

export default AutoPromotion
