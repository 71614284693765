import React from 'react'
import { Button, Col, Dropdown, Input, Row, Select, Space, Tag } from 'antd'
import Search from 'antd/es/input/Search'
import { useTiktokShopQuery } from '../queries/tiktok-shop.query'
import { DEFAULT_OPTION_ALL_VALUE } from '../../../../const'
import { useAutoIdeaContext } from '../AutoIdeaProvider'
import { ReloadOutlined } from '@ant-design/icons'
import { useModal } from '../../../../hooks/useModal'
import AutoIdeaUpdateAuthModal from '../modals/AutoIdeaUpdateAuthModal'
import { useAutoIdeaKeywordsQuery } from '../queries/auto-idea.query'
import useInput from '../../../../hooks/useInput'
import { notify } from '../../../../contexts/Notification'
import ListingAPI from '../../../../api/ListingAPI'
import { useMutation } from '@tanstack/react-query'
import AutoIdeaUpdateDataResult from '../modals/AutoIdeaUpdateDataResult'
import {useAuth} from "../../../../contexts/Authenticate";

function AutoIdeaTableToolbar() {
    const { user } = useAuth()
    const { data: tiktokShops } = useTiktokShopQuery({ page_number: 1, page_size: 200 })
    const { onFilterChange, filter, tableConfigs } = useAutoIdeaContext()
    const { data: keywords } = useAutoIdeaKeywordsQuery(filter.shop)
    const [visible, toggleModal] = useModal()
    const [visibleUpdateData, toggleUpdateDataModal] = useModal()
    const { mutateAsync, isLoading, data: updateData } = useMutation({
        mutationFn: ListingAPI.crawlNewData,
        onSuccess: () => {
            toggleUpdateDataModal()
            tableConfigs.queryResponse?.refetch()
        },
        onError: (err) => {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    })
    // Local state
    const [keywordSearch, onKeywordSearchChange, { onEnter }] = useInput('')

    // TODO: Do better practice for that filter
    const keywordItems = keywords
        ?.filter((keyword) => keyword.toLowerCase().includes(keywordSearch.toLowerCase()))
        ?.map((keyword) => ({
            key: keyword,
            label: keyword
        }))

    const onFilterShopChange = (shop: string) => onFilterChange(shop, 'shop')

    const onFilterKeywordChange = () => onFilterChange(keywordSearch, 'keyword')

    const onDropdownSelect = ({ key }: { key: string }) => {
        onKeywordSearchChange(key)
        onFilterChange(key, 'keyword')
    }

    const updateCrawlData = async () => {
        const shop = tiktokShops?.find((s) => s._id === filter.shop)
        if (!shop) {
            return
        }
        if (!shop.key_words) {
            notify('error', 'This shop doesnt have any keywords.')
        }
        await mutateAsync({
            shop,
            keywords: filter.keyword ? [filter.keyword] : shop.key_words.split('\n')
        })
    }

    return (
        <Row className='mb-3' gutter={[20, 20]}>
            <Col span={16}>
                <Row gutter={[16, 20]}>
                    <Col span={8}>
                        <Search
                            placeholder='Tìm kiếm theo: Tên sản phẩm, ID, Profile, Template Name'
                            allowClear
                            enterButton='Search'
                            size='large'
                            onSearch={tableConfigs.toolbar.onSearchChange}
                        />
                    </Col>
                    <Col span={8}>
                        <Select
                            showSearch
                            optionFilterProp='textSearch'
                            style={{ width: '100%' }}
                            placeholder='Tất cả cửa hàng'
                            onChange={onFilterShopChange}
                            value={filter.shop}
                            allowClear
                            size='large'
                            onClear={() => onFilterShopChange(DEFAULT_OPTION_ALL_VALUE)}
                        >
                            <Select.Option value={DEFAULT_OPTION_ALL_VALUE}>Tất cả cửa hàng</Select.Option>
                            {tiktokShops?.map((shop) => (
                                <Select.Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                                    {shop.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={5}>
                        <Dropdown
                            menu={{
                                items: keywordItems,
                                selectable: true,
                                onSelect: onDropdownSelect
                            }}
                            trigger={['click']}
                        >
                            <Input
                                size='large'
                                placeholder='Search keyword'
                                value={keywordSearch}
                                onChange={onKeywordSearchChange}
                                onKeyDown={onEnter(onFilterKeywordChange)}
                            />
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
            <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <div className='d-flex flex-wrap' style={{gap: 16}}>
                    {filter.shop && (
                        <Button type='primary' onClick={updateCrawlData} icon={<ReloadOutlined />} size='large' loading={isLoading}>
                            Update Data
                        </Button>
                    )}
                    {
                        user?.email === 'nguyenhopquang@gmail.com' && (
                            <Button type='primary' onClick={toggleModal} icon={<ReloadOutlined />} size='large'>
                                Update Auth
                            </Button>
                        )
                    }
                    <Button type='primary' onClick={() => tableConfigs.queryResponse?.refetch()} icon={<ReloadOutlined />} size='large'>
                        Refresh
                    </Button>
                </div>
            </Col>
            <AutoIdeaUpdateAuthModal visible={visible} onClose={toggleModal} />
            <AutoIdeaUpdateDataResult visible={visibleUpdateData} onClose={toggleUpdateDataModal} data={updateData?.errors} />
        </Row>
    )
}

export default AutoIdeaTableToolbar
