import { ICategory } from "types/ICategory";

export const GroupCategories = (categories: ICategory[]) => {
    let options: {
        value: string
        label: string
        children: any[]
    }[] = []
    categories.map((item: ICategory) => {
        if (item.level === 1) {
            options.push({
                value: item.id,
                label: item.name,
                children: []
            })
        }
    })
    categories.filter((item: ICategory) => item.level === 2).map((item: ICategory) => {
        options.map((option) => {
            if (item.parent_id === option.value) {
                option.children.push({
                    value: item.id,
                    label: item.name,
                    children: []
                })
            }
        })
    })
    categories.filter((item: ICategory) => item.level === 3).map((item: ICategory) => {
        options.map((option) => {
            option.children.map((child) => {
                if (item.parent_id === child.value) {
                    child.children.push({
                        value: item.id,
                        label: item.name,
                        children: []
                    })
                }
            })
        })
    })
    categories.filter((item: ICategory) => item.level === 4).map((item: ICategory) => {
        options.map((option1) => {
            option1.children.map((option2) => {
                if (option2 && option2.children) {
                    option2.children.map((child: any) => {
                        if (item.parent_id === child.value) {
                            child.children.push({
                                value: item.id,
                                label: item.name
                            })
                        }
                    })
                }
            })
        })
    })
    return options
}