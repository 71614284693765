type CurlObject = {
    headers: Record<string, string>;
    params: Record<string, string>;
    body: object | null;
    url: string;
    rawUrl: string;
};

const trimStartChar = (str: string): string => {
    return str.substring(1);
};

const trimEndChar = (str: string): string => {
    return str.substring(0, str.length - 1);
};

const trimChar = (str: string): string => {
    return trimEndChar(trimStartChar(str.trim()));
};


export const parseCurl = (curlCommand: string): CurlObject => {
    const lines = curlCommand.split("\\\n").map(line => line.trim());

    let url = '';
    let rawUrl = '';
    let headers: Record<string, string> = {};
    let params: Record<string, string> = {};
    let body: object | null = null;

    lines.forEach(line => {
        // Extract URL and raw URL
        if (line.startsWith("curl")) {
            const urlMatch = line.split(' ')
            if (urlMatch) {
                rawUrl = urlMatch[urlMatch.length - 1];
                const [baseUrl, queryString] = rawUrl.split("?");
                url = baseUrl;

                if (queryString) {
                    queryString.split("&").forEach(param => {
                        const [key, value] = param.split("=");
                        params[key] = decodeURIComponent(value || '');
                    });
                }
            }
        }

        // Extract headers
        if (line.startsWith("-H '")) {
            const headerMatch = line.match(/-H '(.*?): (.*)'/);
            if (headerMatch) {
                headers[headerMatch[1]] = headerMatch[2];
            }
        }

        // Extract headers
        if (line.startsWith("--header '")) {
            const headerMatch = line.match(/--header '(.*?): (.*)'/);
            if (headerMatch) {
                headers[headerMatch[1]] = headerMatch[2];
            }
        }

        // Extract body (if present)
        if (line.startsWith("--data-raw")) {
            const bodyMatch = line.split('--data-raw');
            if (bodyMatch) {
                try {
                    body = JSON.parse(trimChar(bodyMatch[1]));
                } catch (err) {
                    body = {}
                }
            }
        }

        if (line.startsWith("--data")) {
            const bodyMatch = line.split('--data');
            if (bodyMatch) {
                try {
                    body = JSON.parse(trimChar(bodyMatch[1]));
                } catch (err) {
                    body = {}
                }
            }
        }
    });

    return {
        headers,
        params,
        body,
        url,
        rawUrl
    };
};
