import React, { useState } from 'react'
import { IPromotionProvider, ISelectProductPromotion } from 'types/IPromotion'

const PromotionContext = React.createContext<IPromotionProvider>({
    selectedProducts: [],
    setSelectedProducts: () => {},
    productLevel: 'PRODUCT',
    setProductLevel: () => {},
    saveDataSource: (value: any) => {}
})

function PromotionProvider({ children }: React.PropsWithChildren) {
    const [selectedProducts, setSelectedProducts] = useState<ISelectProductPromotion[]>([])
    const [productLevel, setProductLevel] = useState<'PRODUCT' | 'VARIATION'>('PRODUCT')
    const saveDataSource = (record: any) => {
        console.log('Save data', record)
        const index = selectedProducts.findIndex((item) => item.product_id === record.product_id)
        if (index > -1) {
            selectedProducts[index] = record
            setSelectedProducts([...selectedProducts])
        }
    }

    const onDeleteProduct = (product_id: string) => {
        const newSelectedProducts = selectedProducts.filter((product) => product.product_id !== product_id)
        setSelectedProducts(newSelectedProducts)
    }
    return (
        <PromotionContext.Provider
            value={{
                selectedProducts,
                setSelectedProducts,
                saveDataSource,
                productLevel,
                setProductLevel
            }}
        >
            {children}
        </PromotionContext.Provider>
    )
}

export const usePromotionContext = () => React.useContext(PromotionContext)

export default PromotionProvider
