import React from 'react';
import {Button, Input, Modal, Typography} from "antd";
import {useModal} from "../../../../../contexts/Modal";
import {numberToWords} from "../../../../../utils/format";
import useInput from "../../../../../hooks/useInput";
import PaidModal from "./PaidModal";

function DepositAmountModal({amount }: {amount: string}) {
    const {closeModal, openModal} = useModal()
    const [inputAmount, onInputAmountChange] = useInput(amount)

    const isInvalidAmount = isNaN(Number(inputAmount)) || (Number(inputAmount) <= 0)

    console.log('isValidAmount', isInvalidAmount)
    console.log('Number(amount)', Number(inputAmount))
    const openPaidModal = () => openModal(<PaidModal amount={inputAmount} />)

    return (
        <Modal maskClosable={false} closable open onCancel={() => closeModal()} title='Nạp tiền' footer={null} width={500}>
            <Typography className='mt-2'>Nhập số tiền muốn nạp</Typography>
            <Input value={inputAmount} onChange={onInputAmountChange} placeholder='Nhập số tiền muốn nạp' className='mt-2' />
            <p className='font-italic mt-1'>Bằng chữ: <strong>{numberToWords(Number(inputAmount))} đồng</strong></p>
            <Button type='primary' className='w-100 mt-3' disabled={isInvalidAmount} onClick={openPaidModal}>Xác nhận</Button>
        </Modal>
    );
}

export default DepositAmountModal;
