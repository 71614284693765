import React from 'react'
import { AUTO_IDEA_TABS, useAutoIdeaContext } from './AutoIdeaProvider'
import { Table, Tabs } from 'antd'
import { AUTO_IDEA_COLUMNS } from './Table/_autoIdeaColumnsDefined'

function AutoIdeaTabs() {
    const {
        tableConfigs: { queryResponse, pagination },
        onTabChange,
    } = useAutoIdeaContext()

    return (
        <Tabs
            defaultActiveKey='Available'
            type='card'
            items={Object.values(AUTO_IDEA_TABS).map((tab) => {
                return {
                    key: tab.label,
                    label: tab.label,
                    children: (
                        <Table
                            bordered
                            dataSource={queryResponse?.data?.data ?? []}
                            columns={AUTO_IDEA_COLUMNS}
                            loading={queryResponse?.isFetching}
                            pagination={{
                                defaultCurrent: pagination.pageNumber,
                                current: pagination.pageNumber,
                                pageSize: pagination.pageSize,
                                total: pagination.total,
                                onChange: pagination.handleChangePagination,
                                position: ['topLeft', 'bottomRight'],
                                showTotal: (total) => <span>Total <strong>{total}</strong> products</span>
                            }}
                            rowKey={(record) => record?._id}
                        />
                    ),
                    icon: tab.icon
                }
            })}
            onChange={onTabChange}
        />
    )
}

export default AutoIdeaTabs
