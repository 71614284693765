import { IPayloadCreatePromotion, IPayloadUpdatePromotion, IPromotion, IPromotionQueryParams } from 'types/IPromotion'
import ApiSender from './ApiSender'
import { IListApiResponse } from 'types/IApiResponse'

export default class PromotionAPI {
    public static getPromotions(params: IPromotionQueryParams): Promise<IListApiResponse<IPromotion>> {
        const route = `/core/user/promotions`
        return ApiSender.get(route, params)
    }

    public static syncPromotionsFromTikTokAccount(payload: { shop_id: string }) {
        const route = `/core/user/sync-promotions`
        return ApiSender.post(route, payload)
    }

    public static createPromotion(payload: IPayloadCreatePromotion) {
        const route = `/core/user/promotions`
        return ApiSender.post(route, payload)
    }

    public static editPromotion(_id: string, payload: IPayloadUpdatePromotion) {
        const route = `/core/user/promotions/${_id}`
        return ApiSender.put(route, payload)
    }

    public static deactivatePromotion(_id: string) {
        const route = `/core/user/promotions/${_id}/deactivate`
        return ApiSender.post(route)
    }

    public static updateProductsPromotion(_id: string, payload: {products: any[]}) {
        const route = `/core/user/promotions/${_id}/products`
        return ApiSender.put(route, payload)
    }
}
