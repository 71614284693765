import { useQuery } from '@tanstack/react-query'
import StaffAPI from 'api/StaffAPI'
import { IUser } from 'types/IUser'

export const ALL_STAFF_QUERY_KEY = 'all_staff_list'


export const useAllStaffQuery = () => {
    return useQuery<IUser[]>({
        queryKey: [ALL_STAFF_QUERY_KEY],
        queryFn: () => StaffAPI.getAllStaffs()
    })
}
