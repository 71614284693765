import { ColumnsType } from 'antd/lib/table'
import { IAutoIdeaProduct } from '../../../../types/IAutoIdea'
import AutoIdeaProductImageColumn from './AutoIdeaProductImageColumn'
import AutoIdeaProductColumn from './AutoIdeaProductColumn'
import AutoIdeaCrawlSourceColumn from './AutoIdeaCrawlSourceColumn'
import AutoIdeaPublishAtColumn, { formatTime } from './AutoIdeaPublishAtColumn'
import AutoIdeaActionsColumn from './AutoIdeaActionsColumn'

export const AUTO_IDEA_COLUMNS: ColumnsType<IAutoIdeaProduct> = [
    {
        title: 'Product Image',
        dataIndex: 'image',
        key: 'image',
        render: AutoIdeaProductImageColumn
    },
    {
        title: 'Product',
        dataIndex: 'product_id',
        key: 'product_id',
        render: AutoIdeaProductColumn
    },
    {
        title: 'Keyword',
        dataIndex: 'keyword',
        key: 'keyword'
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (price, record) => (price === -1 ? '-' : `${price} ${record.currency}`),
        width: 120
    },
    {
        title: '7 Day Sales',
        dataIndex: 'week_sold_count',
        key: 'week_sold_count',
        width: 120,
        render: (wsc) => (wsc === -1 ? '-' : wsc)
    },
    {
        title: '30 Day Sales',
        dataIndex: ['moreInfo', 'l30d_sales_volume'],
        key: 'month_sold_count',
        width: 120,
        render: (msc) => msc ?? '-'
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 100,
        render: (_, record) => <AutoIdeaActionsColumn {...record} />
    },
    {
        title: 'Total Sold Count',
        dataIndex: 'sold_count',
        key: 'sold_count',
        width: 120,
        render: (tsc) => (tsc === -1 ? '-' : tsc)
    },
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        width: 120,
        render: AutoIdeaCrawlSourceColumn
    },
    {
        title: 'Publish at',
        dataIndex: 'first_time',
        key: 'first_time',
        width: 120,
        render: AutoIdeaPublishAtColumn
    },
    {
        title: 'Last updated',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        render: (time) => formatTime(new Date(time).getTime()).timeAgo
    },
]
