import React, { useMemo, useState } from 'react'
import { Button, Divider, Image, Modal, Select, Table, Tag } from 'antd'
import Search from 'antd/es/input/Search'
import useInput from '../../../../hooks/useInput'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ListingAPI from '../../../../api/ListingAPI'
import { ColumnsType } from 'antd/lib/table'
import { IAutoIdeaProduct, ShopPlusProduct } from '../../../../types/IAutoIdea'
import AutoIdeaProductImageColumn, { CRAWL_SOURCE } from '../../../ListingProduct/AutoIdea/Table/AutoIdeaProductImageColumn'
import AutoIdeaProductColumn from '../../../ListingProduct/AutoIdea/Table/AutoIdeaProductColumn'
import AutoIdeaPublishAtColumn from '../../../ListingProduct/AutoIdea/Table/AutoIdeaPublishAtColumn'
import { ReloadOutlined } from '@ant-design/icons'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import AutoIdeaCrawlSourceColumn from '../../../ListingProduct/AutoIdea/Table/AutoIdeaCrawlSourceColumn'
import { ITikTokShop } from '../../../../types/ITikTokShop'
import { PRODUCT_STATUS } from '../../../ListingProduct/AutoIdea/Table/AutoIdeaActionsColumn'
import { DEFAULT_OPTION_ALL_VALUE } from '../../../../const'
import { useTiktokShopQuery } from '../../../ListingProduct/AutoIdea/queries/tiktok-shop.query'
import CreateListingModal from '../../../ListingProduct/ListingManagement/modals/CreateListingModal'
import AutoIdeaTitleGenerator from '../../../ListingProduct/AutoIdea/components/AutoIdeaTitleGenerator'
import AutoIdeaCreateListingHead from '../../../ListingProduct/AutoIdea/components/AutoIdeaCreateListingHead'
import ShoplusRemovedRating from './ShoplusRemovedRating'
import { useAutoIdeaListingHandler } from '../../../ListingProduct/AutoIdea/hooks/useAutoIdeaListingHandler'
import { notify } from '../../../../contexts/Notification'
import { AUTO_IDEA_QUERY_KEY } from '../../../ListingProduct/AutoIdea/queries/auto-idea.query'
import { useModal, ModalProps } from 'contexts/Modal'

interface IShopPlusQuickSearch extends ModalProps {
    search?: string
    product?: IAutoIdeaProduct
}

export const SHOP_PLUS_SEARCH_QUERY_KEY = 'SHOP_PLUS_SEARCH_QUERY_KEY'

const mergeProduct = (shopPlusProducts: ShopPlusProduct[], originalProduct: IAutoIdeaProduct): IAutoIdeaProduct[] => {
    return shopPlusProducts.map((product) => {
        const isOriginal = product.id === originalProduct.product_id
        if (isOriginal) {
            return {
                ...originalProduct,
                moreInfo: product
            }
        }

        return {
            _id: product.id,
            product_id: product.id,
            title: product.title,
            image: product.images_privatization[0],
            first_time: product.first_time,
            week_sold_count: product.week_sold_count,
            price: product.min_price,
            source: CRAWL_SOURCE.SHOP_PLUS.source,
            shop: product.shop as unknown as ITikTokShop,
            is_posted: false,
            is_never_use: false,
            keyword: product.title,
            sold_count: product.sold_count,
            currency: product.currency,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            product_status: product.product_status,
            moreInfo: product
        }
    })
}

function ShoplusQuickSearchModal({ onClose, search, product }: IShopPlusQuickSearch) {
    const [keyword, onSearchChange] = useInput(search)
    const [page, setPage] = useState(0)
    const { data: tiktokShops } = useTiktokShopQuery({ page_number: 1, page_size: 200 })

    const [selectedShop, setSelectedShop] = useState(DEFAULT_OPTION_ALL_VALUE)

    const { data, isFetching, refetch } = useQuery({
        queryKey: [SHOP_PLUS_SEARCH_QUERY_KEY, { keyword, page }],
        queryFn: () => ListingAPI.searchProducts({ keyword, page }),
        enabled: !!keyword,
        retry: false
    })

    const _products = useMemo(() => mergeProduct(data?.data ?? [], (product ?? []) as IAutoIdeaProduct), [data, product])

    const _columns = useMemo(() => getSearchColumns({ selectedShop }), [selectedShop])

    const onFilterShopChange = (value: string) => {
        setSelectedShop(value)
    }

    return (
        <Modal maskClosable={false} closable open onCancel={onClose} title='Shoplus Quick Search' footer={null} width='95%' centered>
            <Divider />
            <div className='d-flex align-items-center' style={{ gap: 12 }}>
                <Search
                    defaultValue={keyword}
                    placeholder='Search for products'
                    allowClear
                    enterButton='Search'
                    onSearch={onSearchChange}
                    size='large'
                />
                <Select
                    showSearch
                    optionFilterProp='textSearch'
                    style={{ width: 600 }}
                    placeholder='Tất cả cửa hàng'
                    onChange={onFilterShopChange}
                    value={selectedShop}
                    allowClear
                    size='large'
                    onClear={() => onFilterShopChange(DEFAULT_OPTION_ALL_VALUE)}
                >
                    <Select.Option value={DEFAULT_OPTION_ALL_VALUE}>Tất cả cửa hàng</Select.Option>
                    {tiktokShops?.map((shop) => (
                        <Select.Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                            <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                            {shop.name}
                        </Select.Option>
                    ))}
                </Select>
                {keyword && (
                    <>
                        <Button type='primary' onClick={() => setPage((prev) => (prev - 1 < 0 ? 0 : prev - 1))} icon={<LeftOutlined />} size='large'>
                            Prev Page
                        </Button>
                        <Button type='primary' onClick={() => setPage((prev) => prev + 1)} icon={<RightOutlined />} iconPosition='end' size='large'>
                            Next Page
                        </Button>
                    </>
                )}
                <Button type='primary' onClick={() => refetch()} icon={<ReloadOutlined />} size='large'>
                    Refresh
                </Button>
            </div>
            {data?.errorCode && data.msg && <p className='text-danger font-weight-bold'>{data.msg}</p>}
            <ShoplusRemovedRating keyword={keyword} />
            <Table
                bordered
                dataSource={_products}
                columns={_columns}
                loading={isFetching}
                rowKey={(record) => record?._id}
                className='mt-3'
                scroll={{ y: window.innerHeight - 320 }}
                pagination={false}
            />
        </Modal>
    )
}

export default ShoplusQuickSearchModal

const ActionsColumn = ({ selectedShop, ...record }: IAutoIdeaProduct & { selectedShop: string }) => {
    const { openModal } = useModal()
    const queryClient = useQueryClient()

    const openCreateListingModal = () => {
        if (!selectedShop) return
        openModal(
            <CreateListingModal
                refetch={() => {}}
                shop_id={selectedShop}
                onSuccess={async (listing) => {
                    try {
                        const { _id: _, createdAt: __, updatedAt: ___, ...rest } = record
                        await ListingAPI.createAutoIdeaProduct({
                            ...rest,
                            shop: selectedShop,
                            is_posted: true,
                            is_never_use: false,
                            listing: listing._id
                        })
                        await queryClient.refetchQueries([AUTO_IDEA_QUERY_KEY])
                    } catch (err) {}
                }}
                successMessage='The listing is in progress, please wait...'
                disabledSKUInput
                ideaRecord={record}
            />,
            { isSecondModal: true }
        )
    }

    const confirmDelete = async () => {
        try {
            const { _id: _, createdAt: __, updatedAt: ___, ...rest } = record
            await ListingAPI.createAutoIdeaProduct({
                ...rest,
                shop: selectedShop,
                is_posted: false,
                is_never_use: true,
                listing: undefined
            })
            notify('success', 'Xóa Product thành công')
        } catch (err) {
            if (err instanceof Error) {
                notify('error', err.message)
            }
        }
    }

    return (
        <>
            <Button
                type='primary'
                onClick={openCreateListingModal}
                disabled={!selectedShop || record.moreInfo?.product_status === PRODUCT_STATUS.removed}
            >
                {record.source === CRAWL_SOURCE.SHOP_PLUS.source && !record.product_status ? 'Removed' : 'Get product'}
            </Button>
            <Button type='primary' danger className='mt-2' onClick={confirmDelete} disabled={!selectedShop}>
                Delete product
            </Button>
        </>
    )
}

const getSearchColumns = (props: { selectedShop: string }): ColumnsType<IAutoIdeaProduct> => [
    {
        title: 'Product Image',
        dataIndex: 'image',
        key: 'image',
        width: 175,
        render: AutoIdeaProductImageColumn
    },
    {
        title: 'Product',
        dataIndex: 'product_id',
        key: 'product_id',
        render: AutoIdeaProductColumn
    },
    {
        title: 'Shop',
        dataIndex: 'shop',
        key: 'shop',
        width: 200,
        render: (_, record) => (
            <div className='d-flex align-items-center'>
                <Image
                    src={CRAWL_SOURCE.SHOP_PLUS.imagePrefix + record.moreInfo?.shop?.shop_logo_privatization}
                    width={40}
                    className='rounded-circle flex-shrink-0'
                />
                <p className='ml-2'>{record.moreInfo?.shop?.shop_name}</p>
            </div>
        )
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (price, record) => (price === -1 ? '-' : `${price} ${record.currency}`),
        width: 120
    },
    {
        title: '7 Day Sales',
        dataIndex: 'week_sold_count',
        key: 'week_sold_count',
        width: 120,
        render: (wsc) => (wsc === -1 ? '-' : wsc)
    },
    {
        title: 'Total Sold Count',
        dataIndex: 'sold_count',
        key: 'sold_count',
        width: 120,
        render: (tsc) => (tsc === -1 ? '-' : tsc)
    },
    {
        title: 'Source',
        dataIndex: 'source',
        key: 'source',
        width: 120,
        render: AutoIdeaCrawlSourceColumn
    },
    {
        title: 'Publish at',
        dataIndex: 'first_time',
        key: 'first_time',
        width: 120,
        render: AutoIdeaPublishAtColumn
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 180,
        render: (_, record) => <ActionsColumn {...record} selectedShop={props.selectedShop} />
    }
]
