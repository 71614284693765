import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isToday);
dayjs.extend(utc);

export const USDvsVND = 25000;

export const VNDvsUSD = 1 / USDvsVND;

export const convertVNDToUSD = (vnd: number) => Number(`${vnd / USDvsVND}`);

interface CurrencyFormatOptions {
    prefix?: string;
    suffix?: string;
    digits?: number;
    defaultValue?: string | number;
    rate?: number;
    notation?: 'standard' | 'compact';
    minimumFractionDigits?: number;
}

const defaultCurrencyOptions: CurrencyFormatOptions = {
    prefix: '',
    suffix: '',
    digits: 2,
    rate: 1,
    minimumFractionDigits: 0,
    notation: 'standard',
    defaultValue: '-',
};

export const formatStringLabel = (label: string, { prefix = '', suffix = '' }) =>
    `${prefix ?? ''}${label}${suffix ?? ''}`;

export const formatCurrency = (
    amount?: string | number | null,
    options: CurrencyFormatOptions = defaultCurrencyOptions,
): string => {
    const { prefix, suffix, digits, rate, notation, minimumFractionDigits, defaultValue } = {
        ...defaultCurrencyOptions,
        ...options,
    };
    if (!amount && amount !== 0) return `${defaultValue}` ?? '--';

    if (typeof amount === 'string' && Number.isNaN(Number(amount)) && Number.isNaN(parseFloat(amount + ''))) {
        return amount;
    }
    const convertCurrency =
        new Intl.NumberFormat('en-US', {
            notation,
            maximumFractionDigits: digits ?? 2,
            minimumFractionDigits,
        }).format(parseFloat(amount + '') / (rate as number)) + '';
    return formatStringLabel(convertCurrency, { prefix, suffix });
};

export const middleTruncate = (string: string, options = { head: 4, last: 4 }): string => {
    return `${string.substring(0, options.head)}...${string.substring(string.length - options.last)}`;
};

export const formatDate = (date?: string | number | Date | dayjs.Dayjs | null, format = 'DD/MM/YYYY HH:mm') => {
    return dayjs(date).format(format);
};

export const getDateTimeUTC = (date?: string | number | Date | dayjs.Dayjs | null) => {
    let convertDate = date;
    if (dayjs.isDayjs(date)) {
        convertDate = date.format('YYYY-MM-DD');
    }
    return dayjs.utc(convertDate);
};

export const numberToWords = (num: number) => {
    if (num === 0) return "Không";

    const units = ["", "nghìn", "triệu", "tỷ"];
    const numbers = [
        "không",
        "một",
        "hai",
        "ba",
        "bốn",
        "năm",
        "sáu",
        "bảy",
        "tám",
        "chín",
    ];

    function readThreeDigits(n: number) {
        let result = "";
        const hundreds = Math.floor(n / 100);
        const tens = Math.floor((n % 100) / 10);
        const ones = n % 10;

        if (hundreds > 0) {
            result += numbers[hundreds] + " trăm";
            if (tens === 0 && ones > 0) {
                result += " lẻ";
            }
        }

        if (tens > 0) {
            if (tens === 1) {
                result += " mười";
            } else {
                result += " " + numbers[tens] + " mươi";
            }
        }

        if (ones > 0) {
            if (tens === 1 && ones === 1) {
                // Trường hợp đặc biệt: 11 -> "mười một"
                result += " một";
            } else if (tens > 0 && ones === 1) {
                result += " mốt";
            } else if (tens > 0 && ones === 5) {
                result += " lăm";
            } else {
                result += " " + numbers[ones];
            }
        }

        return result.trim();
    }

    const parts = [];
    let unitIndex = 0;

    while (num > 0) {
        const part = num % 1000;
        if (part > 0) {
            const partInWords = readThreeDigits(part) + " " + units[unitIndex];
            parts.unshift(partInWords.trim());
        }
        num = Math.floor(num / 1000);
        unitIndex++;
    }

    return parts.join(" ").trim().replace(/\s+/g, " ");
}
