import { Switch, Table, Typography } from 'antd'
import { usePromotionContext } from '../PromotionProvider'
import { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { ISelectProductPromotion, ISKUItemProductPromotion } from 'types/IPromotion'
import SKUDealPriceColumn from './ColumnsSKU/SKUDealPriceColumn'
import SKUQuantityLimitColumn from './ColumnsSKU/SKUQuantityLimitColumn'
import SKUQuantityPerUserColumn from './ColumnsSKU/SKUQuantityPerUserColumn'
import CellProductProductStatus from 'core/Table/CellProductStatus'

const ExpandSKUTable = (product: ISelectProductPromotion) => {
    const { selectedProducts, saveDataSource, setSelectedProducts } = usePromotionContext()
    console.log('product:', product)
    const handleOnOff = (sku_id: string) => {
        const _idx = selectedProducts.findIndex((item) => item.product_id === product.product_id)
        if (_idx === -1) return
        const record = selectedProducts[_idx] as ISelectProductPromotion
        const skuIdx = record.skus.findIndex((item) => item.id === sku_id)
        if (skuIdx === -1) return
        const skuTmp = record.skus[skuIdx] as ISKUItemProductPromotion
        skuTmp.is_on_sale = !skuTmp.is_on_sale
        saveDataSource(record)
    }
    const columns: ColumnsType<ISKUItemProductPromotion> = [
        {
            title: 'Variant',
            dataIndex: 'properties',
            key: 'properties',
            render: (text: any[], record) => {
                let itemName = text.reduce((acc, property) => (acc += `${property.value_name} `), '')
                return itemName
            },
            width: 270
        },
        {
            title: 'Giá bán',
            dataIndex: 'base_price',
            key: 'base_price',
            render: (text) => {
                return text?.sale_price_display
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Deal Price',
            dataIndex: 'activity_price_amount',
            key: 'activity_price_amount',
            width: 350,
            align: 'center',
            render: (text, record) => {
                return <SKUDealPriceColumn sku={record} product_id={product.product_id} />
            }
        },
        {
            title: 'Total Purchase Limit',
            dataIndex: 'quantity_limit',
            key: 'quantity_limit',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return <SKUQuantityLimitColumn sku={record} product_id={product.product_id} />
            }
        },
        {
            title: 'Customer Purchase Limit',
            dataIndex: 'quantity_per_user',
            key: 'quantity_per_user',
            width: 200,
            align: 'center',
            render: (text, record) => {
                return <SKUQuantityPerUserColumn sku={record} product_id={product.product_id} />
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => {
                return <CellProductProductStatus status={product.status} />
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Hành động',
            dataIndex: 'is_on_sale',
            key: 'is_on_sale',
            render: (text: boolean, record: any) => {
                return (
                    <Switch
                        checked={text}
                        onClick={() => handleOnOff(record.id)}
                    />
                )
            },
            width: 150,
            fixed: 'right',
            align: 'center'
        }
    ]

    return (
        <Table
            bordered
            columns={columns}
            dataSource={product.skus}
            rowKey={(record) => record?.id}
            scroll={{
                x: 500,
                y: 500
            }}
            pagination={{
                pageSize: 500
            }}
        />
    )
}

export default ExpandSKUTable
