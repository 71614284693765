import ApiSender from './ApiSender'
import { IPackage } from '../types/IPackage'

export default class PackageAPI {
    public static getPackage(): Promise<IPackage[]> {
        return ApiSender.get('/core/packages')
    }

    public static changePackage(payload: { newPackageId: string; duration: number }) {
        return ApiSender.put('/core/user/package/upgrade', payload)
    }

    public static extendPackage(payload: { duration: number }) {
        return ApiSender.put('/core/user/package/extend', payload)
    }

    public static faucetCredit() {
        return ApiSender.put('/core/user/faucet')
    }
}
