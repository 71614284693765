import { useQuery } from '@tanstack/react-query'
import DescriptionTemplateAPI from 'api/DescriptionTemplateAPI'
import { IDescriptionTemplate } from 'types/IDescriptionTemplate'

export const ALL_DESCRIPTION_TEMPLATE_QUERY_KEY = 'all_description_template_list'


export const useAllDescriptionTemplateQuery = () => {
    return useQuery<IDescriptionTemplate[]>({
        queryKey: [ALL_DESCRIPTION_TEMPLATE_QUERY_KEY],
        queryFn: () => DescriptionTemplateAPI.getAllDescriptionTemplates()
    })
}
