import { useQuery } from '@tanstack/react-query'
import OrderAPI from '../../../api/OrderAPI'
import { IOrderQueryParams } from '../../../types/IOrder'
import { useOrderStore } from '../store/order.store'

export const LIST_ORDER_QUERY_KEY = 'LIST_ORDER_QUERY_KEY'

export const useOrderQuery = (queryParams: IOrderQueryParams) => {
    const filter = useOrderStore((state) => state.filter)
    const query = { shop: filter.shop, ...queryParams }
    return useQuery({
        queryKey: [LIST_ORDER_QUERY_KEY, query],
        queryFn: () => OrderAPI.getOrders(query)
    })
}
