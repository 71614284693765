import React, { useContext, useState } from 'react'

interface ModalWithClose {
    onClose: () => void
    isSecondModal?: boolean
}

type ModalProps<T = any> = T & ModalWithClose

interface Modal {
    modalNode?: React.ReactNode
}

type ModalOpener = (
    modalNode?: React.ReactNode,
    options?: {
        isSecondModal?: boolean
    }
) => void

interface ModalContextProps {
    openModal: ModalOpener
    closeModal: (props?: { closeAll?: boolean }) => void
    isModalHasOpening: boolean
}

const ModalContext = React.createContext<ModalContextProps>({
    openModal: () => {
        // do something
    },
    closeModal: () => {
        // do something
    },
    isModalHasOpening: false
})

const ModalProvider = ({ children }: React.PropsWithChildren<any>) => {
    const [ModalNode, setModalNode] = useState<Modal>()
    const [SecondModalNode, setSecondModalNode] = useState<Modal>()

    const onClose = ({ closeAll }: { closeAll?: boolean } | undefined = {}) => {
        if (closeAll) {
            setModalNode(undefined)
            setSecondModalNode(undefined)
        } else {
            if (SecondModalNode) {
                setSecondModalNode(undefined)
            } else {
                setModalNode(undefined)
            }
        }
    }

    return (
        <ModalContext.Provider
            value={{
                openModal: (modalNode, options) => {
                    if (options?.isSecondModal) {
                        setSecondModalNode({
                            modalNode: modalNode
                        })
                    } else {
                        setModalNode({
                            modalNode: modalNode
                        })
                    }
                },
                closeModal: onClose,
                isModalHasOpening: !!ModalNode
            }}
        >
            {ModalNode && <>{ModalNode.modalNode && ModalNode.modalNode}</>}
            {SecondModalNode && <>{SecondModalNode.modalNode && SecondModalNode.modalNode}</>}
            {children}
        </ModalContext.Provider>
    )
}

function useModal(): ModalContextProps {
    return useContext(ModalContext)
}

export { ModalProvider, useModal }
export type { ModalProps, ModalOpener, Modal, ModalContextProps }
