import ApiSender from './ApiSender'

export default class NicheAPI {
    public static getNiches(params: { page_size: number; page_number: number }) {
        const route = `/core/user/niches`
        return ApiSender.get(route, params)
    }

    public static getAllNiches() {
        const route = `/core/user/all-niches`
        return ApiSender.get(route)
    }

    public static getParentNiches() {
        const route = `/core/user/niches/parent`
        return ApiSender.get(route)
    }

    public static createNiche(payload: { name: string; parent_id?: string }) {
        const route = `/core/user/niches`
        return ApiSender.post(route, payload)
    }

    public static editNiche(_id: string, payload: { name: string; parent_id?: string }) {
        const route = `/core/user/niches/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removeNiche(_id: string) {
        const route = `/core/user/niches/${_id}`
        return ApiSender.delete(route)
    }
}
