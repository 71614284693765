import React from 'react'
import { AutoIdeaTab, AutoIdeaTabValue, IAutoIdeasProvider } from '../../../types/IAutoIdea'
import { DEFAULT_OPTION_ALL_VALUE } from '../../../const'
import { useTableConfigs } from '../../../hooks/useTableConfigs'
import { useAutoIdeaQuery } from './queries/auto-idea.query'
import { CheckCircleOutlined, HourglassOutlined } from '@ant-design/icons'

export const AUTO_IDEA_TABS: Record<AutoIdeaTab, AutoIdeaTabValue> = {
    Available: {
        label: 'Available',
        icon: <CheckCircleOutlined />,
        filter: { is_posted: false, is_never_use: false }
    },
    Drafted: {
        label: 'Drafted',
        icon: <HourglassOutlined />,
        filter: { is_posted: true, is_never_use: false }
    },
    Deleted: {
        label: 'Deleted',
        icon: <HourglassOutlined />,
        filter: { is_posted: false, is_never_use: true }
    },
}

const appIdeasContext = React.createContext<IAutoIdeasProvider>({
    tableConfigs: {
        pagination: {
            pageNumber: 1,
            pageSize: 10,
            handleChangePagination: () => {},
            total: 0,
            resetPageNumber: () => {}
        },
        toolbar: {
            search: '',
            onSearchChange: () => {}
        }
    },
    filter: {
        shop: DEFAULT_OPTION_ALL_VALUE,
        keyword: DEFAULT_OPTION_ALL_VALUE
    },
    onFilterChange: () => {},
    onTabChange: () => {},
    currentTab: 'Available'
})

function AutoIdeaProvider({ children }: React.PropsWithChildren) {
    const [filter, setFilter] = React.useState({
        shop: DEFAULT_OPTION_ALL_VALUE,
        keyword: DEFAULT_OPTION_ALL_VALUE
    })
    const [currentTab, setCurrentTab] = React.useState<AutoIdeaTab>('Available')

    const tableConfigs = useTableConfigs(useAutoIdeaQuery, { ...AUTO_IDEA_TABS[currentTab].filter, ...filter })

    const onFilterChange = (value: string, prop: keyof typeof filter) => {
        setFilter(prev => ({ ...prev, [prop]: value }))
        tableConfigs.pagination.resetPageNumber();
    }

    const onTabChange = (key: string) => {
        setCurrentTab(key as AutoIdeaTab)
        tableConfigs.pagination.resetPageNumber();
    }

    return (
        <appIdeasContext.Provider
            value={{
                tableConfigs,
                filter,
                onFilterChange,
                onTabChange,
                currentTab
            }}
        >
            {children}
        </appIdeasContext.Provider>
    )
}

export const useAutoIdeaContext = () => React.useContext(appIdeasContext)

export default AutoIdeaProvider
