import { create } from 'zustand'
import { IOrderStore } from '../interfaces/order-store.interface'

export const useOrderStore = create<IOrderStore>((set) => ({
    filter: {
        shop: '',
        addShop: (shop: string) => set((state) => ({ filter: { ...state.filter, shop } }))
    },
    setFilter: (newFilter) => set({ filter: newFilter }),
    addFilter: (partialFilter) => set((state) => ({ filter: { ...state.filter, ...partialFilter } })),
    removeFilter: (prop) =>
        set((state) => {
            const _filter = { ...state.filter }
            delete _filter[prop]
            return { filter: _filter }
        })
}))
