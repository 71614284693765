import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import * as store from 'utils/storage'
import { ROUTE_ALIAS } from 'const'
import _ from 'lodash'

const queryString = require('query-string')

axios.defaults.timeout = 120000
axios.defaults.headers.common.Accept = 'application/json'

const getApiRoot = (route: string) => {
    let API_ROOT: any = ''
    _.forIn(ROUTE_ALIAS, function (value, key) {
        if (route.indexOf(key) !== -1) API_ROOT = value
    })
    return API_ROOT
}

export default class ApiSender {
    protected static handleResponse(res: AxiosResponse): any {
        if (res.status === 200 && res.data.code === '10000') return res.data.data
        throw new Error(res.data.message)
    }

    protected static handleError(error: AxiosError): any {
        if (error.response) {
            const responseData: { message?: string } = error.response.data as { message?: string }
            throw new Error(responseData?.message)
        } else {
            throw new Error(error.message)
        }
    }

    public static async get(url: string, params = {}): Promise<any> {
        const API_ROOT = getApiRoot(url)
        axios.defaults.baseURL = API_ROOT
        const AUTH_TOKEN = store.get('access_token')
        axios.defaults.headers.common.authorization = AUTH_TOKEN
        return axios
            .get(url, {
                params
                // paramsSerializer(_params) {
                //     return queryString.stringify(_params, {
                //         skipNull: true,
                //         skipEmptyString: true
                //     })
                // }
            })
            .then(this.handleResponse)
            .catch(this.handleError)
    }

    public static post(url: string, data = {}, configs?: AxiosRequestConfig) {
        const API_ROOT = getApiRoot(url)
        axios.defaults.baseURL = API_ROOT
        const AUTH_TOKEN = store.get('access_token')
        axios.defaults.headers.common.authorization = AUTH_TOKEN
        return axios.post(url, data, configs).then(this.handleResponse).catch(this.handleError)
    }

    public static put(url: string, data = {}) {
        const API_ROOT = getApiRoot(url)
        axios.defaults.baseURL = API_ROOT
        const AUTH_TOKEN = store.get('access_token')
        axios.defaults.headers.common.authorization = AUTH_TOKEN
        return axios.put(url, data).then(this.handleResponse).catch(this.handleError)
    }

    public static patch(url: string, data = {}) {
        const API_ROOT = getApiRoot(url)
        axios.defaults.baseURL = API_ROOT
        const AUTH_TOKEN = store.get('access_token')
        axios.defaults.headers.common.authorization = AUTH_TOKEN
        return axios.patch(url, data).then(this.handleResponse).catch(this.handleError)
    }

    public static delete(url: string) {
        const API_ROOT = getApiRoot(url)
        axios.defaults.baseURL = API_ROOT
        const AUTH_TOKEN = store.get('access_token')
        axios.defaults.headers.common.authorization = AUTH_TOKEN
        return axios.delete(url).then(this.handleResponse).catch(this.handleError)
    }
}
