import { useQuery } from '@tanstack/react-query'
import NicheAPI from 'api/NicheAPI'
import { INiche } from 'types/INiche'

export const ALL_NICHE_QUERY_KEY = 'all_niche_list'


export const useAllNicheQuery = () => {
    return useQuery<INiche[]>({
        queryKey: [ALL_NICHE_QUERY_KEY],
        queryFn: () => NicheAPI.getAllNiches()
    })
}
