import { FC, useState } from 'react'
import { Modal, Button, Divider, Form, Select } from 'antd'
import _ from 'lodash'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { notify } from 'contexts/Notification'
import { IUser } from 'types/IUser'
const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetchData: () => void
    staffs: IUser[]
}

const MigrateStaffModal: FC<Props> = ({ visible, toggleModal, refetchData, staffs }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const onClose = () => {
        toggleModal(false)
        form.resetFields()
    }

    const handeMigrate = async (values: any) => {
        console.log(values)
        try {
            setLoading(true)
            await TikTokShopAPI.migrateStaff(values)
            notify('success', 'Chuyển dữ liệu thành công')
            onClose()
            refetchData()
        } catch (err: any) {
            notify('error', err.message)
        } finally {
            setLoading(false)
        }
    }

    const renderStaffs = () => {
        return _.map(staffs, (staff) => {
            return (
                <Option value={staff._id} key={staff._id} textSearch={staff.full_name}>
                    {staff.full_name}
                </Option>
            )
        })
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Chuyển dữ liệu Shops từ nhân viên cũ sang nhân viên mới'
            footer={null}
            width={600}
            centered
        >
            <Divider />
            <Form layout={'vertical'} form={form} initialValues={{ layout: 'vertical' }} onFinish={handeMigrate} style={{ maxWidth: 600 }}>
                <Form.Item label={<b>Chọn Nhân viên cũ</b>} name='src_staff_id' rules={[{ required: true, message: 'Please Select staff!' }]}>
                    <Select allowClear style={{ width: '100%' }} showSearch optionFilterProp='textSearch' placeholder='Chọn Nhân viên' size='large'>
                        {renderStaffs()}
                    </Select>
                </Form.Item>
                <Form.Item label={<b>Chọn Nhân viên mới</b>} name='dest_staff_id' rules={[{ required: true, message: 'Please Select staff!' }]}>
                    <Select allowClear style={{ width: '100%' }} showSearch optionFilterProp='textSearch' placeholder='Chọn Nhân viên' size='large'>
                        {renderStaffs()}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button size='large' htmlType='submit' type='primary' loading={loading}>
                        Chuyển dữ liệu
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default MigrateStaffModal
