import { Button } from 'antd'

const ButtonPublish = ({ ...props }) => {
    return (
        <Button
            {...props}
            style={{
                backgroundImage: 'linear-gradient(to right, #4776E6 0%, #8E54E9 51%, #4776E6 100%)',
                backgroundSize: '200% auto',
                boxShadow: '0 0 20px #eee',
                borderRadius: '10px',
                textTransform: 'uppercase',
                transition: '0.5s'
            }}
            onMouseEnter={
                (e) => (e.currentTarget.style.backgroundPosition = 'right center') // Hover effect
            }
            onMouseLeave={
                (e) => (e.currentTarget.style.backgroundPosition = 'left center') // Remove hover effect
            }
        >
            Đăng sản phẩm
        </Button>
    )
}

export default ButtonPublish
