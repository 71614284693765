import { Card, Tabs } from 'antd'
import { useState, FC } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import SettingAccount from './SettingAccount'
import ChangePassword from './ChangePassword'
import SettingFulfill from "./SettingFulfill";
const TITLE = 'Quản lý tài khoản'

const AccountManagement: FC = () => {
    const [selectedTab, setSelectedTab] = useState(1)
    const onChangeTab = (key: any) => {
        setSelectedTab(Number(key))
        console.log('key', key)
    }
    const LIST_TABS = [
        {
            id: 1,
            label: 'Cài đặt tài khoản',
            children: <SettingAccount tab={selectedTab} />,
        },
        {
            id: 2,
            label: 'Đổi mật khẩu',
            children: <ChangePassword tab={selectedTab} />
        },
        {
            id: 3,
            label: 'Cài đặt fulfill',
            children: <SettingFulfill />
        }
    ]
    return (
        <Card title='Quản lý tài khoản'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Tabs
                activeKey={selectedTab.toString()}
                defaultActiveKey='1'
                type='card'
                items={LIST_TABS.map((tab, i) => {
                    return {
                        key: tab.id.toString(),
                        label: tab.label,
                        children: tab.children
                    }
                })}
                onChange={onChangeTab}
            />
        </Card>
    )
}

export default AccountManagement
