import { Button, Divider, Form, InputNumber, Select, Space } from 'antd'
import _ from 'lodash'
import { usePromotionContext } from 'pages/PromotionManagement/PromotionProvider'
import { useEffect, useState } from 'react'
import { ISelectProductPromotion, ISKUItemProductPromotion } from 'types/IPromotion'

function SKUQuantityLimitColumn({ sku, product_id }: { sku: ISKUItemProductPromotion; product_id: string }) {
    const [form] = Form.useForm()
    const { selectedProducts, saveDataSource } = usePromotionContext()
    const [itemsQuantityLimit, setItemsQuantityLimit] = useState([{ label: 'No limit', value: -1 }])
    const [quantityLimit, setQuantityLimit] = useState(0)
    const handleSave = async (key: keyof ISelectProductPromotion) => {
        try {
            const values = await form.validateFields() // Kiểm tra giá trị hợp lệ
            // Cập nhật dữ liệu vào bảng
            const _idx = selectedProducts.findIndex((item) => item.product_id === product_id)
            if (_idx === -1) return
            const record = selectedProducts[_idx] as ISelectProductPromotion
            const skuIdx = record.skus.findIndex((item) => item.id === sku.id)
            if (skuIdx === -1) return
            const skuTmp = record.skus[skuIdx] as ISKUItemProductPromotion
            ;(skuTmp as any)[key] = values[`${sku.id}_${key}`]
            saveDataSource(record) // Cập nhật
        } catch (error) {
            console.log('Error during save:', error)
        }
    }
    useEffect(() => {
        if (sku) {
            const limit = _.toInteger(sku.quantity_limit || -1)
            if (limit) {
                form.setFieldsValue({
                    [`${sku.id}_quantity_limit`]: limit
                })
                if (limit !== -1) {
                    const newItems = [...itemsQuantityLimit]
                    if (newItems.length === 1) {
                        newItems.push({ label: `${limit}`, value: limit })
                        setItemsQuantityLimit(newItems)
                    } else {
                        newItems[1] = { label: `${limit}`, value: limit }
                        setItemsQuantityLimit(newItems)
                    }
                }
                handleSave('quantity_limit')
            }
        }
    }, [sku?.quantity_limit])

    const applyPurchaseLimit = () => {
        if (quantityLimit) {
            const limit = _.toInteger(quantityLimit)
            form.setFieldsValue({ [`${sku.id}_quantity_limit`]: limit })
            const newItems = [...itemsQuantityLimit]
            if (newItems.length === 1) {
                newItems.push({ label: `${limit}`, value: limit })
                setItemsQuantityLimit(newItems)
            } else {
                newItems[1] = { label: `${limit}`, value: limit }
                setItemsQuantityLimit(newItems)
            }
            handleSave('quantity_limit')
        }
    }
    return (
        <>
            <Form layout='horizontal' form={form} onValuesChange={handleSave}  className='mb-3'>
                <Form.Item name={`${sku.id}_quantity_limit`}>
                    <Select
                        disabled={!sku.is_on_sale}
                        placeholder='Total purchase limit'
                        size='large'
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider style={{ margin: '8px 0' }} />
                                <Space style={{ padding: '0 8px 4px' }}>
                                    <InputNumber
                                        placeholder='Please enter item'
                                        value={quantityLimit}
                                        onChange={(value) => setQuantityLimit(Number(value))}
                                    />
                                    <Button type='text' onClick={applyPurchaseLimit}>
                                        Apply
                                    </Button>
                                </Space>
                            </>
                        )}
                        options={itemsQuantityLimit}
                    />
                </Form.Item>
            </Form>
        </>
    )
}

export default SKUQuantityLimitColumn
