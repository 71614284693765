import { FC, useEffect, useState } from 'react'
import { Modal, Button, Divider, Form, Input, InputNumber, Select, Row, Col, Tag, Image, Table } from 'antd'
import { notify } from 'contexts/Notification'
import _ from 'lodash'
import { IProduct } from 'types/IProduct'
import ProductAPI from 'api/ProductAPI'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import moment from 'moment'
import CellProductProductStatus from 'core/Table/CellProductStatus'
import { ColumnsType } from 'antd/es/table'

const { Search } = Input

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    item: any
}

const CheckDuplicateModal: FC<Props> = ({ visible, toggleModal, item }) => {
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState<IProduct[]>([])
    const [search, setSearch] = useState('')
    const onClose = () => {
        toggleModal(false)
    }

    useEffect(() => {
        if (!visible) return
        if (item && item.product_name) {
            setSearch(item.product_name)
        }
    }, [visible])

    const getProducts = (query: { page_size: number; page_number: number; search?: string; shop_id: string }) => {
        setLoading(true)
        ProductAPI.getProducts(query)
            .then((res) => {
                // if (res.pagination && res.pagination.total >= 0) {
                //     setTotal(res.pagination.total)
                // }
                setProducts(res.data)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const onChangeSearch = (e: any) => {
        setSearch(e.target.value)
    }
    const onSearch = (value: string) => {
        if (value) {
            getProducts({ page_size: 100, page_number: 1, search: value, shop_id: item.tiktok_shop._id })
        }
    }

    const columns: ColumnsType<IProduct> = [
        {
            title: 'Ảnh Preview',
            dataIndex: 'images',
            key: 'images',
            render: (text: any, record: any, index: number) => {
                return (
                    <Link to={text && text[0] && text[0]?.url_list[0]} target='_blank'>
                        <Image src={text && text[0] && text[0]?.url_list[0]} width={200} preview={false} />
                    </Link>
                )
            },
            width: 200,
            align: 'center'
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record: IProduct) => {
                return <CellProductName product_id={record.product_id} product_name={text} skus={record.skus} tiktok_shop={record.tiktok_shop} />
            },
            width: 300
        },
        {
            title: 'SKU',
            dataIndex: 'skus',
            key: 'skus',
            render: (text: any[], record: any, index: number) => {
                if (text && text[0]) {
                    return <p>{text[0].seller_sku}</p>
                }
                return <p>Không có SKU</p>
            },
            width: 120,
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                return <CellProductProductStatus status={text} />
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm'),
            width: 150,
            align: 'center'
        }
    ]

    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Check trùng sản phẩm' footer={null} width={1280} centered>
            <Divider />
            <Search
                placeholder='Tìm kiếm theo: Tên sản phẩm'
                allowClear
                enterButton='Search'
                onSearch={_.debounce(onSearch, 250)}
                size='large'
                className='mb-5'
                value={search}
                onChange={onChangeSearch}
            />
            <Table
                bordered
                dataSource={products}
                columns={columns}
                loading={loading}
                pagination={{
                    defaultCurrent: 1,
                    current: 1,
                    pageSize: 100,
                    total: 0,
                    position: ['bottomRight']
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500,
                    y: 500
                }}
            />
        </Modal>
    )
}

export default CheckDuplicateModal
