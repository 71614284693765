import { Button, Card, Form, Input } from 'antd'
import { useEffect, useState, FC } from 'react'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import AuthAPI from 'api/AuthAPI'
import { useForm } from 'antd/es/form/Form'
import { Helmet } from 'react-helmet'
import { useAuth } from 'contexts/Authenticate'

const TITLE = 'Đổi mật khẩu'
const ChangePassword: FC<any> = ({ tab }: { tab: number }) => {
    const [loading, setLoading] = useState(false)
    const [form] = useForm()
    const { logout, user } = useAuth()

    const onChangePassword = async (values: any) => {
        try {
            if (values.new_pwd !== values.re_pwd) {
                notify('error', 'Mật khẩu mới không khớp')
                return;
            }
            setLoading(true)
            await AuthAPI.changePassword(values)
            notify('success', 'Đổi mật khẩu thành công')
            form.resetFields()
            logout()
        } catch (error: any) {
            notify('error', error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form layout={'vertical'} form={form} initialValues={{ layout: 'vertical' }} onFinish={onChangePassword} style={{ maxWidth: 600 }}>
                <Form.Item label={<b>Mật khẩu cũ</b>} name='old_pwd' rules={[{ required: true, message: 'Nhập Mật khẩu cũ' }]}>
                    <Input placeholder='Nhập Mật khẩu cũ' size='large' />
                </Form.Item>
                <Form.Item label={<b>Mật khẩu mới</b>} name='new_pwd' rules={[{ required: true, message: 'Nhập Mật khẩu mới' }]}>
                    <Input placeholder='Nhập Mật khẩu mới' size='large' />
                </Form.Item>
                <Form.Item label={<b>Nhập lại mật khẩu mới</b>} name='re_pwd' rules={[{ required: true, message: 'Nhập lại mật khẩu mới' }]}>
                    <Input placeholder='Nhập lại mật khẩu mới' size='large' />
                </Form.Item>
                <Form.Item>
                    <Button size='large' htmlType='submit' type='primary' loading={loading}>
                        Đổi mật khẩu
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default ChangePassword
