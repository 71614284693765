import {useQuery} from "@tanstack/react-query";
import {ITikTokShop, ITiktokShopQueryParams} from "../../../../types/ITikTokShop";
import TikTokShopAPI from "../../../../api/TikTokShopAPI";

export const TIKTOK_SHOP_QUERY_KEY = 'tiktok-shop-list'

// TODO: Move this query to tiktok shop management directory
export const useTiktokShopQuery = (queryParams: ITiktokShopQueryParams) => {
    return useQuery<ITikTokShop[]>({
        queryKey: [TIKTOK_SHOP_QUERY_KEY, queryParams],
        queryFn: () => TikTokShopAPI.getTikTokShops(queryParams),
    })
}
