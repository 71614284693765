import { notification } from 'antd'
import _ from 'lodash'

export const notify = (type: any, message: string) => {
    // @ts-ignore
    notification[type]({
        message: _.capitalize(type),
        description: message
    })
}
