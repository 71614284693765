import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    Alignment,
    Bold,
    Italic,
    Underline,
    BlockQuote,
    Base64UploadAdapter,
    CloudServices,
    CKBox,
    Heading,
    HorizontalLine,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    PictureEditing,
    Indent,
    IndentBlock,
    Link,
    List,
    ListProperties,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    TextTransformation,
    ClassicEditor,
    ImageInsert,
    AutoImage,
    ImageResizeEditing,
    ImageResizeHandles,
    ImageResizeButtons
} from 'ckeditor5'

const Editor = ({ onChangeEditor, initialData }: { onChangeEditor: (content: any, editor: any) => void; initialData: string }) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={initialData}
            config={{
                toolbar: {
                    shouldNotGroupWhenFull: true,
                    items: [
                        // --- Block-level formatting -------------------------------------------------------------------
                        'heading',
                        '|',

                        // --- Basic styles and inline formatting -------------------------------------------------------
                        'bold',
                        'italic',
                        'underline',
                        'removeFormat',
                        '|',
                        'link',
                        'insertImage',
                        'blockQuote',
                        'alignment',
                        '|',

                        // --- Lists and indentation --------------------------------------------------------------------
                        'bulletedList',
                        'numberedList',
                        'multilevelList',
                        '|',
                        'outdent',
                        'indent'
                    ]
                },
                plugins: [
                    AutoImage,
                    Alignment,
                    Bold,
                    Italic,
                    Underline,
                    BlockQuote,
                    Base64UploadAdapter,
                    CloudServices,
                    CKBox,
                    Heading,
                    HorizontalLine,
                    Image,
                    ImageCaption,
                    ImageResize,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    ImageInsert,
                    ImageResizeEditing,
                    ImageResizeHandles,
                    ImageResizeButtons,
                    PictureEditing,
                    Indent,
                    IndentBlock,
                    Link,
                    List,
                    ListProperties,
                    Paragraph,
                    PasteFromOffice,
                    RemoveFormat,
                    TextTransformation
                ],
                heading: {
                    options: [
                        {
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'imageStyle:block',
                        'imageStyle:side',
                        'imageStyle:margin-left',
                        'imageStyle:margin-right',
                        'imageStyle:inline',
                        '|',
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'linkImage'
                    ],
                    insert: {
                        // If this setting is omitted, the editor defaults to 'block'.
                        // See explanation below.
                        type: 'auto'
                    }
                }
            }}
            onChange={onChangeEditor}
        />
    )
}

export default Editor
