import { Card, Table, Row, Col, Select, Space, Button, Tag } from 'antd'
import { useEffect, useState, useCallback, FC } from 'react'
import { EditOutlined, ReloadOutlined } from '@ant-design/icons'
import { blue } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'

import { ITikTokAccount } from 'types/ITiktokAccount'
import TikTokShopAPI from 'api/TikTokShopAPI'
import { notify } from 'contexts/Notification'
import { Helmet } from 'react-helmet'
const { Option } = Select
const TITLE = 'Shop Health'

const ShopHealth: FC = () => {
    const [tiktokAccounts, setTiktokAccounts] = useState<ITikTokAccount[]>([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [total, setTotal] = useState(0)

    const [selectedItem, setSelectedItem] = useState<ITikTokAccount>()
    const getShopsHealth = (query: { page_size: number; page_number: number; search?: string }) => {
        setLoading(true)
        TikTokShopAPI.getShopsHealth(query)
            .then((res) => {
                if (res.pagination && res.pagination.total >= 0) {
                    setTotal(res.pagination.total)
                }
                setTiktokAccounts(res.data)
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getShopsHealth({ page_number: pageNumber, page_size: pageSize })
    }, [])

    const refetchData = () => {
        getShopsHealth({ page_number: pageNumber, page_size: pageSize })
    }

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
            getShopsHealth({
                page_number: pageNumber,
                page_size: _pageSize
            })
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
            getShopsHealth({
                page_number: _pageNumber,
                page_size: pageSize
            })
        }
    }

    const onEdit = useCallback((item: any) => {}, [selectedItem])

    const onChangeStatus = (val: any) => {}

    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index',
            render: (text: any, record: any, index: number) => index + 1
        },
        {
            title: 'Tên cửa hàng',
            dataIndex: 'tiktok_shop',
            key: 'tiktok_shop',
            render: (text: any) => {
                return text.name
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Accelerated Settlement',
            dataIndex: 'settlement_days',
            key: 'settlement_days',
            render: () => {
                return <Tag color='green'>Eligible</Tag>
            }
        },
        {
            title: 'Settlement Period',
            dataIndex: 'settlement_days',
            key: 'settlement_days'
        },
        {
            title: 'Shop Violations',
            dataIndex: 'shop_violation',
            key: 'shop_violation',
            render: (text: any) => {
                return text && text.value
            }
        },
        {
            title: 'Product Violations',
            dataIndex: 'product_violation',
            key: 'product_violation',
            render: (text: any) => {
                return text && text.value
            }
        },
        {
            title: 'Seller Fault Cancel Rate',
            dataIndex: 'seller_fault_cancellation_rate',
            key: 'seller_fault_cancellation_rate',
            render: (text: any) => {
                return text && text.value
            }
        },
        {
            title: 'Late Dispatch Rate',
            dataIndex: 'late_dispatch_rate',
            key: 'late_dispatch_rate',
            render: (text: any) => {
                return text && text.value
            }
        },
        {
            title: 'Valid Tracking Rate',
            dataIndex: 'valid_tracking_rate',
            key: 'valid_tracking_rate',
            render: (text: any) => {
                return text && text.value
            }
        },
        {
            title: 'Total Listing',
            dataIndex: 'listing_count',
            key: 'total_listing',
            render: (text: any[]) => {
                return text && text.find((e) => e.tab_id === 1).count
            }
        },
        {
            title: 'Order Overdue',
            dataIndex: 'action_needed_order_count',
            key: 'order_overdue',
            render: (text: any[]) => {
                return text && text.find((e) => e.column_id === '100300').order_count
            }
        },
        {
            title: 'Bank Number',
            dataIndex: 'bank_data',
            key: 'bank_data',
            render: (text: any) => {
                return text?.bank_account_number ? text.bank_account_number : <Tag color='red'>No Bank</Tag>
            }
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text: any, record: any) => moment(text).format('DD/MM/YYYY HH:mm')
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size='middle'>
                    <EditOutlined style={{ color: blue.primary }} type='button' onClick={() => onEdit(record)} title='Edit course' />
                </Space>
            )
        }
    ]
    return (
        <Card title='Tình trạng cửa hàng'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Row justify='end' className='mb-3' gutter={[20, 20]}>
                <Space size='large' className='mr-2'>
                    <Select allowClear showArrow style={{ width: '100%' }} placeholder='Filter by status' onChange={onChangeStatus}>
                        <Option value={null} key={-1}>
                            ALL STATUS
                        </Option>
                    </Select>
                    <Button type='primary' onClick={refetchData} icon={<ReloadOutlined />}>
                        Refresh
                    </Button>
                </Space>
            </Row>
            <Table
                bordered
                dataSource={_.orderBy(tiktokAccounts, ['id'], ['desc'])}
                columns={columns}
                loading={loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight']
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500
                }}
            />
        </Card>
    )
}

export default ShopHealth
