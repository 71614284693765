export const CODE_PERMISSION = {
    REVIEW: 'review',
    DASH_BOARD: 'DASH_BOARD',
    PRODUCT_MANAGEMENT: 'PRODUCT_MANAGEMENT',
    TEMPLATE_MANAGEMENT: 'TEMPLATE_MANAGEMENT',
    NICHE_MANAGEMENT: 'NICHE_MANAGEMENT',
    FROZEN_MANAGEMENT: 'FROZEN_MANAGEMENT',
    LISTING_MANAGEMENT: 'LISTING_MANAGEMENT',
    FLASH_SALE_MANAGEMENT: 'FLASH_SALE_MANAGEMENT',
    ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
    SHOP_HEALTH: 'SHOP_HEALTH',
    CONNECT_SHOP: 'CONNECT_SHOP',
    STAFF_MANAGEMENT: 'STAFF_MANAGEMENT',
    ROLE_MANAGEMENT: 'ROLE_MANAGEMENT',
};