import {useQuery} from "@tanstack/react-query";
import ListingAPI from "../../../../api/ListingAPI";
import {IAutoIdeaQueryParams} from "../../../../types/IAutoIdea";

export const AUTO_IDEA_QUERY_KEY = 'auto-idea-list'
export const AUTO_IDEA_KEYWORDS_QUERY_KEY = 'auto-idea-keywords'

export const useAutoIdeaQuery = (queryParams: IAutoIdeaQueryParams) => {
    return useQuery({
        queryKey: [AUTO_IDEA_QUERY_KEY, queryParams],
        queryFn: () => ListingAPI.getListingsAutoIdea(queryParams),
    })
}

export const useAutoIdeaKeywordsQuery = (shop?: string) => {
    return useQuery({
        queryKey: [AUTO_IDEA_KEYWORDS_QUERY_KEY, shop],
        queryFn: () => ListingAPI.getAutoIdeaKeywords(shop)
    })
}
