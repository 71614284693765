import { ITableParams } from 'hooks/useTableConfigs'
import { IProduct } from './IProduct'
import { ITikTokAccount } from './ITiktokAccount'
import { IUser } from './IUser'

export enum EnumListingStatus {
    DRAFT = 1,
    WAITING,
    PROCESSING,
    SUCCESS,
    FAILED,
    RETRY
}

export interface IListing {
    _id: string
    product_name: string
    category_id: string
    brand_id: string
    model_product: string
    description: string
    images: object[] | any
    packing_list: string
    warranty_period: number
    warranty_policy: string
    product_properties: object[]
    package_weight: string
    package_dimension_unit: number
    packae_height: number
    package_width: number
    package_length: number
    is_cod_open: boolean
    sale_properties: object[]
    warehouse_id: string
    product_source: number
    size_chart_type: number
    logistics_services: object[]
    publish_event_param: object
    is_not_for_sale: boolean
    size_chart_image: object
    skus: object[]
    appended_seo_words: object[]
    from_new_oc: boolean
    tiktok_shop: ITikTokAccount
    user: IUser
    status: EnumListingStatus
    product_template: IProduct
    note: string
    created_time: Date
    updated_time: Date
}

export interface IListingQueryParams extends ITableParams {
    shop_id?: string
    search?: string
    is_template_listing?: boolean
    assignee_id?: string
    start_time?: number
    end_time?: number
    status?: EnumListingStatus | EnumListingStatus[]
}
