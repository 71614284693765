import { Tag, Tooltip } from 'antd'
import { FC } from 'react'
import { EnumProductStatus } from 'types/IProduct'
type AuditReason = {
    position: string
    reasons: string[]
    suggestions: string[]
    listing_platform: string
}
type Props = {
    status: EnumProductStatus
    audit_failed_reasons?: AuditReason[]
}
const CellProductProductStatus: FC<Props> = ({ status, audit_failed_reasons }) => {
    const parseAuditFailedReasons = (audit_failed_reasons: AuditReason[]) => {
        return audit_failed_reasons.map((reason, index) => {
            return (
                <div key={index}>
                    <p>{reason.position}</p>
                    <p>{reason.reasons.join(';')}</p>
                    <p>{reason.suggestions.join(';')}</p>
                </div>
            )
        })
    }
    if (status === EnumProductStatus.ACTIVATE) {
        return <Tag color='#389e0d'>LIVE</Tag>
    } else if (status === EnumProductStatus.PENDING) {
        return <Tag color='#1677ff'>REVIEWING</Tag>
    } else if (status === EnumProductStatus.FAILED) {
        if (audit_failed_reasons) {
            return (
                <Tooltip title={parseAuditFailedReasons(audit_failed_reasons)} placement='bottom'>
                    <Tag color='#ff4d4f'>FAILED</Tag>
                </Tooltip>
            )
        }
        return <Tag color='#ff4d4f'>FAILED</Tag>
    } else if (status === EnumProductStatus.DELETED) {
        return <Tag color='#595959'>DELETED</Tag>
    } else if (status === EnumProductStatus.FREEZE) {
        if (audit_failed_reasons) {
            return (
                <Tooltip title={parseAuditFailedReasons(audit_failed_reasons)} placement='bottom'>
                    <Tag color='#595959'>FREEZE</Tag>
                </Tooltip>
            )
        }
        return <Tag color='#595959'>FREEZE</Tag>
    } else {
        return <Tag color='#8c8c8c'>{status || 'UNKNOWN'}</Tag>
    }
}

export default CellProductProductStatus
