import ApiSender from './ApiSender'
import { IListApiResponse } from 'types/IApiResponse'
import { IAutoPromotion, IAutoPromotionQueryParams, IPayloadCreateAutoPromotion, IPayloadUpdateAutoPromotion } from 'types/IAutoPromotion'

export default class AutoPromotionAPI {
    public static getAutoPromotions(params: IAutoPromotionQueryParams): Promise<IListApiResponse<IAutoPromotion>> {
        const route = `/core/user/auto-promotions`
        return ApiSender.get(route, params)
    }

    public static createAutoPromotion(payload: IPayloadCreateAutoPromotion) {
        const route = `/core/user/auto-promotions`
        return ApiSender.post(route, payload)
    }

    public static editAutoPromotion(_id: string, payload: IPayloadUpdateAutoPromotion) {
        const route = `/core/user/auto-promotions/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removeAutoPromotion(_id: string) {
        const route = `/core/user/auto-promotions/${_id}`
        return ApiSender.delete(route)
    }
}
