import React from 'react'
import { Button, Divider, Form, Modal, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { useMutation, useQuery } from '@tanstack/react-query'
import ListingAPI from '../../../../api/ListingAPI'
import { ModalProps } from '../../../../hooks/useModal'
import {notify} from "../../../../contexts/Notification";
import {parseCurl} from "../../../../utils/curl";

function AutoIdeaUpdateAuthModal({ visible, onClose }: ModalProps) {
    const [form] = useForm()
    const { data } = useQuery({
        queryKey: ['crawl-services'],
        queryFn: ListingAPI.getAutoIdeaCrawlServices,
        enabled: visible
    })
    const { mutateAsync } = useMutation({
        mutationFn: ListingAPI.updateAutoIdeaApiProps,
        onSuccess: () => {
            notify('success', 'Update successfully')
            onClose();
        }
    })

    const onSubmit = async (values: { curl: string; service: string }) => {
        const updater = {
            service: values.service,
            cUrlParsed: parseCurl(values.curl)
        }
        if (!updater.cUrlParsed.headers) {
            notify('error', 'curl parsed missing headers because parser error. try another curl')
            return;
        }
        console.log('updater', updater)
        await mutateAsync(updater)
    }

    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Update API Props' footer={null} width={720} centered>
            <Divider />
            <Form
                name='updateAutoIdeaApiProps'
                layout={'vertical'}
                form={form}
                onFinish={onSubmit}
                style={{ maxWidth: 600 }}
                initialValues={{
                    number_sku: 1,
                    quantity: 8
                }}
            >
                <Form.Item label={<b>Input curl</b>} name='curl' rules={[{ required: true, message: 'Vui lòng nhập curl' }]}>
                    <TextArea
                        placeholder={`curl 'https://www.shoplus.net/api/v1/product/search?cursor=0&keyword=Shonen%20tshirt&is_commerce=false&sort=71&sort_type=DESC' \\
  -H 'accept: application/json, text/plain, */*' \\
  -H 'accept-language: en-US,en;q=0.9,vi;q=0.8' \\
  -H 'cache-control: no-cache' \\
  -H 'x-requested-with: XMLHttpRequest'`}
                        rows={6}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item label={<b>Chọn service</b>} name='service' rules={[{ required: true, message: 'Vui lòng chọn service' }]}>
                    <Select allowClear style={{ width: '100%' }} placeholder='Chọn service' size='large'>
                        {data?.services?.map((service: string) => (
                            <Select.Option key={service} value={service}>
                                {service}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button size='large' htmlType='submit' type='primary'>
                        Cập nhật
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AutoIdeaUpdateAuthModal
