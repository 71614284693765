import { useEffect, useState, FC } from 'react'
import { Button, Form, Modal, Divider, Input, Table, Image, Tooltip } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { gray } from '@ant-design/colors'
import _ from 'lodash'
import { useProductQuery } from 'hooks/queries/product'
import { ColumnsType } from 'antd/es/table'
import { IProduct } from 'types/IProduct'
import { Link } from 'react-router-dom'
import CellProductName from 'core/Table/CellProductName'
import CellProductProductStatus from 'core/Table/CellProductStatus'
import { ISelectProductPromotion } from 'types/IPromotion'
import ProductAPI from 'api/ProductAPI'
import { notify } from 'contexts/Notification'
const { Search } = Input
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    cbChoose: (products: ISelectProductPromotion[]) => void
    shop_id: string
    selectedProducts: ISelectProductPromotion[]
}

const SelectProducts: FC<Props> = ({ visible, toggleModal, cbChoose, shop_id, selectedProducts }) => {
    const [form] = Form.useForm()
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const productQueryResponse = useProductQuery(
        {
            page_size: pageSize,
            page_number: pageNumber,
            shop_id,
            search
        },
        !!shop_id && visible && !search
    )
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    useEffect(() => {
        if (visible && selectedProducts.length > 0) {
            setSelectedRowKeys(selectedProducts.map((product: ISelectProductPromotion) => product._id) as React.Key[])
        }
    }, [visible])

    const onClose = () => {
        form.resetFields()
        setSelectedRowKeys([])
        setPageNumber(1)
        toggleModal(false)
        setSearch('')
    }
    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }
    const onSearch = (value: string) => {
        setPageNumber(1)
        productQueryResponse.refetch()
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }
    const handleDone = () => {
        const products = productQueryResponse?.data?.data?.filter((product: IProduct) => selectedRowKeys.includes(product._id))
        cbChoose(
            (products ?? [])?.map((product: IProduct) =>
                _.pick(product, ['_id', 'product_id', 'product_name', 'images', 'status', 'sale_price_ranges', 'tiktok_shop', 'skus'])
            ) as ISelectProductPromotion[]
        )
    }
    const onSyncProduct = (product_id: string) => {
        setLoading(true)
        ProductAPI.syncProductFromTikTok({ product_id })
            .then((res) => {
                notify('success', 'Đồng bộ sản phẩm thành công')
                productQueryResponse.refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const columns: ColumnsType<IProduct> = [
        {
            title: 'Ảnh Preview',
            dataIndex: 'images',
            key: 'images',
            render: (text: any, record: any, index: number) => {
                return (
                    <Link to={text && text[0] && text[0]?.url_list[0]} target='_blank'>
                        <Image src={text && text[0] && text[0]?.url_list[0]} width={200} preview={false} />
                    </Link>
                )
            },
            width: 200,
            align: 'center'
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text: string, record) => {
                return <CellProductName product_id={record.product_id} product_name={text} skus={record.skus} tiktok_shop={record.tiktok_shop} />
            },
            width: 300
        },
        {
            title: 'Giá bán lẻ',
            dataIndex: 'sale_price_ranges',
            key: 'sale_price_ranges',
            render: (text: [], record: any, index: number) => {
                return text.map((sku: any, index: number) => {
                    return <p key={sku.index}>{sku.price_range}</p>
                })
            },
            width: 80,
            align: 'center'
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => {
                return <CellProductProductStatus status={text} />
            },
            width: 100,
            align: 'center'
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record: any) => {
                if (record?.product_status_view?.product_main_status !== 5) {
                    return (
                        <Tooltip placement='bottom' title={'Đồng bộ dữ liệu sản phẩm trực tiếp từ Tiktok'}>
                            <RedoOutlined style={{ color: gray.primary, fontSize: '20px' }} onClick={() => onSyncProduct(record.product_id)} />
                        </Tooltip>
                    )
                }
                return null
            },
            width: 80,
            fixed: 'right',
            align: 'center'
        }
    ]

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Chọn sản phẩm thêm vào Promotion'
            footer={[
                <Button key='back' onClick={onClose}>
                    Cancel
                </Button>,
                <Button key='submit' type='primary' loading={productQueryResponse?.isFetching} onClick={handleDone}>
                    Done
                </Button>
            ]}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Search
                placeholder='Tìm kiếm theo: Tên sản phẩm, ID sản phẩm'
                allowClear
                enterButton='Search'
                onSearch={_.debounce(onSearch, 250)}
                size='large'
                className='mb-5'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <Table
                bordered
                dataSource={productQueryResponse?.data?.data ?? []}
                columns={columns}
                loading={productQueryResponse?.isFetching || loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total: productQueryResponse?.data?.pagination?.total,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight']
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 500,
                    y: 720
                }}
                rowSelection={rowSelection}
            />
        </Modal>
    )
}

export default SelectProducts
