import {ITableParams} from "../hooks/useTableConfigs";
import { INiche } from "./INiche";
import { IUser } from "./IUser";

export enum EnumCookieStatus {
    LIVE = 'LIVE',
    DIED = 'DIED'
}

export enum EnumProxyStatus {
    LIVE = 'LIVE',
    DIED = 'DIED'
}

export interface ITikTokShop {
    _id: string
    shop_id: string;
    shop_code: string;
    account_id: string;
    name: string;
    region: string;
    email: string;
    code: string;
    cipher: string;
    proxy: string;
    cookie: string;
    user_agent: string;
    access_token: string;
    refresh_token: string;
    authorize_token: string;
    open_id: string;
    description: string;
    logo: {
        height: number;
        width: number;
        url_list: string[];
    };
    owner_seller_id: string;
    profile_id: string;
    profile_name: string;
    main_niche: INiche;
    sub_niches: INiche[];
    key_words: string;
    owner: IUser; // Ref to user models
    assignee: IUser;
    importer: IUser;
    assigner: IUser;
    assigned_time: Date;
    created_in_partner_system: boolean;
    sync_auth_token_partner: boolean
    is_sub_account: boolean;
    time_zone: string;
    cookie_status: EnumCookieStatus;
    proxy_status: EnumProxyStatus;
    note: string;
    created_time: Date;
    updated_time: Date;
}

export interface ITiktokShopQueryParams extends ITableParams {
    page_size: number
    page_number: number
    search?: string
    sort?: string
    assignee_id?: string
    status_kbt?: any
    cookie_status?: EnumCookieStatus
    proxy_status?: EnumProxyStatus
    authorize_token_status?: string
}
