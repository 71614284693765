import React from 'react'
import { Button, Col, Row, Select, Tag } from 'antd'
import { DEFAULT_OPTION_ALL_VALUE } from '../../../const'
import { ReloadOutlined } from '@ant-design/icons'
import { useTiktokShopQuery } from '../../ListingProduct/AutoIdea/queries/tiktok-shop.query'
import { useOrderStore } from '../store/order.store'
import { UseQueryResult } from '@tanstack/react-query'
import { IListApiResponse } from '../../../types/IApiResponse'
import { IOrder } from '../../../types/IOrder'

function OrderTableToolbar({ queryResponse }: { queryResponse?: UseQueryResult<IListApiResponse<IOrder>> }) {
    const { data: tiktokShops } = useTiktokShopQuery({ page_number: 1, page_size: 200 })
    const filter = useOrderStore(state => state.filter)

    return (
        <Row className='mb-3' gutter={[20, 20]}>
            <Col span={16}>
                <Row gutter={[16, 20]}>
                    {/*<Col span={8}>*/}
                    {/*    <Search placeholder='Tìm kiếm theo: Tên sản phẩm, ID, Profile, Template Name' allowClear enterButton='Search' size='large' />*/}
                    {/*</Col>*/}
                    <Col span={8}>
                        <Select
                            showSearch
                            optionFilterProp='textSearch'
                            style={{ width: '100%' }}
                            placeholder='Tất cả cửa hàng'
                            onChange={filter.addShop}
                            value={filter.shop || ''}
                            allowClear
                            size='large'
                            onClear={() => filter.addShop(DEFAULT_OPTION_ALL_VALUE)}
                        >
                            <Select.Option value={DEFAULT_OPTION_ALL_VALUE}>Tất cả cửa hàng</Select.Option>
                            {tiktokShops?.map((shop) => (
                                <Select.Option value={shop._id} key={shop._id} textSearch={`${shop.profile_name} ${shop.name}`}>
                                    <Tag color='#1677ff'>{shop.profile_name ? `${shop.profile_name}` : ''}</Tag>
                                    {shop.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </Col>
            <Col span={8} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <div className='d-flex flex-wrap' style={{ gap: 16 }}>
                    <Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ReloadOutlined />} size='large'>
                        Refresh
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

export default OrderTableToolbar
