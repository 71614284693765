import { createContext, useState, useContext, useEffect, useMemo, FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import * as store from 'utils/storage'
import AuthAPI from 'api/AuthAPI'
import _ from 'lodash'
import { filterConfigByPerms, treeRoutes } from 'core/Routes/RoutesConfig'
import { flatStructure } from 'utils/data-structure-utils'
// import treeConfig, { filterConfigByPerms } from 'core/Routes/RoutesConfig'
// import { flatStructure } from 'utils'

const AuthContext = createContext<{
    user: any
    token: string
    isLoading: boolean
    error: any
    login: (username: string, password: string) => void
    logout: () => void
    loginGoogleOauth: (token: string) => void
    register: (payload: { email: string; password: string; first_name: string; last_name: string }) => void
    collapsed: boolean
    setCollapsed: (collapsed: boolean) => void
}>({
    user: null,
    token: '',
    isLoading: false,
    error: '',
    login: () => {},
    register: () => {},
    logout: () => {},
    loginGoogleOauth: (_token: string) => {},
    collapsed: false,
    setCollapsed: (_collapsed: boolean) => {}
})

export const AuthProvider: FC<{ children: any }> = ({ children }) => {
    const [user, setUser] = useState(null)
    const [token, setToken] = useState(localStorage.getItem('access_token') || '')
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate()

    const logout = () => {
        store.clearAll()
        setUser(null)
        setToken('')
        setLoading(false)
        navigate('/login')
    }
    const veryToken = () => {
        const ACCESS_TOKEN = store.get('access_token')
        if (ACCESS_TOKEN) {
            setLoading(true)
            AuthAPI.verifyAccessToken()
                .then((res) => {
                    setUser(res)
                    setToken(ACCESS_TOKEN)
                    setLoading(false)
                })
                .catch((_error) => {
                    setError(_error.message)
                    logout()
                })
                .finally(() => setLoading(false))
        } else {
            setUser(null)
            setLoading(false)
        }
    }

    useEffect(() => {
        veryToken()
    }, [])

    const login = async (email: string, password: string) => {
        try {
            setLoading(true)
            const response = await AuthAPI.login({
                email,
                password
            })
            if (response && response.access_token) {
                const newUser = {
                    ...response.user,
                    permissions: response.permissions
                }
                if (!newUser.isOwner && _.isEmpty(newUser.permissions))
                    throw new Error("You don't have permission to access")
                store.set('access_token', response.access_token)
                store.set('user', newUser)
                setUser(newUser)
                setToken(response.access_token)
                setLoading(false)
                console.log('newUser', newUser)
                const treeFilterByRole = newUser.isOwner ? treeRoutes : filterConfigByPerms(
                    treeRoutes,
                    newUser.permissions.map((x: any) => x.code_permission)
                )
                const filterTreeHasPath = treeFilterByRole.filter((x: any) => x.path)
                navigate(filterTreeHasPath[0].path)
            }
        } catch (err: any) {
            setError(err.message)
        } finally {
            setLoading(false)
            veryToken()
        }
    }

    const loginGoogleOauth = async (token: string) => {
        try {
            setLoading(true)
            const response = await AuthAPI.loginGoogleOauth({ id_token: token })
            if (response && response.access_token) {
                const newUser = {
                    ...response.user
                }
                store.set('access_token', response.access_token)
                store.set('user', newUser)
                setUser(newUser)
                setToken(response.access_token)
                setLoading(false)
                navigate('/dashboard')
            }
        } catch (err: any) {
            setError(err.message)
        } finally {
            setLoading(false)
            veryToken()
        }
    }

    const register = async (payload: { email: string; password: string; first_name: string; last_name: string }) => {
        try {
            setLoading(true)
            const response = await AuthAPI.register(payload)
            if (response && response.access_token) {
                const newUser = {
                    ...response.user
                }
                store.set('access_token', response.access_token)
                store.set('user', newUser)
                setUser(newUser)
                setToken(response.access_token)
                setLoading(false)
                navigate('/dashboard')
            }
        } catch (err: any) {
            setError(err.message)
        } finally {
            setLoading(false)
            veryToken()
        }
    }
    const memoedValue = useMemo(
        () => ({
            user,
            token,
            isLoading,
            error,
            login,
            logout,
            loginGoogleOauth,
            register,
            collapsed,
            setCollapsed
        }),
        [user, token, isLoading, error, collapsed]
    )

    return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

export const usePermission = () => {
    // const { user } = useAuth()

    // const isPermission = (permission: AdminPermission) =>
    //     checkPermission(user?.permission, permission)


    // return {
    //     isPermission,
    //     user
    // }
}

