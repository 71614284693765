import { useQuery } from '@tanstack/react-query'
import UserAPI from '../../../api/UserAPI'
import { IUserOrderQueryParams } from "../../../types/IUser";

export const USER_ORDER_QUERY_KEY = 'USER_ORDER_QUERY_KEY'

export const useUserOrderQuery = (queryParams: IUserOrderQueryParams) => {
    return useQuery({
        queryKey: [USER_ORDER_QUERY_KEY, queryParams],
        queryFn: () => UserAPI.getOrders(queryParams)
    })
}
