import { useEffect, useState, FC, useRef } from 'react'
import { Button, Form, Modal, Divider, Row, Col, ConfigProvider, Select } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import Editor from 'core/Editor'
import TextArea from 'antd/es/input/TextArea'
import ProductTemplateTmpAPI from 'api/ProductTemplateTmpAPI'
import { IProduct } from 'types/IProduct'
import { useAllDescriptionTemplateQuery } from 'hooks/queries/description-template'

const { Option } = Select
type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item: IProduct | any
}

const EditDescriptionDraft: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [description, setDescription] = useState('')
    const descriptionTemplatesQueryResponse = useAllDescriptionTemplateQuery()
    useEffect(() => {
        if (visible) {
            setDescription(item?.description)
            form.setFieldsValue({ title: item?.product_name })
        }
    }, [visible, item])
    const onClose = () => {
        form.resetFields()
        setDescription('')
        toggleModal(false)
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!description) {
                notify('error', 'Vui lòng nhập mô tả sản phẩm!')
                return
            }
            const diff: any = {
                description,
                title: values.title
            }
            ProductTemplateTmpAPI.editProductTemplateTmp(item._id, diff)
                .then((res) => {
                    notify('success', 'Cập nhật thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const onChangeDescription = (content: any, editor: any) => {
        if (editor?.getData()) {
            setDescription(editor.getData())
        }
    }

    const onChangeForm = (changedValues: any) => {
        console.log('changedValues', changedValues)
        if (changedValues.description_template_id && descriptionTemplatesQueryResponse?.data) {
            const _description = descriptionTemplatesQueryResponse?.data.find((item: any) => item._id === changedValues.description_template_id)
            if (_description) {
                setDescription(_description.description)
            }
        } else if (changedValues.description_template_id === '') {
            setDescription('')
        }
    }

    return (
        <Modal
            maskClosable={false}
            closable
            open={visible}
            onCancel={onClose}
            title='Sửa mô tả sản phẩm mẫu'
            footer={null}
            width={1280}
            centered
            style={{ margin: '25px' }}
        >
            <Divider />
            <Form name='EditDescriptionModal' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form} onValuesChange={onChangeForm}>
                <Form.Item label={<b>Title</b>} name='title' rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }]}>
                    <TextArea placeholder='Nhập Tên sản phẩm' size='large' />
                </Form.Item>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Select: {
                                        colorPrimary: 'linear-gradient(90deg, #6a11cb, #2575fc) !important',
                                        colorBgContainer: '#d9d9d9 !important'
                                    }
                                }
                            }}
                        >
                            <Form.Item
                                label={<b style={{ fontSize: '15px', color: '#0958d9' }}>* Chọn mẫu mô tả có sẵn</b>}
                                name='description_template_id'
                            >
                                <Select placeholder='Chọn mẫu mô tả có sẵn' showSearch optionFilterProp='textSearch' size='large'>
                                    <Option value=''>NHẬP TAY MÔ TẢ</Option>
                                    {_.map(descriptionTemplatesQueryResponse?.data, (des: any) => (
                                        <Option value={des._id} key={des._id} textSearch={`${des.name}`}>
                                            {`${des.name}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </ConfigProvider>
                    </Col>
                </Row>
                <p className='m-2'>
                    <b>Mô tả sản phẩm</b>
                </p>
                <div className='mb-3'>
                    <Editor onChangeEditor={onChangeDescription} initialData={description} />
                </div>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Lưu
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditDescriptionDraft
