import React, { useCallback, useEffect, useRef, useState } from 'react'

export default function useInput(initialValue?: string, autoFocus?: boolean) {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (autoFocus) {
            requestAnimationFrame(() => {
                inputRef.current?.focus()
            })
        }
    }, [autoFocus])

    const [text, setText] = useState(initialValue ?? '')

    const onTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement> | string) => {
        if (typeof e === 'string') {
            setText(e)
        } else {
            setText(e.target.value)
        }
    }, [])

    const onEnter = useCallback(
        (callback: (e: React.KeyboardEvent<HTMLInputElement>) => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                callback(e)
            }
        },
        []
    )

    const onReset = useCallback((text?: string) => {
        setText(text ?? initialValue ?? '')
    }, [])

    return [text, onTextChange, { inputRef, onEnter, onReset }] as const
}
