import { useEffect, useState, FC } from 'react'
import { Button, Form, Modal, Divider, Input } from 'antd'
import _ from 'lodash'
import { notify } from 'contexts/Notification'
import { IListing } from 'types/IListing'
import ProductAPI from 'api/ProductAPI'
import Editor from 'core/Editor'

type Props = {
    visible: boolean
    toggleModal: (val: boolean) => void
    refetch: () => void
    item: IListing | any
}

const EditProductModal: FC<Props> = ({ visible, toggleModal, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [description, setDescription] = useState('')
    useEffect(() => {
        if (visible) {
            setDescription(item?.description)
            if (item.product_name) {
                form.setFieldsValue({ product_name: item.product_name })
            }
        }
    }, [visible, item])
    const onClose = () => {
        form.resetFields()
        setDescription('')
        toggleModal(false)
    }
    const onFinish = async (values: any) => {
        console.log('Success:', values)
        try {
            if (!description) {
                notify('error', 'Vui lòng nhập mô tả sản phẩm!')
                return
            }
            const diff: any = {
                description,
                product_name: values.product_name
            }
            setLoading(true)
            ProductAPI.editProduct(item._id, diff)
                .then((res) => {
                    notify('success', 'Cập nhật thành công')
                    onClose()
                    refetch()
                })
                .catch((err) => {
                    notify('error', err.message)
                })
                .finally(() => setLoading(false))
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }
    const onChangeDescription = (content: any, editor: any) => {
        if (editor?.getData()) {
            setDescription(editor.getData())
        }
    }
    return (
        <Modal maskClosable={false} closable open={visible} onCancel={onClose} title='Sửa sản phẩm' footer={null} width={1280} centered style={{ margin: '25px' }}>
            <Divider />
            <Form name='EditProductModal' layout='vertical' onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete='off' form={form}>
                <Form.Item label={<b>Title</b>} name='product_name' rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm!' }]}>
                    <Input placeholder='Nhập Tên sản phẩm' />
                </Form.Item>
                <p className='m-2'>
                    <b>Mô tả sản phẩm</b>
                </p>
                <div className='mb-3'>
                    <Editor onChangeEditor={onChangeDescription} initialData={description} />
                </div>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        Lưu
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default EditProductModal
