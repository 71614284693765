import { ColumnsType } from 'antd/lib/table'
import { IOrder, OrderItem } from '../../../types/IOrder'
import React from 'react'
import OrderProductImageColumn from './OrderProductImageColumn'
import OrderProductInfoColumn from './OrderProductInfoColumn'

export const ORDER_ITEM_COLUMNS = (order: IOrder): ColumnsType<OrderItem> => [
    {
        title: 'Image',
        dataIndex: 'sku_image',
        key: 'sku_image',
        width: 160,
        render: (_, product) => <OrderProductImageColumn product={product} order={order} />
    },
    {
        title: 'Product',
        dataIndex: 'product_id',
        key: 'product_id',
        render: (_, product) => <OrderProductInfoColumn product={product} order={order} />
    }
]
