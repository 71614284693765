import { Card, Table, Row, Col, Select, Space, Button, Image, Tag, Popconfirm, Tooltip, Form, message } from 'antd'
import { useState, useCallback, FC, useEffect } from 'react'
import { EditOutlined, ReloadOutlined, DeleteOutlined, ExportOutlined } from '@ant-design/icons'
import { blue, red } from '@ant-design/colors'
import moment from 'moment'
import _ from 'lodash'
import ImportKikiModal from './modals/ImportKikiModal'
import { notify } from 'contexts/Notification'
import TikTokShopAPI from 'api/TikTokShopAPI'
import EditTikTokShopModal from './modals/EditTikTokShopModal'
import { EnumCookieStatus, EnumProxyStatus, ITikTokShop } from 'types/ITikTokShop'
import { Helmet } from 'react-helmet'
import ButtonProtected from 'core/ButtonProtected'
import { CODE_PERMISSION } from 'const/permission'
import { EnumAction } from 'types/IPermission'
import { ColumnsType } from 'antd/lib/table'
import Search from 'antd/es/input/Search'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTikTokShopQuery } from 'hooks/queries/tiktok-shop'
import MigrateStaffModal from './modals/MigrateStaffModal'
import { useAllStaffQuery } from 'hooks/queries/staff'
import { useAllNicheQuery } from 'hooks/queries/niche'
import CellProductName from 'core/Table/CellProductName'

const { Option } = Select
const TITLE = 'Danh sách Cửa hàng'

const ConnectTikTokAccount: FC = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [pageSize, setPageSize] = useState(50)
    const [pageNumber, setPageNumber] = useState(1)
    const [visibleKikiModal, setVisibleKikiModal] = useState(false)
    const [visibleEditModal, setVisibleEditModal] = useState(false)
    const [visibleMigrateStaffModal, setVisibleMigrateStaffModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState<ITikTokShop | null>(null)
    const [form] = Form.useForm()
    const queryResponse = useTikTokShopQuery(
        {
            page_size: pageSize,
            page_number: pageNumber,
            search,
            ...form.getFieldsValue()
        },
        true
    )
    const staffsQueryResponse = useAllStaffQuery()
    const nichesQueryResponse = useAllNicheQuery()

    const handleChangePagination = (_pageNumber: number, _pageSize: number) => {
        if (pageSize !== _pageSize) {
            setPageSize(_pageSize)
        } else if (pageNumber !== _pageNumber) {
            setPageNumber(_pageNumber)
        }
    }

    const toggleKikiModal = useCallback(
        (value: boolean) => {
            setVisibleKikiModal(value)
        },
        [visibleKikiModal]
    )

    const toggleEditModal = useCallback(
        (value: boolean) => {
            setVisibleEditModal(value)
            if (!value) {
                setSelectedItem(null)
            }
        },
        [visibleEditModal, selectedItem]
    )

    const toggleMigrateStaffModal = useCallback(
        (value: boolean) => {
            setVisibleMigrateStaffModal(value)
        },
        [visibleMigrateStaffModal]
    )

    const onEdit = useCallback(
        (item: any) => {
            setSelectedItem(item)
            toggleEditModal(true)
        },
        [visibleEditModal, selectedItem]
    )
    const onSyncKikiAgain = (_id: string) => {
        setLoading(true)
        TikTokShopAPI.syncKikiProfile(_id)
            .then(() => {
                notify('success', 'Đồng bộ dữ liệu từ KikiLogin thành công!')
                queryResponse?.refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const onSyncAuthToken = (_id: string) => {
        setLoading(true)
        TikTokShopAPI.syncAuthTokenTiktok(_id)
            .then(() => {
                notify('success', 'Đồng bộ dữ liệu Authorize Token thành công!')
                queryResponse?.refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const onSyncKBT = (_id: string) => {
        setLoading(true)
        TikTokShopAPI.syncKBT(_id)
            .then(() => {
                notify('success', 'Đồng bộ KBT thành công!')
                queryResponse?.refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const onRemoveShop = (_id: string) => {
        setLoading(true)
        TikTokShopAPI.removeShop(_id)
            .then(() => {
                notify('success', 'Xóa cửa hàng thành công!')
                queryResponse?.refetch()
            })
            .catch((err) => {
                notify('error', err.message)
            })
            .finally(() => setLoading(false))
    }

    const renderSubNiches = (sub_niches: any) => {
        return sub_niches.map((niche: any, idx: number) => {
            return (
                <span key={niche._id}>
                    {niche.name}
                    {idx !== sub_niches.length - 1 && ';'}
                </span>
            )
        })
    }

    const onSearch = (value: string) => {
        setSearch(value)
        setPageNumber(1)
    }
    const onCopy = (value: string) => {
        message.success(`Đã Copied: ${value}`)
    }
    const renderStaffs = () => {
        return _.map(staffsQueryResponse?.data, (staff) => {
            return (
                <Option value={staff._id} key={staff._id} textSearch={staff.full_name}>
                    {staff.full_name}
                </Option>
            )
        })
    }
    const renderNiches = () => {
        const parentNiches = nichesQueryResponse?.data?.filter((niche) => !niche.parent)
        return _.map(parentNiches, (niche) => {
            return (
                <Option value={niche._id} key={niche._id}>
                    {niche.name}
                </Option>
            )
        })
    }
    const onChangeValues = (values: any, allValues: any) => {
        console.log('values', values)
        setPageNumber(1)
        queryResponse?.refetch({
            page_size: pageSize,
            page_number: 1,
            search,
            ...allValues
        })
    }
    const handleExportCSV = async () => {
        const shops = await TikTokShopAPI.exportTikTokShops()
        downloadCSV(shops)
    }

    const downloadCSV = (data: ITikTokShop[]) => {
        // Convert the data array into a CSV string
        const csvString = [
            [
                'ID',
                'Name',
                'Code',
                'Account ID',
                'Region',
                'Profile ID',
                'Profile Name',
                'Owner Seller ID',
                'Assignee',
                'Importer',
                'Cipher',
                'Authorize Token',
                'Access Token',
                'Refresh Token',
                'Proxy',
                'User Agent'
            ], // Specify your headers here
            ...data.map((item: ITikTokShop) => [
                item.shop_id,
                item.name,
                item.shop_code,
                item.account_id,
                item.region,
                item.profile_id,
                item.profile_name,
                item.owner_seller_id,
                item.assignee?.full_name,
                item.importer?.full_name,
                item.cipher,
                item.authorize_token,
                item.access_token,
                item.refresh_token,
                item.proxy,
                item.user_agent
            ]) // Map your data fields accordingly
        ]
            .map((row) => row.join(','))
            .join('\n')
        // Add BOM to support UTF-8 encoding
        const BOM = '\uFEFF'
        // Create a Blob from the CSV string
        const blob = new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8;' })

        // Generate a download link and initiate the download
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `tiktok_shops_${moment().format('YYYY_MM_DD_HH_mm_ss')}.csv`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    }
    const columns: ColumnsType<ITikTokShop> = [
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index: number) => index + 1,
            width: 80,
            align: 'center'
        },
        {
            title: 'Profile',
            dataIndex: 'profile_id',
            key: 'profile_id',
            render: (profile_id, record) => {
                return (
                    <CellProductName tiktok_shop={_.pick(record, ['name', 'profile_name', 'main_niche', 'sub_niches', 'assignee', 'profile_id'])} />
                )
            },
            width: 350,
            align: 'left'
        },
        {
            title: 'Tên cửa hàng',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {
                return (
                    <div className='d-flex align-items-center'>
                        <Image src={record.logo?.url_list[0]} width={40} />
                        <div className='ml-2 text-left'>
                            <CopyToClipboard text={name} onCopy={() => onCopy(name)}>
                                <p title='Shop name' className='font-weight-bold clickable'>
                                    {name}
                                </p>
                            </CopyToClipboard>
                            <CopyToClipboard text={record.shop_id} onCopy={() => onCopy(record.shop_id)}>
                                <p title='Shop ID' className='text-sm font-italic clickable'>
                                    {record.shop_id}
                                </p>
                            </CopyToClipboard>
                            {record.authorize_token && (
                                <p title='Authorize Token' className='font-italic clickable'>
                                    <CopyToClipboard text={record.authorize_token} onCopy={() => onCopy(record.authorize_token)}>
                                        <span style={{ color: record.sync_auth_token_partner ? '#389e0d' : '#cf1322' }}>
                                            <b>Auth Token: </b>
                                            <Tooltip title={record.authorize_token}>{record.authorize_token.slice(0, 30) + '...'}</Tooltip>
                                        </span>
                                    </CopyToClipboard>
                                </p>
                            )}
                            {record.cipher && (
                                <CopyToClipboard text={record.cipher} onCopy={() => onCopy(record.cipher)}>
                                    <p
                                        title='Cipher'
                                        className='font-italic clickable'
                                        style={{ color: record.sync_auth_token_partner ? '#389e0d' : '#cf1322' }}
                                    >
                                        <b>Cipher: </b>
                                        <Tooltip title={record.cipher}>{record.cipher.slice(0, 30) + '...'}</Tooltip>
                                    </p>
                                </CopyToClipboard>
                            )}
                            {record.authorize_token && (
                                <Button
                                    type='primary'
                                    danger={!record.sync_auth_token_partner}
                                    title='Đồng bộ Auth Token'
                                    className='mt-3'
                                    onClick={() => onSyncAuthToken(record._id)}
                                >
                                    Get Auth Token
                                </Button>
                            )}
                        </div>
                    </div>
                )
            },
            width: 300,
            align: 'center'
        },
        {
            title: 'Key Words',
            dataIndex: 'key_words',
            key: 'key_words',
            width: 200,
            align: 'center',
            render: (key_words) => {
                if (!key_words) return null
                const keywords = key_words.split('\n')
                const len = keywords.length
                const sliceKeywords = keywords?.slice(0, 2)
                if (len > 3) {
                    sliceKeywords.push('\n...')
                }

                return (
                    <div className='d-flex flex-column'>
                        {sliceKeywords.map((keyword: string) => (
                            <p key={keyword} style={{ whiteSpace: 'nowrap' }}>
                                {keyword.length > 50 ? <Tooltip title={keyword}>{keyword.slice(0, 50) + '...'}</Tooltip> : keyword}
                            </p>
                        ))}
                    </div>
                )
            }
        },
        {
            title: 'Assigner',
            dataIndex: 'assigner',
            key: 'assigner',
            width: 200,
            align: 'center',
            render: (assigner) => {
                return assigner?.full_name
            }
        },
        {
            title: 'Quốc gia',
            dataIndex: 'region',
            key: 'region',
            width: 150,
            align: 'center'
        },
        {
            title: 'Trạng thái KBT',
            dataIndex: 'created_in_partner_system',
            key: 'created_in_partner_system',
            render: (text: boolean, record) => {
                if (text) {
                    return (
                        <>
                            <Tag color='#52c41a'>Đã đồng bộ</Tag>
                            <Button type='primary' title='Đồng bộ Auth Token từ KBT' className='mt-3' onClick={() => onSyncKBT(record._id)}>
                                Sync KBT
                            </Button>
                        </>
                    )
                } else {
                    return (
                        <>
                            <Tag color='#f5222d'>Chưa đồng bộ</Tag>
                            <Button type='primary' danger title='Đồng bộ Auth Token từ KBT' className='mt-3' onClick={() => onSyncKBT(record._id)}>
                                Sync KBT
                            </Button>
                        </>
                    )
                }
            },
            width: 150,
            align: 'center'
        },
        {
            title: 'Trạng thái Cookie',
            dataIndex: 'cookie_status',
            key: 'cookie_status',
            render: (cookie_status) => {
                if (cookie_status === 'LIVE') {
                    return <Tag color='#52c41a'>LIVE</Tag>
                } else {
                    return <Tag color='#f5222d'>DIED</Tag>
                }
            },
            width: 150,
            align: 'center'
        },
        {
            title: 'Trạng thái Proxy',
            dataIndex: 'proxy_status',
            key: 'proxy_status',
            render: (proxy_status) => {
                if (proxy_status === 'LIVE') {
                    return <Tag color='#52c41a'>LIVE</Tag>
                } else {
                    return <Tag color='#f5222d'>DIED</Tag>
                }
            },
            width: 150,
            align: 'center'
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_time',
            key: 'created_time',
            width: 150,
            align: 'center',
            render: (created_time) => moment(created_time).format('DD/MM/YYYY HH:mm')
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text: any, record) => (
                <>
                    <Space size='middle'>
                        <ButtonProtected permission={{ [CODE_PERMISSION.CONNECT_SHOP]: [EnumAction.Edit] }}>
                            <EditOutlined
                                style={{ color: blue.primary, fontSize: '22px' }}
                                type='button'
                                onClick={() => onEdit(record)}
                                title='Edit account'
                            />
                        </ButtonProtected>
                        <ButtonProtected permission={{ [CODE_PERMISSION.CONNECT_SHOP]: [EnumAction.Delete] }}>
                            <Popconfirm
                                title='Bạn chắc chắn xóa Shop này khỏi tài khoản?'
                                description='Xóa Shop sẽ bao gồm xóa Product, Template, Listing khỏi tài khoản?'
                                onConfirm={() => onRemoveShop(record._id)}
                            >
                                <DeleteOutlined style={{ color: red.primary, fontSize: '22px' }} type='button' title='Remove shop' />
                            </Popconfirm>
                        </ButtonProtected>
                    </Space>
                    <Button type='primary' title='Sync Kiki Profile' className='mt-3' onClick={() => onSyncKikiAgain(record._id)}>
                        Đồng bộ KikiLogin
                    </Button>
                </>
            ),
            width: 150,
            align: 'center'
        }
    ]
    return (
        <Card title='Danh sách cửa hàng'>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <Form form={form} onValuesChange={onChangeValues}>
                <Row className='mb-4'>
                    <Col offset={12} span={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <Space size='large' className='mr-2'>
                            <ButtonProtected permission={{ [CODE_PERMISSION.CONNECT_SHOP]: [EnumAction.Create, EnumAction.Edit] }}>
                                <Button icon={<ExportOutlined />} onClick={handleExportCSV} size='large'>
                                    Export (CSV)
                                </Button>
                            </ButtonProtected>
                            <ButtonProtected permission={{ [CODE_PERMISSION.CONNECT_SHOP]: [EnumAction.Create, EnumAction.Edit] }}>
                                <Button type='primary' onClick={() => toggleMigrateStaffModal(true)} size='large'>
                                    Chuyển Nhân Viên
                                </Button>
                            </ButtonProtected>
                            <ButtonProtected permission={{ [CODE_PERMISSION.CONNECT_SHOP]: [EnumAction.Create, EnumAction.Edit] }}>
                                <Button type='primary' onClick={() => toggleKikiModal(true)} size='large'>
                                    Import KikiLogin
                                </Button>
                            </ButtonProtected>
                            <Button type='primary' onClick={() => queryResponse?.refetch()} icon={<ReloadOutlined />} size='large'>
                                Refresh
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Card>
                    <Row gutter={[20, 20]} className='mb-2'>
                        <Col span={12}>
                            <Search
                                placeholder='Tìm kiếm theo: Tên cửa hàng, Profile'
                                allowClear
                                enterButton='Search'
                                onSearch={_.debounce(onSearch, 250)}
                                size='large'
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item name='assignee_id'>
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp='textSearch'
                                    style={{ width: '100%' }}
                                    placeholder='Tất cả nhân viên'
                                    size='large'
                                >
                                    <Option value=''>Tất cả nhân viên</Option>
                                    {renderStaffs()}
                                    <Option value='null'>Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='niche_id'>
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp='textSearch'
                                    style={{ width: '100%' }}
                                    placeholder='Tất cả Niche'
                                    size='large'
                                >
                                    <Option value=''>Tất cả Niche</Option>
                                    {renderNiches()}
                                    <Option value='null'>Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col span={6}>
                            <Form.Item name='authorize_token_status'>
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp='textSearch'
                                    style={{ width: '100%' }}
                                    placeholder='Trạng thái Authorize Token'
                                    size='large'
                                >
                                    <Option value=''>Tất cả</Option>
                                    <Option value='1'>Đã đồng bộ</Option>
                                    <Option value='0'>Chưa đồng bộ</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='status_kbt'>
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp='textSearch'
                                    style={{ width: '100%' }}
                                    placeholder='Trạng thái KBT'
                                    size='large'
                                >
                                    <Option value=''>Tất cả</Option>
                                    <Option value='true'>Đã đồng bộ</Option>
                                    <Option value='false'>Chưa đồng bộ</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='cookie_status'>
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp='textSearch'
                                    style={{ width: '100%' }}
                                    placeholder='Trạng thái Cookie'
                                    size='large'
                                >
                                    <Option value=''>Tất cả</Option>
                                    <Option value={EnumCookieStatus.LIVE}>LIVE</Option>
                                    <Option value={EnumCookieStatus.DIED}>DIED</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name='proxy_status'>
                                <Select
                                    allowClear
                                    showSearch
                                    optionFilterProp='textSearch'
                                    style={{ width: '100%' }}
                                    placeholder='Trạng thái Proxy'
                                    size='large'
                                >
                                    <Option value=''>Tất cả</Option>
                                    <Option value={EnumProxyStatus.LIVE}>LIVE</Option>
                                    <Option value={EnumProxyStatus.DIED}>DIED</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>

            <Table
                bordered
                dataSource={queryResponse?.data?.data ?? []}
                columns={columns}
                loading={queryResponse?.isFetching || loading}
                pagination={{
                    defaultCurrent: pageNumber,
                    current: pageNumber,
                    pageSize,
                    total: queryResponse?.data?.pagination?.total,
                    onChange: handleChangePagination,
                    position: ['topLeft', 'bottomRight'],
                    showTotal: (total) => (
                        <span>
                            Tổng số cửa hàng: <strong>{total}</strong>
                        </span>
                    )
                }}
                rowKey={(record) => record?._id}
                scroll={{
                    x: 800
                }}
            />
            <ImportKikiModal visible={visibleKikiModal} toggleModal={toggleKikiModal} refetchData={() => queryResponse?.refetch()} />
            <EditTikTokShopModal
                visible={visibleEditModal}
                toggleModal={toggleEditModal}
                refetchData={() => queryResponse?.refetch()}
                item={selectedItem}
            />
            <MigrateStaffModal
                visible={visibleMigrateStaffModal}
                toggleModal={toggleMigrateStaffModal}
                refetchData={() => queryResponse?.refetch()}
                staffs={staffsQueryResponse?.data ?? []}
            />
        </Card>
    )
}

export default ConnectTikTokAccount
