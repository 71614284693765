import React from 'react'
import { Divider, Image } from 'antd'
import { CRAWL_SOURCE } from '../Table/AutoIdeaProductImageColumn'
import { IAutoIdeaProduct } from '../../../../types/IAutoIdea'

function AutoIdeaCreateListingHead(record: IAutoIdeaProduct) {
    return (
        <>
            <div className='d-flex'>
                <Image src={CRAWL_SOURCE[record.source as keyof typeof CRAWL_SOURCE].imagePrefix + record.image} width={140} />
                <div className='ml-3'>
                    <p className='font-weight-bold'>{record.title}</p>
                    <p className='text-sm font-italic my-1'>{record.product_id}</p>
                    <p className=''>
                        {record.price !== -1 && (
                            <>
                                Price:{' '}
                                <strong>
                                    {record.price} {record.currency}
                                </strong>{' '}
                                -
                            </>
                        )}{' '}
                        Sold:{' '}
                        <strong>
                            {record.source === CRAWL_SOURCE.SHOP_PLUS.source ? record.week_sold_count : (record.moreInfo?.l30d_sales_volume ?? '-')}
                        </strong>{' '}
                        per {record.source === CRAWL_SOURCE.SHOP_PLUS.source ? 'week' : 'month'}
                    </p>
                </div>
            </div>
            <Divider className='mt-3 mb-1' />
        </>
    )
}

export default AutoIdeaCreateListingHead
