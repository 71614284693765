import ApiSender from './ApiSender'

export default class StaffAPI {
    public static getStaffs(params: { page_size: number; page_number: number }) {
        const route = `/core/user/staffs`
        return ApiSender.get(route, params)
    }

    public static getAllStaffs() {
        const route = `/core/user/all-staffs`
        return ApiSender.get(route)
    }

    public static createStaff(payload: { name: string;}) {
        const route = `/core/user/staffs`
        return ApiSender.post(route, payload)
    }

    public static editStaff(_id: string, payload: { name: string;  }) {
        const route = `/core/user/staffs/${_id}`
        return ApiSender.put(route, payload)
    }

    public static removeStaff(_id: string) {
        const route = `/core/user/staffs/${_id}`
        return ApiSender.delete(route)
    }

    public static getPartnerStaffs() {
        const route = `/core/user/partner/staffs`
        return ApiSender.get(route)
    }

    public static getDetailStaff(id: number) {
        const route = `/core/user/partner/staffs/${id}`
        return ApiSender.get(route)
    }

    public static getRoles() {
        const route = `/core/user/staffs/roles`
        return ApiSender.get(route)
    }
}
