import { useQuery } from '@tanstack/react-query'
import PriceTemplateAPI from 'api/PriceTemplateAPI'
import { IPriceTemplate } from 'types/IPriceTemplate'

export const ALL_PRICE_TEMPLATE_QUERY_KEY = 'all_price_template_list'


export const useAllPriceTemplateQuery = () => {
    return useQuery<IPriceTemplate[]>({
        queryKey: [ALL_PRICE_TEMPLATE_QUERY_KEY],
        queryFn: () => PriceTemplateAPI.getAllPriceTemplates()
    })
}
