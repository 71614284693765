import React from 'react'
import { Card } from 'antd'
import { Helmet } from 'react-helmet'
import AutoIdeaTableToolbar from './Table/AutoIdeaTableToolbar'
import AutoIdeaProvider from './AutoIdeaProvider'
import AutoIdeaTabs from './AutoIdeaTabs'

function AutoIdea() {
    return (
        <AutoIdeaProvider>
            <Card title={<span style={{ fontSize: '20px' }}>Listing Auto Idea</span>}>
                <Helmet>
                    <title> Listing Auto Idea</title>
                </Helmet>
                <AutoIdeaTableToolbar />
                <AutoIdeaTabs />
            </Card>
        </AutoIdeaProvider>
    )
}

export default AutoIdea
