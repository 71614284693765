export enum EnumAction {
    View = 'view',
    Create = 'create',
    Edit = 'edit',
    Delete = 'delete'
}

export interface IPermission {
    _id: string
    id: number
    name: string
    description: string
    group_module: string
    code_group_module: string
    actions: EnumAction[]
    code_permission: string
    created_time?: Date
    updated_time?: Date
}

export type UserPermission = { [k: string]: EnumAction[] }
